import { Props } from '..';
import { Typography } from '../../../../../../componentsV2/ui/Typography';
import { formatCardNumber } from '../../../../../../utils/formatCardNumber';
import {
	parseMovementStatus,
	parseMovementType,
} from '../../../../Management/utils/parseBalanceMovements';
import * as S from '../styles';

export function MainContent({ movement }: Props) {
	const movementType = parseMovementType(movement.type, movement.action);

	if (
		movementType === 'Estorno para carteira' ||
		movementType === 'Repasse para cartão'
	) {
		return (
			<>
				<S.SubTitleRow>
					<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
						Detalhes do cartão de{' '}
						{movementType === 'Estorno para carteira' ? 'origem' : 'destino'}:
					</Typography>
				</S.SubTitleRow>

				<S.FieldsContainer>
					<S.Field>
						<Typography color='var(--dark-gray)'>Responsável:</Typography>

						<Typography color='var(--dark-gray)'>
							{movement.card?.responsible}
						</Typography>
					</S.Field>

					<S.Field>
						<Typography color='var(--dark-gray)'>Nome do cartão:</Typography>
						<Typography color='var(--dark-gray)'>
							{movement.card?.alias}
						</Typography>
					</S.Field>

					<S.Field>
						<Typography color='var(--dark-gray)'>Número do cartão:</Typography>
						<Typography color='var(--dark-gray)'>
							{formatCardNumber(movement.card?.pan!)}
						</Typography>
					</S.Field>

					<S.Field>
						<Typography color='var(--dark-gray)'>Centro de custo:</Typography>
						<Typography color='var(--dark-gray)'>
							{movement.card?.cost_center}
						</Typography>
					</S.Field>

					<S.Field>
						<Typography color='var(--dark-gray)'>Data da operação:</Typography>
						<Typography color='var(--dark-gray)'>
							{Intl.DateTimeFormat('pt-BR', {
								day: '2-digit',
								month: '2-digit',
								year: 'numeric',
								hour: '2-digit',
								minute: '2-digit',
								hour12: false, // 24-hour format
							})
								.format(new Date(movement.created_at))
								.replace(',', ' às')}
						</Typography>
					</S.Field>
				</S.FieldsContainer>
				<S.SubTitleRow style={{ marginTop: '25px' }}>
					<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
						Status da transação:
					</Typography>

					{parseMovementStatus(movement.status!)}
				</S.SubTitleRow>
			</>
		);
	}

	if (movementType === 'Recarga de carteira') {
		return (
			<>
				<S.SubTitleRow>
					<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
						Detalhes da transação:
					</Typography>
				</S.SubTitleRow>

				<S.FieldsContainer>
					<S.Field>
						<Typography color='var(--dark-gray)'>Responsável:</Typography>

						<Typography color='var(--dark-gray)'>
							{movement.operator?.name}
						</Typography>
					</S.Field>

					<S.Field>
						<Typography color='var(--dark-gray)'>Operação via:</Typography>
						<Typography color='var(--dark-gray)'>
							{movement.type === 'pix' ? 'Pix' : 'Boleto'}
						</Typography>
					</S.Field>

					<S.Field>
						<Typography color='var(--dark-gray)'>Destino:</Typography>
						<Typography color='var(--dark-gray)'>Carteira</Typography>
					</S.Field>

					<S.Field>
						<Typography color='var(--dark-gray)'>Data da operação:</Typography>
						<Typography color='var(--dark-gray)'>
							{Intl.DateTimeFormat('pt-BR', {
								day: '2-digit',
								month: '2-digit',
								year: 'numeric',
								hour: '2-digit',
								minute: '2-digit',
								hour12: false, // 24-hour format
							})
								.format(new Date(movement.created_at))
								.replace(',', ' às')}
						</Typography>
					</S.Field>
				</S.FieldsContainer>
				<S.SubTitleRow style={{ marginTop: '25px' }}>
					<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
						Status da transação:
					</Typography>

					{parseMovementStatus(movement.status!)}
				</S.SubTitleRow>
			</>
		);
	}

	return <></>;
}
