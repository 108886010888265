import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import ArrowUp3D from '../../../assets/3d-arrow-up.png';
import { InfoContent } from '../../../componentsV2/InfoContent';
import { Button } from '../../../componentsV2/ui/Button';
import { SectionContainer } from '../../../componentsV2/ui/SectionContainer';
import {
	TitleTypography,
	Typography,
} from '../../../componentsV2/ui/Typography';
import { useAuth } from '../../../hooks/useAuth';
import { cepMask, cnpjMask, phoneMask } from '../../../utils/masks';
import { CostCenterExpenses } from './Charts/CostCenterExpenses';
import * as S from './styles';
import { YearExpenses } from './Charts/YearExpenses';
import { CardsTopExpenses } from './Charts/CardsTopExpenses';
import { AsynchronousContent } from '../../../componentsV2/AsynchronousContent';
import { useQuery } from 'react-query';
import {
	CORPWAY_CARDS_KEY,
	getCards,
} from '../../../services/queries/Corpway/Cards';
import { CardsList } from '../Cards/components/CardsList';
import { UserTopExpenses } from './Charts/UserTopExpenses';
import styled from 'styled-components';

export const SectionTitle = ({
	children,
	primaryText,
}: {
	children: React.ReactNode;
	primaryText: string;
}) => (
	<TitleTypography
		primaryText={primaryText}
		size='2.4rem'
		weight='600'
		style={{ display: 'flex' }}
	>
		{children}
	</TitleTypography>
);

const CustomSectionContainer = styled(SectionContainer)`
	padding: 3.2rem 4rem 3.2rem 4rem;
`;

const BalanceSectionContainer = styled(SectionContainer)`
	max-width: 44rem;
	padding: 0 0.4rem 0 0;
	@media (max-width: 1024px) {
		max-width: 100%;
		width: 100%;
	}
`;

export function Home() {
	const { currentCompany, user } = useAuth();

	// Show dashboards to all users OR show it only to Bounty companyusers
	const shouldShowDashboard =
		process.env.REACT_APP_DASHBOARD_ACTIVE == 'true' ||
		process.env.REACT_APP_BOUNTY_COMPANY_ID === currentCompany?.id;

	const getCardsQuery = useQuery(
		[CORPWAY_CARDS_KEY, user.id, currentCompany?.id],
		() => getCards(currentCompany!.id),
		{
			onError: (err) => {},
		}
	);

	return (
		<div>
			<CustomSectionContainer
				style={{ minHeight: '16.5rem', justifyContent: 'center' }}
			>
				<TitleTypography image>Início</TitleTypography>
			</CustomSectionContainer>

			<CustomSectionContainer>
				<S.Details open={false}>
					<summary>
						<S.SectionHeader>
							<SectionTitle primaryText='Dados'>da empresa</SectionTitle>
							<MdOutlineKeyboardArrowDown />
						</S.SectionHeader>
					</summary>

					<S.CompanyInfoContainer>
						<InfoContent label='Nome:'>{currentCompany?.name}</InfoContent>

						<InfoContent label='Endereço:'>
							<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
								{`${currentCompany?.address}, ${currentCompany?.number} - ${currentCompany?.district}`}
								<br />
								{`${currentCompany?.city} - ${currentCompany?.uf}, ${cepMask(
									currentCompany!.cep
								)}`}
							</Typography>
						</InfoContent>

						<InfoContent label='Site:'>{currentCompany?.website}</InfoContent>

						<InfoContent label='CNPJ:'>
							{cnpjMask(currentCompany!.cnpj)}
						</InfoContent>

						<InfoContent label='Telefone 1:'>
							{phoneMask(currentCompany!.first_phone)}
						</InfoContent>

						<InfoContent label='Telefone 2:'>
							{currentCompany?.second_phone &&
								phoneMask(currentCompany.second_phone)}
						</InfoContent>
					</S.CompanyInfoContainer>
				</S.Details>
			</CustomSectionContainer>

			<S.SideBySideContainer>
				{user.roles.includes('visualize-balances') && (
					<BalanceSectionContainer>
						<S.BalanceContainer>
							<S.BalanceInfoContainer>
								<SectionTitle primaryText='Adicionar'>saldo</SectionTitle>
								<style>
									{`
										@media (max-width: 768px) {
											.mobileStyle {
												text-align: center !important;
												gap: 0.5rem; 
											}
										}
									`}
								</style>
								<div>
									<Typography
										className='mobileStyle'
										color='var(--mid-gray)'
										size='1.4rem'
										weight='400'
										style={{
											width: '24rem',
											paddingBottom: '0.5rem',
											textAlign: 'left',
										}}
									>
										Disponibilize valores rapidamente na sua conta principal
									</Typography>
								</div>

								{user.roles.includes('manage-balances') && (
									<Button
										roundness='lg'
										as='link'
										to='/corporate-expenses/wallet'
										style={{
											padding: '1rem 3.5rem',
											fontFamily: 'Poppins',
											textAlign: 'center',
											fontSize: '16px',
										}}
									>
										Adicionar Saldo
									</Button>
								)}
								<div></div>
							</S.BalanceInfoContainer>
							<img src={ArrowUp3D} alt='cartões bounty' />
						</S.BalanceContainer>
					</BalanceSectionContainer>
				)}

				{user.roles.includes('visualize-all-extracts') && shouldShowDashboard && (
					<CustomSectionContainer>
						<CostCenterExpenses />
					</CustomSectionContainer>
				)}
				{user.roles.includes('visualize-balances') && !shouldShowDashboard && (
					<CustomSectionContainer>
						<div>
							<SectionTitle primaryText='Saldo'>dos cartões</SectionTitle>
							{getCardsQuery.data && (
								<Typography color='var(--mid-gray)'>
									Cartões ativos - {getCardsQuery.data?.cards_active.length}
								</Typography>
							)}
						</div>

						<AsynchronousContent status={getCardsQuery.status}>
							{getCardsQuery.data && (
								<CardsList
									cards={getCardsQuery.data.cards_active}
									height='14rem'
								/>
							)}
						</AsynchronousContent>
					</CustomSectionContainer>
				)}
			</S.SideBySideContainer>

			{user.roles.includes('visualize-all-extracts') && shouldShowDashboard && (
				<CustomSectionContainer>
					<YearExpenses />
				</CustomSectionContainer>
			)}

			<S.SideBySideContainer>
				{user.roles.includes('visualize-all-extracts') && shouldShowDashboard && (
					<CustomSectionContainer>
						<CardsTopExpenses />
					</CustomSectionContainer>
				)}

				{user.roles.includes('visualize-all-extracts') && shouldShowDashboard && (
					<CustomSectionContainer>
						<UserTopExpenses />
					</CustomSectionContainer>
				)}
			</S.SideBySideContainer>
		</div>
	);
}
