import styled from 'styled-components';
import { Typography } from '../../../../componentsV2/ui/Typography';
import { AsynchronousContent } from '../../../../componentsV2/AsynchronousContent';
import { InputComp } from '../../../../componentsV2/ui/Form/InputStyle';
import { SectionTitle } from '..';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../hooks/useAuth';
import { useQuery } from 'react-query';
import { useState } from 'react';
import {
	CORPWAY_CARDS_KEY,
	getCardsTopExpenses,
} from '../../../../services/queries/Corpway/Cards';
import { convertCentsToFormattedReais } from '../../../../utils/CurrencyConvert';
import { DatesContainer } from '../styles';

export function CardsTopExpenses() {
	const { user, currentCompany } = useAuth();
	const january2024 = new Date('January 1, 2024'); // start of bounty control
	const currentDate = new Date();

	const [selectedDate, setSelectedDate] = useState(currentDate);

	const getCardsTopExpensesQuery = useQuery(
		[
			CORPWAY_CARDS_KEY,
			'top-expenses',
			user.id,
			currentCompany?.id,
			selectedDate,
		],
		() => getCardsTopExpenses(currentCompany!.id, selectedDate),
		{
			onError: () => {
				toast.error(
					'Ocorreu um problema ao buscar o relatório de cartões utilizados.'
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	return (
		<RootContainer>
			<ChildContainer>
				<style>
					{`
						@media (max-width: 768px) {
							.mobileStyle {
								text-align: center !important;
								min-width: 2rem;
							}
						
						}
					`}
				</style>
				<div>
					<SectionTitle primaryText='Cartões'> top gastos</SectionTitle>
					<Typography
						className='mobileStyle'
						size='1.4rem'
						color='var(--mid-gray)'
						style={{
							width: '24rem',
							marginTop: '0.1rem',
							marginBottom: '2rem',
						}}
					>
						Relatório com os principais cartões utilizados
					</Typography>
				</div>

				<div
					style={{
						textAlign: 'right',
					}}
				>
					<DatesContainer>
						<InputComp
							type='month'
							min={january2024.toISOString().split('-').slice(0, 2).join('-')}
							max={currentDate.toISOString().split('-').slice(0, 2).join('-')}
							value={selectedDate
								.toISOString()
								.split('-')
								.slice(0, 2)
								.join('-')}
							style={{ marginTop: '0.6rem' }}
							onChange={(e) => {
								const selectedMonth = e.target.value;
								const year = parseInt(selectedMonth.split('-')[0]);
								const month = parseInt(selectedMonth.split('-')[1]);
								setSelectedDate(new Date(year, month - 1, 1));
							}}
						/>
					</DatesContainer>
				</div>
			</ChildContainer>

			<AsynchronousContent
				status={getCardsTopExpensesQuery.status}
				loaderHeight={'23rem'}
			>
				<List>
					{getCardsTopExpensesQuery.data?.cardTopExpenses
						.slice(0, 5)
						.map((card, i) => (
							<li key={card.id}>
								<TopIndicator> {i + 1}º </TopIndicator>
								<Typography
									size='1.6rem'
									color='var(--dark-gray)'
									weight='600'
									style={{ minWidth: '20rem' }}
								>
									<div className='column'>{card.alias}</div>
								</Typography>
								<HiddenOnMobile>
									<Typography size='1.6rem' color='var(--dark-gray)'>
										<div className='column pan'>
											{'**** ' + card.pan.slice(-4)}
										</div>
									</Typography>
								</HiddenOnMobile>
								<Typography
									size='1.6rem'
									weight='600'
									color='var(--primary-blue)'
									style={{ marginLeft: '1rem', textAlign: 'right' }}
								>
									<div className='column'>
										{convertCentsToFormattedReais(card.expenses)}
									</div>
								</Typography>
							</li>
						))}
				</List>
			</AsynchronousContent>
		</RootContainer>
	);
}

const ChildContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 5rem;
	@media (max-width: 650px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 0.1rem;
		margin-bottom: 1.5rem;
	}
`;

const RootContainer = styled.div`
	display: 'grid';
	gap: '1rem';
	min-height: '32.5rem';

	@media (max-width: 650px) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

const HiddenOnMobile = styled.div`
	@media screen and (max-width: 650px) {
		display: none;
	}
`;

const List = styled.ul`
	display: grid;
	gap: 1.5rem;
	margin-top: 1.5rem;
	list-style: none;

	& li {
		display: grid;
		grid-template-columns: 3.5rem 1fr 1fr 1fr;
		gap: 2rem;
		align-items: center;
		padding-bottom: 0.5rem;
		border-bottom: 1px solid #f0f3f8;
	}

	.column {
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.pan {
		text-align: center;
	}
	@media (max-width: 650px) {
		gap: 1.5rem;
		& li {
			display: grid;
			grid-template-columns: 2.5rem 1fr 1fr 1fr;
			gap: 1rem;
			align-items: center;
			padding-bottom: 0.5rem;
			border-bottom: 1px solid #f0f3f8;
		}
		li:nth-child(3),
		th:nth-child(3) {
			display: none;
		}
	}
`;

const TopIndicator = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	background-color: var(--primary-blue);
	color: var(--white);
	font-size: 1.4rem;
	font-weight: 600;
`;
