import {
	Document,
	Page,
	Text,
	View,
	Font,
	Image,
	pdf,
} from '@react-pdf/renderer';
import Loader from '../../../../components/Loader';
import { useQuery } from 'react-query';
import { FilterParams } from '../../../../components/Filter';
import { toast } from 'react-toastify';
import { convertCentsToReais } from '../../../../utils/CurrencyConvert';
import { useAuth } from '../../../../hooks/useAuth';
import { ReportButton } from '../../../../components/PDFReportStyles';
import {
	FilterRefundsReturn,
	getRefundsToReport,
} from '../../../../services/queries/Refunds';
import { SortColumn } from '../../../../hooks/useSortColumnHook';
import { refundsReportStyle } from './styles';
import FlexibleBenefitsLogo from '../../../../assets/flexibleBenefitsReports/FlexibleBenefitsLogo.png';
import { cnpjMask, cpfMask } from '../../../../utils/masks';
import reportIcon from '../../../../assets/flexibleBenefitsReports/ReportIcon.png';
import { parseRefundStatusToReport } from './utils/parseRefundStatusToReport';

Font.register({
	family: 'Poppins',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/hlvAxH6aIdOjWlLzgm0jqg.ttf',
		},
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/4WGKlFyjcmCFVl8pRsgZ9vesZW2xOQ-xsNqO47m55DA.ttf',
			fontWeight: 500,
		},
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/-zOABrCWORC3lyDh-ajNnPesZW2xOQ-xsNqO47m55DA.ttf',
			fontWeight: 600,
		},
	],
});

interface ReportProps {
	company_id: string;
	filterParams: FilterParams[];
	sortColumn: SortColumn | null;
	pending?: boolean;
	disabled?: boolean;
}

export function ReportGenerator({
	company_id,
	filterParams,
	pending,
	sortColumn,
	disabled = false,
}: ReportProps) {
	const { currentCompany } = useAuth();

	const fetchRefundsQuery = useQuery<FilterRefundsReturn>(
		['refundsListToReport', company_id, filterParams, pending, sortColumn],
		() => {
			return getRefundsToReport(filterParams, pending, company_id, sortColumn);
		},
		{
			enabled: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	async function handleGeneratePDF() {
		try {
			const PDFGenerated = await generatePDFContent();
			const blob = await pdf(PDFGenerated).toBlob();

			// Create a temporary URL for the blob
			const url = URL.createObjectURL(blob);

			// Create a link element
			const link = document.createElement('a');
			link.href = url;
			link.download = 'relatorio_de_reembolsos.pdf';

			// Simulate a click event to trigger the download
			link.dispatchEvent(new MouseEvent('click'));

			// Clean up the temporary URL
			URL.revokeObjectURL(url);
		} catch (error) {
			console.log(error);
			toast.error(
				'Ocorreu um erro ao tentar gerar o arquivo de relátorio. Tente novamente'
			);
		}
	}

	async function generatePDFContent() {
		const reportFetching = await fetchRefundsQuery.refetch();
		const { refunds, totalRefunds, totalReports } = reportFetching.data!;

		const totalValue = refunds.reduce(
			(acc, refund) => acc + Number(refund.value),
			0
		);

		return (
			<Document>
				<Page size='A4' style={refundsReportStyle.page} orientation='landscape'>
					<View style={refundsReportStyle.header} fixed>
						<Image src={FlexibleBenefitsLogo} style={refundsReportStyle.logo} />
						<View style={{ ...refundsReportStyle.headerInfo, width: '120%' }}>
							<Text style={refundsReportStyle.headerInfoLabel}>Nome:</Text>
							<Text style={refundsReportStyle.headerInfoData}>
								{currentCompany?.corporateName}
							</Text>
						</View>
						<View style={refundsReportStyle.headerInfo}>
							<Text style={refundsReportStyle.headerInfoLabel}>CNPJ:</Text>
							<Text style={refundsReportStyle.headerInfoData}>
								{cnpjMask(currentCompany?.cnpj!)}
							</Text>
						</View>
						<View style={refundsReportStyle.headerInfo}>
							<Text style={refundsReportStyle.headerInfoLabel}>N° Página:</Text>
							<Text
								style={refundsReportStyle.headerInfoData}
								render={({ pageNumber, totalPages }) =>
									`${String(pageNumber).padStart(2, '0')}/${String(
										totalPages
									).padStart(2, '0')}`
								}
							/>
						</View>
						<View style={refundsReportStyle.headerInfo}>
							<Text style={refundsReportStyle.headerInfoLabel}>
								Data do pedido:
							</Text>
							<Text style={refundsReportStyle.headerInfoData}>
								{Intl.DateTimeFormat('pt-BR', {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
									hour12: false, // 24-hour format
								}).format(new Date())}
							</Text>
						</View>
						<View style={{ ...refundsReportStyle.headerInfo, width: '60%' }}>
							<Text style={refundsReportStyle.headerInfoLabel}>
								N° do pedido:
							</Text>
							<Text style={refundsReportStyle.headerInfoData}>
								{String(totalReports).padStart(7, '0')}
							</Text>
						</View>
					</View>
					<View style={refundsReportStyle.subHeader}>
						<View style={refundsReportStyle.titleContainer}>
							<Image src={reportIcon} style={refundsReportStyle.reportLogo} />
							<View style={refundsReportStyle.reportTitleContainer}>
								<Text
									style={{
										...refundsReportStyle.reportTitle,
										color: '#FF0037',
									}}
								>
									Relatório de
								</Text>

								<Text> </Text>

								<Text style={refundsReportStyle.reportTitle}>Reembolso</Text>
							</View>
						</View>

						<View style={refundsReportStyle.subheaderDataContainer}>
							<View style={{ ...refundsReportStyle.subheaderInfo }}>
								<Text style={refundsReportStyle.subheaderLabel}>
									Total de registros:
								</Text>
								<Text style={refundsReportStyle.subheaderData}>
									{totalRefunds}
								</Text>
							</View>

							<View style={{ ...refundsReportStyle.subheaderInfo }}>
								<Text style={refundsReportStyle.subheaderLabel}>
									Valor total de reembolsos{' '}
									{pending ? 'pendentes' : 'analisados'}:
								</Text>
								<Text style={refundsReportStyle.subheaderData}>
									{convertCentsToReais(totalValue)}
								</Text>
							</View>
						</View>
					</View>

					<View style={refundsReportStyle.tableContainer}>
						<View style={refundsReportStyle.tableHeader}>
							<View
								style={{
									...refundsReportStyle.columnTitleContainer,
									width: '135%',
								}}
							>
								<Text style={refundsReportStyle.columnTitle}>Colaborador</Text>
							</View>

							{!pending && (
								<View
									style={{
										...refundsReportStyle.columnTitleContainer,
									}}
								>
									<Text style={refundsReportStyle.columnTitle}>CPF</Text>
								</View>
							)}

							<View style={refundsReportStyle.columnTitleContainer}>
								<Text style={refundsReportStyle.columnTitle}>Título</Text>
							</View>

							<View style={refundsReportStyle.columnTitleContainer}>
								<Text style={refundsReportStyle.columnTitle}>
									Data da Compra
								</Text>
							</View>

							{!pending && (
								<View style={refundsReportStyle.columnTitleContainer}>
									<Text style={refundsReportStyle.columnTitle}>
										Data da Análise
									</Text>
								</View>
							)}

							<View
								style={{
									...refundsReportStyle.columnTitleContainer,
									width: pending ? '100%' : '60%',
								}}
							>
								<Text style={refundsReportStyle.columnTitle}>Status</Text>
							</View>

							<View
								style={{
									...refundsReportStyle.columnTitleContainer,
									width: '70%',
								}}
							>
								<Text style={refundsReportStyle.columnTitle}>Valor</Text>
							</View>
						</View>

						{refunds.map((refund) => (
							<View
								style={refundsReportStyle.tableRow}
								key={refund.id}
								wrap={false}
							>
								<Text
									style={{
										...refundsReportStyle.columnData,
										width: '135%',
									}}
								>
									{`${refund.collaborator.first_name} ${refund.collaborator.last_name}`}
								</Text>

								{!pending && (
									<Text
										style={{
											...refundsReportStyle.columnData,
										}}
									>
										{cpfMask(refund.collaborator.cpf)}
									</Text>
								)}

								<Text style={refundsReportStyle.columnData}>
									{refund.title}
								</Text>

								<Text style={refundsReportStyle.columnData}>
									{new Date(refund.purchase_date).toLocaleDateString()}
								</Text>

								{!pending && (
									<Text style={refundsReportStyle.columnData}>
										{new Date(refund.review_date!).toLocaleDateString()}
									</Text>
								)}

								<View
									style={{
										...refundsReportStyle.columnData,
										width: pending ? '100%' : '60%',
									}}
								>
									{parseRefundStatusToReport(refund.status)}
								</View>

								<Text
									style={{
										...refundsReportStyle.columnData,
										fontWeight: 600,
										width: '70%',
									}}
								>
									{convertCentsToReais(Number(refund.value))}
								</Text>
							</View>
						))}
					</View>
				</Page>
			</Document>
		);
	}

	return (
		<>
			<ReportButton onClick={handleGeneratePDF} disabled={disabled}>
				{fetchRefundsQuery.isLoading ? (
					<Loader color='#fff' size={15} />
				) : (
					'Gerar relatório'
				)}
			</ReportButton>
		</>
	);
}
