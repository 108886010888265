import { useState } from 'react';
import { MdNotes, MdOutlineRecycling } from 'react-icons/md';
import { DoubleCheck } from 'akar-icons';
import { Payment } from '../../../../@types';
import PageTitle from '../../../../components/PageTitle';
import { PaymentsProps } from '..';
import {
	getStatusIcon,
	parseStatusString,
} from '../../../../utils/parsePaymentStatus';

import * as TableStyle from '../../../../components/Table/TableStyles';
import * as S from '../styles';
import Loader from '../../../../components/Loader';
import { useAuth } from '../../../../hooks/useAuth';
import { parseDate } from '../../../../utils/parseDate';
import { BsFileEarmarkText } from 'react-icons/bs';
import { EmptyContent } from '../../../../components/EmptyContent';
import { Pagination } from '../../../../components/Pagination';
import { useQuery, useQueryClient } from 'react-query';
import {
	getFilteredReleases,
	getFilteredReleasesPendingAdmin,
	getFilteredScheduledReleases,
	getOtherPayments,
	getRequestedPayments,
	getScheduledPayments,
	PaymentsListReturn,
} from '../../../../services/queries/PaymentsV2';
import { convertCentsToReais } from '../../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { FaFileExcel, FaFilePdf, FaInfoCircle } from 'react-icons/fa';
import IconHover from '../../../../components/IconHover';
import { Filter, FilterParams } from '../../../../components/Filter';
import { ReleasesFilterReturn } from '../../../../services/queries/PaymentsV2';
import { usePaymentsListStore } from '../../../../stores/usePaymentListStore';
import { useHistoryNonMatchCallback } from '../../../../hooks/useHistoryNonMatchCallback';
import { useSortColumnHook } from '../../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../../components/SortColumnButton';
import { Dropdown, Menu } from 'uiw';

export default function PaymentsAdmin({
	handleDownloadPayment,
}: PaymentsProps) {
	const { user, currentCompany, updateCompanyBalance } = useAuth();
	const queryClient = useQueryClient();
	const [showingTable, setShowingTable] = useState<string>();
	const {
		currentSortColumn: currentSortColumnPending,
		toggleSort: toggleSortPending,
	} = useSortColumnHook();
	const {
		currentSortColumn: currentSortColumnNonPending,
		toggleSort: toggleSortNonPending,
	} = useSortColumnHook();
	const {
		currentSortColumn: currentSortColumnScheduled,
		toggleSort: toggleSortScheduled,
	} = useSortColumnHook();
	const [pendingReleasesCurrentPage, setPendingReleasesCurrentPage] =
		useState(1);
	const [nonPendingReleasesCurrentPage, setNonPendingReleasesCurrentPage] =
		useState(1);
	const [scheduledReleasesCurrentPage, setScheduledReleasesCurrentPage] =
		useState(1);
	const [
		pendingReleasesFiltersParams,
		setPendingReleasesFiltersParams,
		nonPendingReleasesFiltersParams,
		setNonPendingReleasesFiltersParams,
		scheduledReleasesFiltersParams,
		setScheduledReleasesFiltersParams,
		resetFilters,
	] = usePaymentsListStore((state) => [
		state.pendingReleasesFiltersParams,
		state.setPendingReleasesFiltersParams,
		state.nonPendingReleasesFiltersParams,
		state.setNonPendingReleasesFiltersParams,
		state.scheduledReleasesFiltersParams,
		state.setScheduledReleasesFiltersParams,
		state.resetFilters,
	]);

	// reset filters when navigating outside payments pages
	useHistoryNonMatchCallback('payments', resetFilters);

	function updateFilters(
		filters: FilterParams[],
		pending: boolean,
		scheduled: boolean
	) {
		if (pending) {
			setPendingReleasesCurrentPage(1);
			setPendingReleasesFiltersParams(filters);
		} else if (scheduled) {
			setScheduledReleasesCurrentPage(1);
			setScheduledReleasesFiltersParams(filters);
		} else {
			setNonPendingReleasesCurrentPage(1);
			setNonPendingReleasesFiltersParams(filters);
		}
	}

	const filteredPendingReleasesQuery = useQuery<ReleasesFilterReturn, Error>(
		[
			'filteredReleases',
			pendingReleasesFiltersParams,
			true,
			currentCompany?.id,
			pendingReleasesCurrentPage,
			currentSortColumnPending,
		],
		() => {
			return getFilteredReleasesPendingAdmin(
				pendingReleasesFiltersParams,
				currentCompany?.id,
				true,
				pendingReleasesCurrentPage,
				currentSortColumnPending
			);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os pagamentos. '
				);
			},
			enabled:
				!!pendingReleasesFiltersParams.find((f) => !!f.value) &&
				!!pendingReleasesCurrentPage,
		}
	);

	const filteredScheduledReleasesQuery = useQuery<ReleasesFilterReturn, Error>(
		[
			'filteredReleases',
			scheduledReleasesFiltersParams,
			false,
			currentCompany?.id,
			scheduledReleasesCurrentPage,
			currentSortColumnScheduled,
		],
		() => {
			return getFilteredScheduledReleases(
				scheduledReleasesFiltersParams,
				currentCompany?.id,
				true,
				scheduledReleasesCurrentPage,
				currentSortColumnScheduled
			);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os pagamentos. '
				);
			},
			enabled:
				!!scheduledReleasesFiltersParams.find((f) => !!f.value) &&
				!!scheduledReleasesCurrentPage,
		}
	);

	const filteredNonPendingReleasesQuery = useQuery<ReleasesFilterReturn, Error>(
		[
			'filteredReleases',
			nonPendingReleasesFiltersParams,
			false,
			currentCompany?.id,
			nonPendingReleasesCurrentPage,
			currentSortColumnNonPending,
		],
		() => {
			return getFilteredReleases(
				nonPendingReleasesFiltersParams,
				currentCompany?.id,
				nonPendingReleasesCurrentPage,
				currentSortColumnNonPending
			);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os pagamentos. '
				);
			},
			enabled:
				!!nonPendingReleasesFiltersParams.find((f) => !!f.value) &&
				!!nonPendingReleasesCurrentPage,
		}
	);

	const otherPaymentsQuery = useQuery<PaymentsListReturn, Error>(
		[
			'otherPaymentsList',
			currentCompany?.id,
			nonPendingReleasesCurrentPage,
			currentSortColumnNonPending,
		],
		() => {
			return getOtherPayments(
				nonPendingReleasesCurrentPage,
				currentCompany?.id,
				currentSortColumnNonPending
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os lançamentos. '
				);
			},
			keepPreviousData: true,
		}
	);

	const scheduledPaymentsQuery = useQuery<PaymentsListReturn, Error>(
		[
			'scheduledPaymentsList',
			currentCompany?.id,
			scheduledReleasesCurrentPage,
			currentSortColumnScheduled,
		],
		() => {
			return getScheduledPayments(
				scheduledReleasesCurrentPage,
				currentCompany?.id,
				currentSortColumnScheduled
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os lançamentos. '
				);
			},
			keepPreviousData: true,
		}
	);

	const requestedPaymentsQuery = useQuery<PaymentsListReturn, Error>(
		[
			'requestedPaymentsList',
			currentCompany?.id,
			pendingReleasesCurrentPage,
			currentSortColumnPending,
		],
		() => {
			return getRequestedPayments(
				pendingReleasesCurrentPage,
				currentCompany?.id,
				currentSortColumnPending
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os lançamentos. '
				);
			},
			keepPreviousData: true,
		}
	);

	function getTableTotalRecords(tableName: string) {
		if (tableName === 'Pendentes') {
			if (pendingReleasesFiltersParams.find((f) => !!f.value)) {
				return filteredPendingReleasesQuery.data?.totalRelease;
			}
			return requestedPaymentsQuery.data?.totalRequested;
		}
		if (tableName === 'Concluídos') {
			if (nonPendingReleasesFiltersParams.find((f) => !!f.value)) {
				return filteredNonPendingReleasesQuery.data?.totalRelease;
			}
			return otherPaymentsQuery.data?.totalOther;
		}
		if (tableName === 'Agendados') {
			if (scheduledReleasesFiltersParams.find((f) => !!f.value)) {
				return filteredScheduledReleasesQuery.data?.totalRelease;
			}
			return scheduledPaymentsQuery.data?.totalScheduled;
		}
	}

	function reloadPage() {
		setNonPendingReleasesCurrentPage(1);
		setPendingReleasesCurrentPage(1);
		setScheduledReleasesCurrentPage(1);
		queryClient.resetQueries('otherPaymentsList');
		queryClient.resetQueries('scheduledPaymentsList');
		queryClient.resetQueries('requestedPaymentsList');
		queryClient.resetQueries('filteredReleases');

		updateCompanyBalance.refetch();
	}

	function isIndividualTableLoading(tableName: string) {
		if (tableName === 'Pendentes')
			return (
				filteredPendingReleasesQuery.isLoading ||
				requestedPaymentsQuery.isPreviousData
			);
		if (tableName === 'Concluídos')
			return (
				filteredNonPendingReleasesQuery.isLoading ||
				otherPaymentsQuery.isPreviousData
			);
		if (tableName === 'Agendados')
			return (
				filteredScheduledReleasesQuery.isLoading ||
				scheduledPaymentsQuery.isPreviousData
			);

		return false;
	}

	function getSortColumnOrder(columnName: string, tableName: string) {
		if (tableName === 'Pendentes') {
			return currentSortColumnPending?.name === columnName
				? currentSortColumnPending.order
				: null;
		} else if (tableName === 'Concluídos') {
			return currentSortColumnNonPending?.name === columnName
				? currentSortColumnNonPending.order
				: null;
		}

		return currentSortColumnScheduled?.name === columnName
			? currentSortColumnScheduled.order
			: null;
	}
	function handleToggleSortColumn(columnName: string, tableName: string) {
		if (tableName === 'Pendentes') toggleSortPending(columnName);
		else if (tableName === 'Concluídos') toggleSortNonPending(columnName);
		else toggleSortScheduled(columnName);
	}

	function generateRow(payment: Payment) {
		return (
			<TableStyle.TableRow key={payment.id}>
				<TableStyle.TableData>{payment.order_id ?? 'N/A'}</TableStyle.TableData>
				<TableStyle.TableData>
					<TableStyle.DocumentContainer>
						<TableStyle.IconContainer>
							<BsFileEarmarkText />
						</TableStyle.IconContainer>
						{parseDate(payment.created_at, 1, payment.reference_month)}

						{payment.updated ? (
							payment.identify_operator_editor === user.id ? (
								<TableStyle.LabelEdited color={'#0AD1A8'}>
									<p>Você editou</p>
								</TableStyle.LabelEdited>
							) : (
								<TableStyle.LabelEdited>
									<p>Editado por {payment.editor_operator?.name}</p>
								</TableStyle.LabelEdited>
							)
						) : (
							<></>
						)}
					</TableStyle.DocumentContainer>
				</TableStyle.TableData>
				<TableStyle.TableData>
					{convertCentsToReais(Number(payment.total_value))}
				</TableStyle.TableData>
				<TableStyle.TableData>
					<TableStyle.StatusContainer>
						{getStatusIcon(payment.status)}
						<span>{parseStatusString(payment.status)}</span>
						{payment.status === 'canceled' && (
							<IconHover
								Icon={FaInfoCircle}
								textHover='Pagamento não efetivado. Relançamento disponível.'
								colorIcon='var(--primary-red)'
							/>
						)}
						{payment.status === 'chargeback-partial' && (
							<IconHover
								Icon={FaInfoCircle}
								textHover='Alguns colaboradores nesse pagamento não possuiam saldo suficiente para o estorno completo.'
								colorIcon='var(--primary-red)'
							/>
						)}
					</TableStyle.StatusContainer>
					{(payment.status === 'scheduled' ||
						(payment.scheduledDate && payment.status === 'requested')) && (
						<S.ScheduledDate>
							{new Date(payment.scheduledDate!).toLocaleString()}
						</S.ScheduledDate>
					)}
				</TableStyle.TableData>
				<TableStyle.TableData>
					{payment.creator_operator.name}
				</TableStyle.TableData>
				<TableStyle.TableData>
					<S.OptionsContainer>
						{payment.status === 'requested' ? (
							<>
								<TableStyle.OptionLink
									data-rh='Revisar pagamento'
									to={`/home/payments/${payment.id}/review`}
								>
									<DoubleCheck />
								</TableStyle.OptionLink>
							</>
						) : (
							<>
								<TableStyle.OptionLink
									data-rh='Relançar este pagamento'
									to={`/home/payments/relaunch/${payment.id}`}
								>
									<MdOutlineRecycling />
								</TableStyle.OptionLink>

								<TableStyle.OptionLink
									data-rh='Detalhamento'
									to={`/home/payments/details/${payment.id}`}
								>
									<MdNotes />
								</TableStyle.OptionLink>

								<Dropdown
									menu={
										<Menu bordered style={{ minWidth: 120 }}>
											<Menu.Item
												key={'details'}
												text={'Detalhado'}
												onClick={async () =>
													await handleDownloadPayment(payment.id, true, true)
												}
											/>
											<Menu.Item
												key={'simplified'}
												text={'Simplificado'}
												onClick={async () =>
													await handleDownloadPayment(payment.id, true, false)
												}
											/>
										</Menu>
									}
									trigger='click'
									placement='bottom'
								>
									<TableStyle.OptionButton data-rh='Baixar relatório PDF'>
										<FaFilePdf />
									</TableStyle.OptionButton>
								</Dropdown>

								<TableStyle.OptionButton
									data-rh='Baixar relatório XLSX'
									onClick={async () =>
										await handleDownloadPayment(payment.id, false)
									}
								>
									<FaFileExcel />
								</TableStyle.OptionButton>
							</>
						)}
					</S.OptionsContainer>
				</TableStyle.TableData>
			</TableStyle.TableRow>
		);
	}

	function getTable(tableName: string, payments?: Payment[]) {
		if (isIndividualTableLoading(tableName) || !payments?.length) {
			return (
				<>
					<TableStyle.TitleWrapper>
						<PageTitle title={tableName} />
						{tableName === 'Pendentes' ? (
							<>
								<Filter
									filterParams={pendingReleasesFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, true, false);
									}}
								/>
							</>
						) : (
							<>
								<Filter
									filterParams={nonPendingReleasesFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, false, false);
									}}
								/>
							</>
						)}
						{tableName === 'Pendentes' && (
							<TableStyle.ReloadIcon type='reload' onClick={reloadPage} />
						)}
					</TableStyle.TitleWrapper>
					{isIndividualTableLoading(tableName) ? (
						<Loader />
					) : (
						<EmptyContent text='Não há ítens a serem exibidos.' />
					)}
				</>
			);
		}
		return (
			<>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle
							title={tableName}
							totalRecords={getTableTotalRecords(tableName) ?? 0}
						/>
						{tableName === 'Pendentes' ? (
							<>
								<Filter
									filterParams={pendingReleasesFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, true, false);
									}}
								/>
							</>
						) : (
							<>
								<Filter
									filterParams={nonPendingReleasesFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, false, false);
									}}
								/>
							</>
						)}
						{showingTable === tableName ? (
							<TableStyle.SeeLess
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver menos
							</TableStyle.SeeLess>
						) : (
							<TableStyle.SeeMore
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver mais
							</TableStyle.SeeMore>
						)}
					</TableStyle.TitleWrapper>

					{showingTable === tableName ? (
						tableName === 'Pendentes' ? (
							pendingReleasesFiltersParams.find((f) => !!f.value) ? (
								<Pagination
									onPageChange={(page) => setPendingReleasesCurrentPage(page)}
									currentPage={pendingReleasesCurrentPage}
									totalCount={
										filteredPendingReleasesQuery.data
											? filteredPendingReleasesQuery.data?.totalRelease!
											: 1
									}
								/>
							) : (
								<Pagination
									onPageChange={(page) => setPendingReleasesCurrentPage(page)}
									currentPage={pendingReleasesCurrentPage}
									totalCount={
										requestedPaymentsQuery.data
											? requestedPaymentsQuery.data?.totalRequested!
											: 1
									}
								/>
							)
						) : nonPendingReleasesFiltersParams.find((f) => !!f.value) ? (
							<Pagination
								onPageChange={(page) => setNonPendingReleasesCurrentPage(page)}
								currentPage={nonPendingReleasesCurrentPage}
								totalCount={
									filteredNonPendingReleasesQuery.data
										? filteredNonPendingReleasesQuery.data?.totalRelease!
										: 1
								}
							/>
						) : (
							<Pagination
								onPageChange={(page) => setNonPendingReleasesCurrentPage(page)}
								currentPage={nonPendingReleasesCurrentPage}
								totalCount={
									otherPaymentsQuery.data
										? otherPaymentsQuery.data.totalOther!
										: 1
								}
							/>
						)
					) : (
						<></>
					)}
					{tableName !== 'Concluídos' && (
						<TableStyle.ReloadIcon type='reload' onClick={reloadPage} />
					)}
				</TableStyle.TableHeaderContainer>

				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							<TableStyle.TableHeaderCell>ID</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder('created_at', tableName)}
									onToggleSort={() =>
										handleToggleSortColumn('created_at', tableName)
									}
								/>
								PERÍODO
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder('total_value', tableName)}
									onToggleSort={() =>
										handleToggleSortColumn('total_value', tableName)
									}
								/>
								VALOR TOTAL
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>STATUS</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder('creator_operator', tableName)}
									onToggleSort={() =>
										handleToggleSortColumn('creator_operator', tableName)
									}
								/>
								OPERADOR
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
						</TableStyle.TableRow>
					</TableStyle.TableHeader>

					<TableStyle.TableBody>
						{showingTable === tableName
							? payments.map((payment) => generateRow(payment))
							: generateRow(payments[0])}
					</TableStyle.TableBody>
				</TableStyle.Table>
			</>
		);
	}

	function getTableScheduled(tableName: string, payments?: Payment[]) {
		if (isIndividualTableLoading(tableName) || !payments?.length) {
			return (
				<>
					<TableStyle.TitleWrapper>
						<PageTitle title={tableName} />
						{tableName === 'Agendados' && (
							<>
								<Filter
									filterParams={scheduledReleasesFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, false, true);
									}}
								/>
							</>
						)}
					</TableStyle.TitleWrapper>
					{isIndividualTableLoading(tableName) ? (
						<Loader />
					) : (
						<EmptyContent text='Não há ítens a serem exibidos.' />
					)}
				</>
			);
		}
		return (
			<>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle
							title={tableName}
							totalRecords={getTableTotalRecords(tableName) ?? 0}
						/>
						{tableName === 'Agendados' ? (
							<>
								<Filter
									filterParams={scheduledReleasesFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, false, true);
									}}
								/>
							</>
						) : (
							<></>
						)}
						{showingTable === tableName ? (
							<TableStyle.SeeLess
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver menos
							</TableStyle.SeeLess>
						) : (
							<TableStyle.SeeMore
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver mais
							</TableStyle.SeeMore>
						)}
					</TableStyle.TitleWrapper>

					{showingTable === tableName ? (
						tableName === 'Agendados' ? (
							scheduledReleasesFiltersParams.find((f) => !!f.value) ? (
								<Pagination
									onPageChange={(page) => setScheduledReleasesCurrentPage(page)}
									currentPage={scheduledReleasesCurrentPage}
									totalCount={
										filteredScheduledReleasesQuery.data
											? filteredScheduledReleasesQuery.data?.totalRelease!
											: 1
									}
								/>
							) : (
								<></>
							)
						) : (
							<></>
						)
					) : (
						<></>
					)}
				</TableStyle.TableHeaderContainer>

				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							<TableStyle.TableHeaderCell>ID</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder('created_at', tableName)}
									onToggleSort={() =>
										handleToggleSortColumn('created_at', tableName)
									}
								/>
								PERÍODO
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder('total_value', tableName)}
									onToggleSort={() =>
										handleToggleSortColumn('total_value', tableName)
									}
								/>
								VALOR TOTAL
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>STATUS</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder('creator_operator', tableName)}
									onToggleSort={() =>
										handleToggleSortColumn('creator_operator', tableName)
									}
								/>
								OPERADOR
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
						</TableStyle.TableRow>
					</TableStyle.TableHeader>

					<TableStyle.TableBody>
						{showingTable === tableName
							? payments.map((payment) => generateRow(payment))
							: generateRow(payments[0])}
					</TableStyle.TableBody>
				</TableStyle.Table>
			</>
		);
	}

	if (otherPaymentsQuery.isLoading || requestedPaymentsQuery.isLoading) {
		return (
			<S.Container>
				<PageTitle title={'Pagamentos'} />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			{getTable(
				'Pendentes',
				pendingReleasesFiltersParams.find((f) => !!f.value)
					? filteredPendingReleasesQuery.data?.requestedReleases
					: requestedPaymentsQuery.data?.requestedReleases
			)}

			{getTable(
				'Concluídos',
				nonPendingReleasesFiltersParams.find((f) => !!f.value)
					? filteredNonPendingReleasesQuery.data?.requestedReleases
					: otherPaymentsQuery.data?.otherReleases
			)}

			{getTableScheduled(
				'Agendados',
				scheduledReleasesFiltersParams.find((f) => !!f.value)
					? filteredScheduledReleasesQuery.data?.requestedReleases
					: scheduledPaymentsQuery.data?.scheduledReleases
			)}
		</S.Container>
	);
}
