import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import {
	MdKeyboardArrowRight,
	MdOutlineKeyboardArrowLeft,
	MdOutlineKeyboardArrowRight,
} from 'react-icons/md';
import { useQuery } from 'react-query';
import { Transaction } from '../../../@types/CorporateExpenses/Transaction';
import { InfoContent } from '../../../componentsV2/InfoContent';
import { Table } from '../../../componentsV2/Table';
import { Button } from '../../../componentsV2/ui/Button';
import { InputComp } from '../../../componentsV2/ui/Form/InputStyle';
import { SectionContainer } from '../../../componentsV2/ui/SectionContainer';
import {
	TitleTypography,
	Typography,
} from '../../../componentsV2/ui/Typography';
import { useAuth } from '../../../hooks/useAuth';
import {
	CORPWAY_CARD_BALANCE_KEY,
	CORPWAY_EXTRACT_KEY,
	getCardBalance,
	getCardExtractByMonth,
	getExtractDetails,
} from '../../../services/queries/Corpway/Extract';
import { useCardSelectStore } from '../../../stores/corpway/useCardSelectStore';
import { convertCentsToReais } from '../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import {
	getCardStatusIcon,
	parseCardStatus,
} from '../Cards/utils/parseCardStatus';
import { CardSelect } from './components/CardSelect';
import { CostCenterSelect } from './components/CostCenterSelect';
import { ExtractDetailsModal } from './components/ExtractDetailsModal';
import { ReportGeneratorModal } from './components/ReportGeneratorModal';
import * as S from './styles';
import {
	checkDeclineAndStrikeText,
	parseTransactionType,
	truncateString,
} from './utils/transactionUtils';
import { StaticCheckbox } from '../../../componentsV2/ui/Form/CheckboxField';
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import { CardNotificationsAndTransactionsModals } from '../Cards/components/CardDetailsModal/CardNotificationsAndTransactionsModals';
import { UpdateCardBalanceModal } from '../Cards/components/UpdateCardBalanceModal';
import { FaPlus } from 'react-icons/fa';
import styled from 'styled-components';
import { StyledParagraph } from './styles';

const columnHelper = createColumnHelper<Transaction>();

export function Extract() {
	const { currentCompany, user: currentUser } = useAuth();
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 1080);

	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth <= 1080);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const hasAcessToUpdateCardBalanceModal =
		currentUser.roles.includes('manage-balances');

	const HasPermissionToCreateCards = currentUser.roles.includes('manage-cards');

	const [
		selectedCard,
		activeCardsNumber,
		costCenterFilter,
		selectedTabFilter,
		setCardSelectStoreState,
	] = useCardSelectStore((state) => [
		state.selectedCard,
		state.activeCardsNumber,
		state.costCenterFilter,
		state.selectedTabFilter,
		state.setState,
	]);
	const [isModalOpen, setModalOpen] = useState(false);

	const enableAll =
		currentUser.corpway_access_level !== 'card_responsible' ||
		(activeCardsNumber ?? 0) > 1;
	const date = new Date();
	const currentMonthDate = `${date.getFullYear()}-${(date.getMonth() + 1)
		.toString()
		.padStart(2, '0')}`;
	const [monthDate, setMonthDate] = useState(
		`${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`
	);

	const dateLimit = date;
	const january2024 = new Date('January 1, 2024');
	dateLimit.setMonth(dateLimit.getMonth() - 17);
	dateLimit.setDate(1);
	dateLimit.setHours(0, 0, 0, 0);

	if (dateLimit < january2024) {
		dateLimit.setDate(1);
		dateLimit.setMonth(0);
		dateLimit.setFullYear(2024);
		dateLimit.setHours(0, 0, 0, 0);
	}

	const getExtractQuery = useQuery(
		[CORPWAY_EXTRACT_KEY, currentCompany?.id, monthDate, selectedCard],
		() =>
			getCardExtractByMonth(
				selectedCard!.id,
				currentCompany!.id,
				monthDate,
				[]
			),
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar o extrato. ');
			},
			enabled: !!selectedCard,
			refetchOnWindowFocus: false,
		}
	);

	const getCardBalanceQuery = useQuery(
		[CORPWAY_CARD_BALANCE_KEY, currentCompany?.id, monthDate, selectedCard],
		() => getCardBalance(selectedCard!.id, currentCompany!.id),
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar o extrato. ');
			},
			enabled: !!selectedCard,
			refetchOnWindowFocus: false,
		}
	);

	function handleChangeMonthDate(increment: -1 | 1) {
		const [year, month] = monthDate.split('-').map(Number);

		const newDate = new Date(year, month - 1, 1);

		newDate.setMonth(newDate.getMonth() + increment);

		if (newDate >= dateLimit) {
			setMonthDate(
				`${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(
					2,
					'0'
				)}`
			);
		}
	}

	function TransactionCell({
		response_code,
		merchant_name,
		transaction_id,
		transactionType,
	}: any) {
		const getTransactionQuery = useQuery(
			[CORPWAY_EXTRACT_KEY, transaction_id],
			() => getExtractDetails(transaction_id[0].internal_transaction_id),
			{
				enabled:
					merchant_name == null &&
					transactionType === 'Estorno externo' &&
					getExtractQuery.isSuccess,
			}
		);

		if (merchant_name == null) {
			return <>{getTransactionQuery.data?.merchant_name ?? '--'}</>;
		}

		const merchant = checkDeclineAndStrikeText(response_code, merchant_name);

		return <>{merchant}</>;
	}

	const columns = useMemo(
		() => [
			columnHelper.accessor('added_time', {
				cell: (info) => new Date(info.getValue()).toLocaleDateString(),
				header: 'Data',
			}),
			columnHelper.accessor((row) => row, {
				cell: (info) => {
					const { txn_type, msg_type, currency, merchant_country } =
						info.getValue();

					return parseTransactionType(
						txn_type,
						msg_type,
						currency,
						merchant_country
					);
				},
				header: 'Lançamento',
			}),
			columnHelper.accessor((row) => row, {
				cell: (info) => {
					const {
						response_code,
						merchant_name,
						merchant_country,
						linked_transactions,
						txn_type,
						msg_type,
						currency,
					} = info.getValue();

					const transactionType = parseTransactionType(
						txn_type,
						msg_type,
						currency,
						merchant_country
					);

					const TransactionFilter = linked_transactions?.filter(
						(transaction) =>
							transaction.link_type === 'EXPIRES' ||
							transaction.link_type === 'REVERSES' ||
							transaction.link_type === 'REFUNDS'
					);

					return (
						<TransactionCell
							response_code={response_code}
							merchant_name={merchant_name}
							transaction_id={TransactionFilter}
							transactionType={transactionType}
						/>
					);
				},
				header: 'Estabelecimento',
			}),
			columnHelper.accessor((row) => row, {
				cell: (info) => {
					const {
						response_code,
						billing_amount,
						txn_type,
						msg_type,
						currency,
						merchant_country,
					} = info.getValue();
					const transactionType = parseTransactionType(
						txn_type,
						msg_type,
						currency,
						merchant_country
					);
					const parsedValue = convertCentsToReais(billing_amount);
					return (
						<span style={{ display: 'flex' }}>
							{checkDeclineAndStrikeText(response_code, parsedValue)}
							{transactionType === 'Adição de saldo' ||
							transactionType === 'Estorno de IOF' ||
							transactionType === 'Estorno externo' ? (
								<IoIosArrowRoundUp size={20} color='#00a22d' />
							) : (
								<IoIosArrowRoundDown size={20} color='var(--primary-red)' />
							)}
						</span>
					);
				},
				header: 'Valor',
			}),
			columnHelper.display({
				cell: (props) => {
					const { transactionInvoices } = props.row.original;

					return (
						<StaticCheckbox
							disabled
							checked={!!transactionInvoices?.invoices?.length}
						/>
					);
				},
				meta: {
					align: 'center',
				},
				header: 'Anexo',
			}),
			columnHelper.display({
				header: 'Detalhes',
				meta: {
					align: 'center',
				},
				cell: (props) => (
					<ExtractDetailsModal transaction={props.row.original} />
				),
			}),
		],
		[getExtractQuery.data]
	);

	function balanceText() {
		const balance = getCardBalanceQuery.data;
		if (balance !== undefined) {
			return convertCentsToReais(balance);
		}
		return '-----';
	}

	return (
		<div>
			<SectionContainer style={{ gap: '2rem' }}>
				<S.HeaderContainer>
					<S.HeaderSubContainer style={{ justifyContent: 'space-between' }}>
						<TitleTypography size='2.4rem' primaryText='Meus'>
							cartões
						</TitleTypography>
						{HasPermissionToCreateCards && (
							<Button
								as='link'
								to={'/corporate-expenses/cards/create'}
								intent='primary'
								roundness='lg'
								style={{
									columnGap: '0.6rem',
									fontWeight: 550,
									fontFamily: 'poppins',
									fontSize: '1.5rem',
									padding: '1rem 3.5rem',
									paddingRight: '2.4rem',
								}}
							>
								Novo cartão
								<MdKeyboardArrowRight
									style={{ fontSize: '2.3rem', width: 'auto', height: 'auto' }}
								/>
							</Button>
						)}
					</S.HeaderSubContainer>
					<StyledParagraph>
						Aqui você pode criar e acessar todos os seus cartões e filtrá-los
						por
						<br />
						centro de custo ou status, além de acompanhar o extrato de cada
						cartão.
					</StyledParagraph>
					<S.HeaderSubContainer>
						<CostCenterSelect
							selectedCostCenter={costCenterFilter}
							onSelect={(c) => setCardSelectStoreState({ costCenterFilter: c })}
						/>
					</S.HeaderSubContainer>
				</S.HeaderContainer>

				<CardSelect />

				{selectedCard && (
					<S.GraySection>
						<S.SelectedCardSection>
							<InfoContent label='Centro de custo'>
								{selectedCard.cost_center_data?.title
									? truncateString(
											selectedCard.cost_center_data.title
												.charAt(0)
												.toUpperCase() +
												selectedCard.cost_center_data.title
													.slice(1)
													.toLowerCase()
									  )
									: ''}
							</InfoContent>

							{isMobile ? (
								<div className='responsavel-status-container'>
									<InfoContent label='Responsável'>
										{selectedCard.user_data?.name
											? truncateString(selectedCard.user_data.name)
											: ''}
									</InfoContent>

									<InfoContent label='Status'>
										<Typography style={{ gap: '0.5rem' }} weight='600'>
											{getCardStatusIcon(selectedCard.status)}
											{parseCardStatus(selectedCard.status)}
										</Typography>
									</InfoContent>
								</div>
							) : (
								<>
									<InfoContent label='Responsável'>
										{selectedCard.user_data?.name
											? truncateString(selectedCard.user_data.name)
											: ''}
									</InfoContent>

									<InfoContent label='Status'>
										<Typography style={{ gap: '0.5rem' }} weight='600'>
											{getCardStatusIcon(selectedCard.status)}
											{parseCardStatus(selectedCard.status)}
										</Typography>
									</InfoContent>
								</>
							)}

							<InfoContent label='Saldo do cartão' orientation='vertical'>
								<S.Balance>
									{balanceText()}
									{hasAcessToUpdateCardBalanceModal &&
										selectedTabFilter === 'Ativos' && (
											<OpenButton onClick={() => setModalOpen(true)}>
												<FaPlus color='#FFF' size={10} />
											</OpenButton>
										)}
								</S.Balance>
							</InfoContent>

							{(currentUser.roles.includes('use-all-cards') ||
								currentUser.roles.includes('use-own-cards')) && (
								<InfoContent label=''>
									<CardNotificationsAndTransactionsModals card={selectedCard} />
								</InfoContent>
							)}
						</S.SelectedCardSection>
					</S.GraySection>
				)}

				{selectedCard && isModalOpen && (
					<UpdateCardBalanceModal
						card={selectedCard}
						isModalOpen={isModalOpen}
						setModalOpen={setModalOpen}
					/>
				)}

				{selectedCard && (
					<TitleTypography
						style={{ margin: '0.7rem 0' }}
						size='2.4rem'
						primaryText='Extrato'
					>
						do cartão
					</TitleTypography>
				)}

				{selectedCard && (
					<S.Section>
						<S.DateFilterContainer>
							<Button
								className='previous-date'
								intent='link'
								iconLeft={<MdOutlineKeyboardArrowLeft />}
								onClick={() => handleChangeMonthDate(-1)}
								disabled={monthDate <= dateLimit.toISOString()}
								style={{ textUnderlineOffset: 'none' }}
							>
								Mês anterior
							</Button>
							<InputComp
								type='month'
								value={monthDate}
								max={currentMonthDate}
								onChange={(e) => setMonthDate(e.target.value)}
								min={`${dateLimit.getFullYear()}-${(dateLimit.getMonth() + 1)
									.toString()
									.padStart(2, '0')}`}
								style={{
									width: 'fit-content',
									fontWeight: 600,
									fontSize: '2rem',
									lineHeight: '2.4rem',
									borderRadius: '3.3rem',
									padding: '0 2.4rem',
								}}
								title={monthDate}
							/>
							<Button
								className='next-date'
								intent='link'
								iconRight={<MdOutlineKeyboardArrowRight />}
								onClick={() => handleChangeMonthDate(1)}
								disabled={monthDate >= currentMonthDate}
							>
								Próximo mês
							</Button>
						</S.DateFilterContainer>

						<Table
							data={getExtractQuery.data ?? []}
							asyncStatus={getExtractQuery.status}
							showHeader
							columns={columns}
							variant={'lightgray'}
							idPath='internal_transaction_id'
						/>
					</S.Section>
				)}

				{selectedCard && (
					<ReportGeneratorModal
						status={selectedTabFilter}
						card={selectedCard}
						month={monthDate}
						enableAll={enableAll}
					/>
				)}
			</SectionContainer>
		</div>
	);
}

const OpenButton = styled.button`
	width: 1.6rem;
	height: 1.6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #00a22d;
	padding: 0.3rem;
	border-radius: 50%;
	margin-right: 2rem;
`;
