import { useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import { SectionTitle } from '..';
import { AsynchronousContent } from '../../../../componentsV2/AsynchronousContent';
import { InputComp } from '../../../../componentsV2/ui/Form/InputStyle';
import { Typography } from '../../../../componentsV2/ui/Typography';
import { useAuth } from '../../../../hooks/useAuth';
import {
	CORPWAY_COST_CENTER_KEY,
	getCostCenterExpenses,
} from '../../../../services/queries/Corpway/CostCenters';
import { convertCentsToReais } from '../../../../utils/CurrencyConvert';

export function CostCenterExpenses() {
	const { user, currentCompany } = useAuth();
	const colors = ['#1F59A4', '#269EEF', '#27ADFF', '#55C6FF', '#B5E6FF'];
	const january2024 = new Date('January 1, 2024'); // start of bounty control
	const currentDate = new Date();

	const [selectedDate, setSelectedDate] = useState(currentDate);

	const getCostCenterExpensesQuery = useQuery(
		[
			CORPWAY_COST_CENTER_KEY,
			'expenses',
			user.id,
			currentCompany?.id,
			selectedDate,
		],
		() => getCostCenterExpenses(currentCompany!.id, selectedDate),
		{
			onError: () => {
				toast.error('Ocorreu um problema ao buscar o relatório de custos.');
			},
			refetchOnWindowFocus: false,
		}
	);

	function parseData(data: Record<string, number>) {
		const dataArray = Object.entries(data).map(([name, value]) => ({
			name,
			value,
		}));

		// Sort the array by value in descending order
		dataArray.sort((a, b) => b.value - a.value);

		//top 4 elements
		const topThree = dataArray.slice(0, 4);

		// Sum the values of the remaining elements
		const othersValue = dataArray
			.slice(4)
			.reduce((sum, item) => sum + item.value, 0);

		// final array with top 4 elements and "Others"
		const result = [...topThree];
		if (dataArray.length > 4) {
			result.push({ name: 'Outros', value: othersValue });
		}

		return result;
	}

	const data = getCostCenterExpensesQuery.data
		? parseData(getCostCenterExpensesQuery.data.costCenterExpenses)
		: [];
	const totalValue = data.reduce((acc, item) => acc + item.value, 0);

	return (
		<CostCenterContainer>
			<ChildContainer>
				<div>
					<SectionTitle primaryText='Relatórios'> de custos</SectionTitle>
					<Typography
						size='1.6rem'
						color='var(--mid-gray)'
						style={{ width: '24rem' }}
					>
						Acompanhe o percentual gasto por centro de custo
					</Typography>
				</div>
				<InputComp
					type='month'
					min={january2024.toISOString().split('-').slice(0, 2).join('-')}
					max={currentDate.toISOString().split('-').slice(0, 2).join('-')}
					value={selectedDate.toISOString().split('-').slice(0, 2).join('-')}
					onChange={(e) => {
						const selectedMonth = e.target.value;
						const year = parseInt(selectedMonth.split('-')[0]);
						const month = parseInt(selectedMonth.split('-')[1]);
						setSelectedDate(new Date(year, month - 1, 1));
					}}
					style={{ width: 'fit-content'}}
				/>
			</ChildContainer>

			{/* {JSON.stringify(data, null, 3)} */}
			<AsynchronousContent status={getCostCenterExpensesQuery.status}>
				<ChildContainer>
					<ChartContainer>
						<ResponsiveContainer width={220} height={220}>
							<PieChart>
								<Pie
									data={totalValue === 0 ? [{ value: 1 }] : data}
									cx='50%'
									cy='50%'
									fill='var(--light-gray)'
									innerRadius={80}
									outerRadius={100}
									paddingAngle={0}
									dataKey='value'
									stroke='none'
								>
									{totalValue === 0 ? (
										<Cell key={`cell-empty}`} fill='var(--light-gray)' />
									) : (
										data.map((entry, index) => (
											<Cell
												key={`cell-${index}`}
												fill={colors[index % colors.length]}
											/>
										))
									)}
								</Pie>
							</PieChart>
						</ResponsiveContainer>
						<ChartTotalValue>
							<Typography size='2rem' weight='600' color='var(--dark-gray)'>
								{convertCentsToReais(totalValue)}
							</Typography>
							<Typography size='1.4rem' color='var(--mid-gray)'>
								Total gasto
							</Typography>
						</ChartTotalValue>
					</ChartContainer>
					<ul style={{ width: '100%' }}>
						{data.map((entry, index) => (
							<Legend key={`legend-${entry.name}`}>
								<LegendIndicator color={colors[index % colors.length]} />
								<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
									{entry.name}
								</Typography>
								<Typography
									size='1.6rem'
									weight='600'
									color='var(--primary-blue)'
									style={{ marginLeft: 'auto' }}
								>
									{totalValue === 0
										? '0.00'
										: ((entry.value / totalValue) * 100).toFixed(2).toString()}
									%
								</Typography>
							</Legend>
						))}
					</ul>
				</ChildContainer>
			</AsynchronousContent>
		</CostCenterContainer>
	);
}

const ChildContainer = styled.div`
	display: flex;
	gap: 3rem;
	align-items: center;

	@media (max-width: 650px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 0rem;
	}
`;

const CostCenterContainer = styled.div`
	display: 'grid';
	gap: '1rem';
	min-height: '31.5rem';

	@media (max-width: 650px) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

const ChartContainer = styled.div`
	position: relative;
`;

const ChartTotalValue = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 2.4rem;
	display: grid;
	justify-items: center;
	width: 70%;
`;

const Legend = styled.li`
	display: flex;
	gap: 1rem;
	align-items: center;
	list-style: none;
	margin-bottom: 1rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid #f0f3f8;
	width: 100%;
`;

const LegendIndicator = styled.div<{ color: string }>`
	width: 1rem;
	min-width: 1rem;
	min-height: 1rem;
	height: 1rem;
	border-radius: 50%;
	background-color: ${({ color }) => color};
`;
