import { useAuth } from '../../../../../../hooks/useAuth';
import { useMutation } from 'react-query';
import {
	MovementsReportProps,
	getBalanceMovementsToReport,
} from '../../../../../../services/queries/Corpway/Funds';
import { showErrorMessage } from '../../../../../../utils/ErrorHandler';
import {
	Document,
	Font,
	Image,
	Page,
	Text,
	View,
	pdf,
} from '@react-pdf/renderer';
import { controlReportStyle } from './styles';
import { cnpjMask } from '../../../../../../utils/masks';
import controlLogo from '../../../../../../assets/corpwayExtractReport/ControlLogo.png';
import extractIcon from '../../../../../../assets/corpwayExtractReport/extractIcon.png';
import {
	parseMovementStatusToReport,
	parseMovementDetailsToPDFReport,
	parseMovementType,
} from '../../../utils/parseBalanceMovements';
import { convertCentsToReais } from '../../../../../../utils/CurrencyConvert';

Font.register({
	family: 'Poppins',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/hlvAxH6aIdOjWlLzgm0jqg.ttf',
		},
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/4WGKlFyjcmCFVl8pRsgZ9vesZW2xOQ-xsNqO47m55DA.ttf',
			fontWeight: 500,
		},
		{
			src: 'https://fonts.gstatic.com/s/poppins/v1/-zOABrCWORC3lyDh-ajNnPesZW2xOQ-xsNqO47m55DA.ttf',
			fontWeight: 600,
		},
	],
});

export function PdfReportGenerator() {
	const { currentCompany } = useAuth();

	const getBalanceMovementsQuery = useMutation(
		({
			start_date,
			end_date,
			operationTypes,
			operationStatuses,
			fundsInOrigin,
		}: MovementsReportProps) =>
			getBalanceMovementsToReport(
				currentCompany!.id,
				start_date,
				end_date,
				operationTypes,
				operationStatuses,
				fundsInOrigin
			)
	);

	async function handleGeneratePDF({
		start_date,
		end_date,
		operationTypes,
		operationStatuses,
		fundsInOrigin,
	}: MovementsReportProps) {
		try {
			const PDFGenerated = await generatePDFContent(
				start_date,
				end_date,
				operationTypes,
				operationStatuses,
				fundsInOrigin
			);
			const blob = await pdf(PDFGenerated).toBlob();

			// Create a temporary URL for the blob
			const url = URL.createObjectURL(blob);

			const startDate = new Date(start_date);
			const endDate = new Date(end_date);

			// Create a link element
			const link = document.createElement('a');
			link.href = url;
			link.download = `Bounty_Control_Gestao_${startDate.toLocaleDateString(
				'pt-BR'
			)}_${endDate.toLocaleDateString('pt-BR')}.pdf`;

			// Simulate a click event to trigger the download
			link.dispatchEvent(new MouseEvent('click'));

			// Clean up the temporary URL
			URL.revokeObjectURL(url);
		} catch (error: any) {
			if (error.message === 'empty_movements') {
				throw error;
			}

			console.log(error);
			showErrorMessage(
				error as Error,
				'Ocorreu um erro ao tentar gerar o arquivo de relatório. Tente novamente'
			);
		}
	}

	async function generatePDFContent(
		start_date: number,
		end_date: number,
		operationTypes: MovementsReportProps['operationTypes'],
		operationStatuses: MovementsReportProps['operationStatuses'],
		fundsInOrigin?: MovementsReportProps['fundsInOrigin']
	) {
		const movements = await getBalanceMovementsQuery.mutateAsync({
			start_date,
			end_date,
			operationTypes,
			operationStatuses,
			fundsInOrigin,
		});

		if (movements.balanceMovements.length === 0) {
			throw new Error('empty_movements');
		}

		const totalValues = {
			funds_in: 0,
			wallet_refund: 0,
			card_funds: 0,
		};

		movements.balanceMovements.forEach((movement) => {
			if (movement.type === 'billing' || movement.type === 'pix')
				totalValues.funds_in += Number(movement.amount);
			if (movement.type === 'card_fund') {
				if (movement.action === 'add')
					totalValues.card_funds += Number(movement.amount);
				if (movement.action === 'reverse')
					totalValues.wallet_refund += Number(movement.amount);
			}
		});

		return (
			<Document>
				<Page size='A4' style={controlReportStyle.page} orientation='landscape'>
					<View style={controlReportStyle.header} fixed>
						<Image src={controlLogo} style={controlReportStyle.logo} />
						<View style={{ ...controlReportStyle.headerInfo, width: '120%' }}>
							<Text style={controlReportStyle.headerInfoLabel}>Nome:</Text>
							<Text style={controlReportStyle.headerInfoData}>
								{currentCompany?.corporateName}
							</Text>
						</View>
						<View style={controlReportStyle.headerInfo}>
							<Text style={controlReportStyle.headerInfoLabel}>CNPJ:</Text>
							<Text style={controlReportStyle.headerInfoData}>
								{cnpjMask(currentCompany?.cnpj!)}
							</Text>
						</View>
						<View style={{ ...controlReportStyle.headerInfo, width: '70%' }}>
							<Text style={controlReportStyle.headerInfoLabel}>N° Página:</Text>
							<Text
								style={controlReportStyle.headerInfoData}
								render={({ pageNumber, totalPages }) =>
									`${String(pageNumber).padStart(2, '0')}/${String(
										totalPages
									).padStart(2, '0')}`
								}
							/>
						</View>
						<View style={controlReportStyle.headerInfo}>
							<Text style={controlReportStyle.headerInfoLabel}>
								Data do pedido:
							</Text>
							<Text style={controlReportStyle.headerInfoData}>
								{Intl.DateTimeFormat('pt-BR', {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
									hour12: false, // 24-hour format
								})
									.format(new Date())
									.replace('  ', ', ')}
							</Text>
						</View>
						<View style={{ ...controlReportStyle.headerInfo, width: '120%' }}>
							<Text style={controlReportStyle.headerInfoLabel}>
								Intervalo de busca:
							</Text>
							<Text style={controlReportStyle.headerInfoData}>
								{Intl.DateTimeFormat('pt-BR', {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
								}).format(new Date(start_date))}
								{' - '}
								{Intl.DateTimeFormat('pt-BR', {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
								}).format(new Date(end_date))}
							</Text>
						</View>
						<View style={{ ...controlReportStyle.headerInfo, width: '60%' }}>
							<Text style={controlReportStyle.headerInfoLabel}>
								N° do pedido:
							</Text>
							<Text style={controlReportStyle.headerInfoData}>
								{String(movements.totalReports).padStart(7, '0')}
							</Text>
						</View>
					</View>
					<View style={controlReportStyle.subHeader}>
						<View style={controlReportStyle.titleContainer}>
							<Image src={extractIcon} style={controlReportStyle.extractLogo} />
							<View style={controlReportStyle.reportTitleContainer}>
								<Text
									style={{
										...controlReportStyle.reportTitle,
										color: '#27ADFF',
									}}
								>
									Movimentações
								</Text>

								<Text style={controlReportStyle.reportTitle}>Da Carteira</Text>
							</View>
						</View>

						<View style={controlReportStyle.subheaderDataContainer}>
							{operationTypes.funds_in && (
								<View style={controlReportStyle.subheaderInfo}>
									<Text style={controlReportStyle.subheaderLabel}>
										Total de Recarga de Carteira:
									</Text>
									<Text style={controlReportStyle.subheaderData}>
										{convertCentsToReais(totalValues.funds_in)}
									</Text>
								</View>
							)}

							{operationTypes.card_funds && (
								<View style={controlReportStyle.subheaderInfo}>
									<Text style={controlReportStyle.subheaderLabel}>
										Total de Repasse para Cartão:
									</Text>
									<Text style={controlReportStyle.subheaderData}>
										{convertCentsToReais(totalValues.card_funds)}
									</Text>
								</View>
							)}

							<View style={controlReportStyle.subheaderInfo}>
								<Text style={controlReportStyle.subheaderLabel}>
									Total de Transações:
								</Text>
								<Text style={controlReportStyle.subheaderData}>
									{movements.totalMovements}
								</Text>
							</View>

							{/* 							<View style={controlReportStyle.subheaderInfo}>
								<Text style={controlReportStyle.subheaderLabel}>
									Total de Transferências Externas:
								</Text>
								<Text style={controlReportStyle.subheaderData}>
									R$ 100.000,00
								</Text>
							</View> */}

							{operationTypes.wallet_refund && (
								<View style={controlReportStyle.subheaderInfo}>
									<Text style={controlReportStyle.subheaderLabel}>
										Total de Estorno para Carteira:
									</Text>
									<Text style={controlReportStyle.subheaderData}>
										{convertCentsToReais(totalValues.wallet_refund)}
									</Text>
								</View>
							)}
						</View>
					</View>

					<View style={controlReportStyle.tableContainer}>
						<View style={controlReportStyle.tableHeader} fixed>
							<View
								style={{
									...controlReportStyle.columnTitleContainer,
									width: '65%',
								}}
							>
								<Text style={controlReportStyle.columnTitle}>Data</Text>
							</View>
							<View style={controlReportStyle.columnTitleContainer}>
								<Text style={controlReportStyle.columnTitle}>Usuário</Text>
							</View>
							<View
								style={{
									...controlReportStyle.columnTitleContainer,
									width: '75%',
								}}
							>
								<Text style={controlReportStyle.columnTitle}>Operação</Text>
							</View>
							<View style={controlReportStyle.columnTitleContainer}>
								<Text style={controlReportStyle.columnTitle}>
									Detalhes da transação
								</Text>
							</View>
							<View
								style={{
									...controlReportStyle.columnTitleContainer,
									width: '45%',
								}}
							>
								<Text style={controlReportStyle.columnTitle}>Status</Text>
							</View>
							<View
								style={{
									...controlReportStyle.columnTitleContainer,
									width: '50%',
								}}
							>
								<Text style={controlReportStyle.columnTitle}>Valor</Text>
							</View>
						</View>

						{movements.balanceMovements.map((movement) => (
							<View
								style={controlReportStyle.tableRow}
								key={movement.id}
								wrap={false}
							>
								<Text
									style={{ ...controlReportStyle.columnData, width: '65%' }}
								>
									{Intl.DateTimeFormat('pt-BR', {
										day: '2-digit',
										month: '2-digit',
										year: 'numeric',
										hour: '2-digit',
										minute: '2-digit',
										hour12: false, // 24-hour format
									})
										.format(new Date(movement.created_at))
										.replace(', ', ' - ')}
								</Text>

								<Text style={controlReportStyle.columnData}>
									{movement.operator?.name}
								</Text>

								<Text
									style={{ ...controlReportStyle.columnData, width: '75%' }}
								>
									{parseMovementType(movement.type, movement.action)}
								</Text>
								<View style={controlReportStyle.columnData}>
									{parseMovementDetailsToPDFReport(movement)}
								</View>
								<View
									style={{ ...controlReportStyle.columnData, width: '45%' }}
								>
									{parseMovementStatusToReport(movement.status!)}
								</View>
								<View
									style={{
										...controlReportStyle.amountContainer,
										width: '50%',
									}}
								>
									<Text style={controlReportStyle.columnData}>
										{convertCentsToReais(movement.amount)}
									</Text>
								</View>
							</View>
						))}
					</View>
				</Page>
			</Document>
		);
	}

	return {
		generatePDF: handleGeneratePDF,
	};
}
