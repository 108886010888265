import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Chargeback } from '../../../../@types';
import Loader from '../../../../components/Loader';
import PageTitle from '../../../../components/PageTitle';
import FeedbackModal from '../../../../components/FeedbackModal';
import UserCard from '../../../../components/UserCard';
import { useAuth } from '../../../../hooks/useAuth';
import { useDialogModal } from '../../../../hooks/useDialogModal';
import { parseDate } from '../../../../utils/parseDate';
import {
	getStatusIcon,
	parseStatusString,
} from '../../../../utils/parseStatus';
import * as S from './styles';
import { useMutation, useQuery } from 'react-query';
import {
	approveChargeback,
	cancelChargeback,
	fetchChargeback,
	reproveChargeback,
} from '../../../../services/queries/Chargebacks';
import { getBenefitParsedTitle } from '../../../../utils/benefits/getBenefitParsedTitle';
import { convertCentsToReais } from '../../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { toast } from 'react-toastify';

interface ChargebackDetailsParams {
	id: string;
}

export default function ChargebackDetails() {
	const { id } = useParams<ChargebackDetailsParams>();
	const { user, currentCompany, updateCompanyBalance } = useAuth();
	const { openConfirmDialog } = useDialogModal();
	const history = useHistory();
	const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

	const fetchChargebackQuery = useQuery<Chargeback, Error>(
		['fetchChargeback', currentCompany?.id, id],
		() => {
			return fetchChargeback(id, currentCompany?.id);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar o estorno. '
				);
			},
		}
	);

	const reproveChargebackQuery = useMutation(
		(feedback: string) => {
			if (isUserTheChargebackAuthor(fetchChargebackQuery.data!)) {
				return cancelChargeback(feedback, id, currentCompany?.id);
			} else {
				return reproveChargeback(feedback, id, currentCompany?.id);
			}
		},
		{
			onSuccess: () => {
				const confirmMessage = isUserTheChargebackAuthor(
					fetchChargebackQuery.data!
				)
					? 'A solicitação foi cancelada!'
					: 'A solicitação foi reprovada!';
				openConfirmDialog(confirmMessage, 'Entendi', () => {});
				history.push('/home/chargebacks');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar reprovar o estorno. '
				);
			},
		}
	);

	function showSuccessMessage() {
		openConfirmDialog('A solicitação foi aprovada!', 'Entendi', () => {});
		updateCompanyBalance.refetch();
		history.push('/home/chargebacks');
	}

	function showReprovedRefoundBenefits(benefits: { title: string }[]) {
		const titles = benefits.map((benefit) => {
			return getBenefitParsedTitle(benefit.title);
		});
		let message =
			'Não foi possível realizar o estorno para tais benefícios: ' +
			titles.join(',');

		toast.warn(message);
	}

	const approveChargebackQuery = useMutation(
		() => {
			return approveChargeback(id, currentCompany?.id);
		},
		{
			onSuccess: (data) => {
				if (data.chargebackError.length) {
					showReprovedRefoundBenefits(data.chargebackError);
				}
				showSuccessMessage();
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar aprovar o estorno. '
				);
			},
		}
	);

	function renderOperatorActions(chargeback: Chargeback) {
		if (chargeback.status === 'pending') {
			if (user.access_level !== 'operator') {
				return (
					<S.OptionsContainer>
						<S.ReproveOption onClick={() => setFeedbackModalOpen(true)}>
							Reprovar solicitação
						</S.ReproveOption>
						<S.ApproveOption onClick={() => approveChargebackQuery.mutate()}>
							Aprovar solicitação
						</S.ApproveOption>
					</S.OptionsContainer>
				);
			} else {
				if (isUserTheChargebackAuthor(chargeback)) {
					return (
						<S.OptionsContainer>
							<S.ReproveOption onClick={() => setFeedbackModalOpen(true)}>
								Cancelar solicitação
							</S.ReproveOption>
						</S.OptionsContainer>
					);
				}
			}
		}
	}

	function isUserTheChargebackAuthor(chargeback: Chargeback) {
		return user.id === chargeback.operator_creator.id;
	}

	if (
		fetchChargebackQuery.isLoading ||
		approveChargebackQuery.isLoading ||
		reproveChargebackQuery.isLoading
	) {
		return (
			<S.Container>
				<PageTitle title='Detalhes do estorno' />
				<Loader />
			</S.Container>
		);
	}
	if (!fetchChargebackQuery.data) {
		return (
			<S.Container>
				<PageTitle title='Detalhes do estorno' />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<S.Header>
				<PageTitle title='Detalhamento' />
				{getStatusIcon(fetchChargebackQuery.data.status)}
				{parseStatusString(fetchChargebackQuery.data.status)}
			</S.Header>

			<S.InfoTop>
				<S.InfoContainer>
					<S.InfoTitle>Colaborador</S.InfoTitle>
					<UserCard
						name={`${fetchChargebackQuery.data.collaborator.first_name} ${fetchChargebackQuery.data.collaborator.last_name}`}
						bottomInfo={fetchChargebackQuery.data.collaborator.office}
						avatar_url={fetchChargebackQuery.data.collaborator.avatar_url}
					/>
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>Valor base</S.InfoTitle>
					{convertCentsToReais(Number(fetchChargebackQuery.data.value))}
				</S.InfoContainer>
				{fetchChargebackQuery.data.reversed_value !== null && (
					<S.InfoContainer>
						<S.InfoTitle>Valor estornado</S.InfoTitle>

						{convertCentsToReais(
							Number(fetchChargebackQuery.data.reversed_value)
						)}
					</S.InfoContainer>
				)}
			</S.InfoTop>

			<S.InfoContainer>
				<S.InfoTitle>Benefícios</S.InfoTitle>
				{fetchChargebackQuery.data.benefit ? (
					<S.BenefitTitles>
						{getBenefitParsedTitle(fetchChargebackQuery.data.benefit.title)}
					</S.BenefitTitles>
				) : (
					fetchChargebackQuery.data.benefits.map((benefit) => {
						return (
							<S.BenefitTitles>
								{getBenefitParsedTitle(benefit.title)}
							</S.BenefitTitles>
						);
					})
				)}
			</S.InfoContainer>

			<S.InfoContainer>
				<S.InfoTitle>Operador</S.InfoTitle>
				<UserCard
					name={`${fetchChargebackQuery.data.operator_creator.name}`}
					bottomInfo={fetchChargebackQuery.data.operator_creator.office}
					avatar_url={fetchChargebackQuery.data.operator_creator.avatar_url}
				/>
			</S.InfoContainer>
			{fetchChargebackQuery.data.operator_editor && (
				<S.InfoContainer>
					<S.InfoTitle>Operador Revisor</S.InfoTitle>
					<UserCard
						name={`${fetchChargebackQuery.data.operator_editor.name}`}
						bottomInfo={fetchChargebackQuery.data.operator_editor.office}
						avatar_url={fetchChargebackQuery.data.operator_editor.avatar_url}
					/>
				</S.InfoContainer>
			)}

			<S.InfoContainer>
				<S.InfoTitle>Justificativa</S.InfoTitle>
				{fetchChargebackQuery.data.justification}
			</S.InfoContainer>

			<S.InfoContainer>
				<S.InfoTitle>Data</S.InfoTitle>
				{parseDate(fetchChargebackQuery.data.created_at, 2)}
			</S.InfoContainer>

			{fetchChargebackQuery.data.status === 'canceled' && (
				<S.InfoContainer>
					<S.InfoTitle>Motivo da reprovação</S.InfoTitle>
					{fetchChargebackQuery.data.reason_disapproval}
				</S.InfoContainer>
			)}

			{renderOperatorActions(fetchChargebackQuery.data)}

			<FeedbackModal
				description={
					isUserTheChargebackAuthor(fetchChargebackQuery.data)
						? 'Motivo do cancelamento'
						: 'Motivo da reprovação'
				}
				isFeedbackModalOpen={feedbackModalOpen}
				handleCloseFeedbackModal={() => setFeedbackModalOpen(false)}
				submit={(feedback) => reproveChargebackQuery.mutate(feedback)}
			/>
		</S.Container>
	);
}
