import React, { ReactNode, useState, useEffect } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import ReactModal from 'react-modal';
import * as S from './styles';
import { OTHER_MODALS_CONTAINER_ID } from '../../../contexts/DialogModalContext';

export interface ModalProps {
	children: ReactNode;
	isOpen: boolean;
	showClose?: boolean;
	closeBtnStyles?: React.CSSProperties;
	animateOnClose?: boolean;

	onRequestClose(
		event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
	): void;

	parentId?: string;
	position?: S.ModalPosition;
	headerTitle?: string | ReactNode;
	titleTheme?: string;
	runAfterClosingAnimation?(): void;
}

const Modal = ({
	isOpen,
	onRequestClose,
	showClose = true,
	closeBtnStyles,
	children,
	parentId: parent,
	position = 'center',
	headerTitle,
	titleTheme,
	animateOnClose = false,
	runAfterClosingAnimation,
}: ModalProps) => {
	const [closing, setClosing] = useState(false);

	useEffect(() => {
		handleCloseAnimation();
	}, [isOpen, animateOnClose]);

	async function handleCloseAnimation() {
		if (!isOpen && animateOnClose) {
			setClosing(true);
			await new Promise((resolve) =>
				setTimeout(() => {
					setClosing(false);
					resolve('Closed');
				}, 300)
			).then(() => {
				if (runAfterClosingAnimation) {
					runAfterClosingAnimation();
				}
			});
		}
	}

	// Change default Modal styles
	ReactModal.defaultStyles = {
		...ReactModal.defaultStyles,
		overlay: {
			zIndex: 1001,
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgba(122, 122, 122, 0.685)',
		},
	};

	return (
		<S.Modal
			isOpen={isOpen || closing}
			onRequestClose={onRequestClose}
			shouldCloseOnEsc={false}
			shouldCloseOnOverlayClick={true}
			ariaHideApp={false}
			parentSelector={() =>
				document.getElementById(parent ?? OTHER_MODALS_CONTAINER_ID) ??
				document.body
			}
			position={position}
			closing={closing}
		>
			<S.ModalTitle className={titleTheme}>
				<p>{headerTitle}</p>
				{showClose && (
					<S.CloseModalBtn
						onClick={onRequestClose}
						data-testid='close-modal'
						className={titleTheme}
						style={closeBtnStyles}
					>
						<RiCloseLine size={30} />
					</S.CloseModalBtn>
				)}
			</S.ModalTitle>
			{children}
		</S.Modal>
	);
};

export default Modal;
