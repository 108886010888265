import { useEffect, useState } from 'react';
import { RiAddCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import { useQuery } from 'react-query';
import { Collaborator } from '../../../../../@types';
import { EmptyContent } from '../../../../../components/EmptyContent';
import Loader from '../../../../../components/Loader';
import Modal from '../../../../../components/Modal';
import { useAuth } from '../../../../../hooks/useAuth';
import { useDialogModal } from '../../../../../hooks/useDialogModal';
import { getBenefitsWithValue } from '../../../../../services/queries/Benefits';
import { getBenefitIcon } from '../../../../../utils/benefits/getBenefitIcon';
import { getBenefitParsedTitle } from '../../../../../utils/benefits/getBenefitParsedTitle';
import { convertCentsToReais } from '../../../../../utils/CurrencyConvert';
import * as S from './styles';

export interface BenefitSelectorProps {
	alreadyAddedBenefits: BenefitWithValue[]; // already added Benefits if selected
	onSelectBenefits(benefits: BenefitWithValue[]): void;
	selectedCollab: Collaborator | null;
}

export interface BenefitWithValue {
	benefit_balance: number;
	benefit_id: string;
	benefit_id_baas: string;
	benefit_label: string;
}

export const benefitRepresentingAllID = 'allBenefitsID';
const benefitRepresentingAll = {
	benefit_balance: 0,
	benefit_id: benefitRepresentingAllID,
	benefit_id_baas: '123',
	benefit_label: 'TODOS OS BENEFICIOS',
};

export function BenefitSelectorToChargeback({
	alreadyAddedBenefits,
	onSelectBenefits,
	selectedCollab,
}: BenefitSelectorProps) {
	const { currentCompany } = useAuth();
	const [open, setOpen] = useState(false);
	const [fetchedBenefits, setFetchedBenefits] = useState<BenefitWithValue[]>(
		[]
	);
	const [selectedBenefits, setSelectedBenefits] = useState<BenefitWithValue[]>(
		[]
	);
	const [hasMadeChanges, setHasMadeChanges] = useState(false);
	const { openOptionsDialog } = useDialogModal();

	const fetchBenefitsQuery = useQuery<BenefitWithValue[], Error>(
		['benefits', currentCompany?.id, selectedCollab],
		() => {
			return getBenefitsWithValue(selectedCollab?.id!, currentCompany?.id);
		},
		{
			onSuccess: (data) => {
				setFetchedBenefits(data);
			},

			enabled: !!currentCompany && !!selectedCollab,
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		if (open) {
			setSelectedBenefits(alreadyAddedBenefits);
			setHasMadeChanges(false);
		}
	}, [open, alreadyAddedBenefits]);

	function handleSubmit() {
		onSelectBenefits(selectedBenefits);
		setOpen(false);
	}

	function handleClose() {
		if (!hasMadeChanges) {
			setOpen(false);
			return;
		}

		openOptionsDialog(
			'Alterações não salvas. Tem certeza que deseja sair?',
			'Confirmar',
			() => setOpen(false),
			'Cancelar',
			() => {}
		);
	}

	function handleSelectBenefit(benefit: BenefitWithValue) {
		setHasMadeChanges(true);
		setSelectedBenefits([...selectedBenefits, benefit]);
	}

	function selectAllBenefitsBenefit(benefit: BenefitWithValue) {
		setHasMadeChanges(true);
		setSelectedBenefits([benefit]);
	}

	function handleDeselectBenefit(benefit: BenefitWithValue) {
		if (
			isAllBenefitsSelected() &&
			benefit.benefit_id !== benefitRepresentingAllID
		) {
			setHasMadeChanges(true);
			setSelectedBenefits(
				fetchedBenefits.filter(
					(b) => b.benefit_id !== benefit.benefit_id && b.benefit_balance > 0
				)
			);
			return;
		}

		if (selectedBenefits.length >= 1) {
			setHasMadeChanges(true);
			setSelectedBenefits(
				selectedBenefits.filter((b) => b.benefit_id !== benefit.benefit_id)
			);
		}
	}

	function checkSelectedBenefit(benefit: BenefitWithValue) {
		if (isAllBenefitsSelected() && benefit.benefit_balance > 0) return true;

		return !!selectedBenefits.find((b) => b.benefit_id === benefit.benefit_id);
	}

	function isAllBenefitsSelected() {
		return !!selectedBenefits.find(
			(benefit) => benefit.benefit_id === benefitRepresentingAllID
		);
	}

	function renderSelectedBenefitTitleInButton() {
		const isAllBenefitsSelected =
			alreadyAddedBenefits[0].benefit_id === 'allBenefitsID';

		const benefitsToGetBalance = isAllBenefitsSelected
			? fetchBenefitsQuery.data!
			: alreadyAddedBenefits;

		const totalValue = getBenefitsTotalBalance(benefitsToGetBalance);

		const labelText = isAllBenefitsSelected
			? `${alreadyAddedBenefits[0].benefit_label} - ${convertCentsToReais(
					totalValue
			  )}`
			: `${
					alreadyAddedBenefits.length
			  } benefícios selecionados - ${convertCentsToReais(totalValue)}`;

		return labelText;
	}

	function getBenefitsTotalBalance(benefits: BenefitWithValue[]) {
		if (!benefits) {
			return 0;
		}
		return benefits.reduce(
			(accumulator, currentBenefit) =>
				accumulator + currentBenefit.benefit_balance,
			0
		);
	}

	if (
		fetchBenefitsQuery.isLoading ||
		fetchBenefitsQuery.isError ||
		fetchBenefitsQuery.isPreviousData ||
		!fetchedBenefits ||
		fetchBenefitsQuery.isRefetching
	) {
		return (
			<div>
				<S.SelectCollabButtonWithSelection
					onClick={() => {
						setOpen(true);
					}}
					type='button'
					disabled={!selectedCollab}
				>
					{alreadyAddedBenefits.length
						? renderSelectedBenefitTitleInButton()
						: 'Selecionar benefícios'}
				</S.SelectCollabButtonWithSelection>

				<Modal isOpen={open} enableClose onRequestClose={handleClose}>
					<S.Container data-testid='benefitSelectorToChargeback-modal-container'>
						<Loader />
						{fetchBenefitsQuery.isError && (
							<S.ErrorLine>
								Ocorreu um erro ao buscar os benefícios. Tente novamente.
							</S.ErrorLine>
						)}
					</S.Container>
				</Modal>
			</div>
		);
	}

	return (
		<div>
			<S.SelectCollabButtonWithSelection
				onClick={() => {
					setOpen(true);
				}}
				type='button'
				disabled={!selectedCollab}
			>
				{alreadyAddedBenefits.length
					? renderSelectedBenefitTitleInButton()
					: 'Selecionar benefícios'}
			</S.SelectCollabButtonWithSelection>

			<Modal isOpen={open} enableClose onRequestClose={handleClose}>
				<S.Container data-testid='SingleBenefitSelector-modal-container'>
					<S.BenefitsList>
						<S.Benefit key={benefitRepresentingAll.benefit_id}>
							<S.BenefitTitle>
								{benefitRepresentingAll.benefit_label}
							</S.BenefitTitle>
							<S.BenefitValue></S.BenefitValue>
							{!checkSelectedBenefit(benefitRepresentingAll) ? (
								<S.Selector
									selected={checkSelectedBenefit(benefitRepresentingAll)}
									onClick={() =>
										selectAllBenefitsBenefit(benefitRepresentingAll)
									}
									disabled={getBenefitsTotalBalance(fetchedBenefits) === 0}
									data-testid={`${benefitRepresentingAll.benefit_id}-select`}
								>
									<RiAddCircleLine />
								</S.Selector>
							) : (
								<S.Selector
									selected={checkSelectedBenefit(benefitRepresentingAll)}
									onClick={() => handleDeselectBenefit(benefitRepresentingAll)}
									data-testid={`${benefitRepresentingAll.benefit_id}-unselect`}
								>
									<RiCloseCircleLine />
								</S.Selector>
							)}
						</S.Benefit>

						{fetchedBenefits.map((benefit) => (
							<S.Benefit key={benefit.benefit_id}>
								<S.BenefitTitle>
									{getBenefitIcon(benefit.benefit_label)}
									{getBenefitParsedTitle(benefit.benefit_label)}
								</S.BenefitTitle>
								<S.BenefitValue>
									Saldo: {convertCentsToReais(benefit.benefit_balance)}
								</S.BenefitValue>
								{!checkSelectedBenefit(benefit) ? (
									<S.Selector
										selected={checkSelectedBenefit(benefit)}
										onClick={() => handleSelectBenefit(benefit)}
										data-testid={`${benefit.benefit_id}-select`}
										disabled={benefit.benefit_balance === 0}
										title={
											benefit.benefit_balance === 0
												? 'O colaborador não possui saldo neste benefício.'
												: ''
										}
									>
										<RiAddCircleLine />
									</S.Selector>
								) : (
									<S.Selector
										selected={checkSelectedBenefit(benefit)}
										onClick={() => handleDeselectBenefit(benefit)}
										data-testid={`${benefit.benefit_id}-unselect`}
									>
										<RiCloseCircleLine />
									</S.Selector>
								)}
							</S.Benefit>
						))}
						{!fetchedBenefits.length && (
							<EmptyContent text='Nenhum benefício encontrado' />
						)}
					</S.BenefitsList>

					<S.MainButton onClick={handleSubmit} style={{ width: '16rem' }}>
						Salvar
					</S.MainButton>
				</S.Container>
			</Modal>
		</div>
	);
}
