//INVALID_REQUEST_TYPE: 1
const errorMessagesInvalidRequest = [
	//Benefit(100) messages
	[
		'Benefício não encontrado!', //`Benefit not found!`
		'O nome do benefício já está em uso!', //'Benefit name is already in use!'
		'Benefícios não encontrados!', //'Benefits not found'
		'O benefício // está inativo, cheque os grupos!', //`The benefit // is inactive, check groups!`
		'O benefício // está inativo!', //`The benefit // is inactive!`
		'Benefício não encontrado, cheque os grupos!', // 'Benefit not found, check groups!'
	],

	//Company(200) messages
	[
		'Empresa não encontrada!', //'Company not found!'
		'CNPJ inválido!', //'CNPJ invalid!'
		'CNPJ da empresa já está em uso!', //'Company CNPJ already in use!'
		'Nome corporativo já está em uso!', //'Corporate name already in use'
		'O nome da empresa já está em uso!', //'Company name already in use'
		'Email da empresa já está em uso!', //'Company email already in use'
		'Somente usuários autenticados podem mudar a imagem de avatar!', //'Only authenticated users can change company avatar.'
		'Empresa com KYC status irregular!', //'Company with irregular KYC status!'
		'A empresa não tem saldo para este estorno.', // 'The company has no balance for this chargeback.'
		'Email de setor financeiro não encontrado!', //'Company financial sector email not found!'
	],

	//Group(300) messages
	[
		'O nome do grupo já está em uso!', //'Group name is already in use!'
		'O grupo está inativo!', //'The group is not active!'
		'Grupo // está inativo!', //`Group // is inactive!`
		'Grupos não foram achados!', //'Groups not found!'
		'Grupo não achado!', //'Group not found!'
		'Usuário não achado, cheque os grupos!', //'User not found, check groups!'
		'Este grupo já possui o número máximo de 1000 colaboradores adicionados.', // 'It is not possible to add new collaborators. The maximum number of collaborators per group is 1000'
	],

	//Pin(400) messages
	[
		'O pin do usuário já foi registrado, tente atualiza-lo!', //"The user's pin has already been registered, try to update the pin."
		'O pin não é um número!', //'Pin is not a number!'
		'O tamanho do pin não pode ser diferente de 4 dígitos!', //'Pin size cannot be different than 4 digits!'
		'Pin do usuário não achado!', //'User pin not found!'
		'Pin incorreto!', //'Pin incorrect!'
		'É necessário informar o pin antigo para registrar um novo!', //'You need to inform the old pin to set a new pin.'
		'Pin antigo incorreto!', //'Old pin does not match.'
	],

	//Refunds(500) messages
	[
		'Reembolsos não encontrados!', //'Refunds not found!'
		'Reembolso não encontrado!', //'Refund not found!'
		'Somente reembolsos com status requested podem ser analisados!', //'Error trying to parse the refund, only refund with requested status can be parsed.'
		'O reembolso não pode ter o valor mudado e o comentário de desaprovação!', //'The refund posting cannot have the value changed and the disapproval comment!'
		'Benefício de reembolso não encontrado!', // 'Error trying to parse the refund, refund wallet not found.'
	],

	//Release(600) messages
	[
		'Os parametros são necessários!', //'Parameters is required!'
		'O colaborador // está duplicado no lançamento! Cheque a listagem e os grupos.', //`The collaborator // is duplicated at launch! check groups and listing.`
		'Lançamentos não encontrados!', //'Releases not found!'
		'Somente lançamentos com status requested podem ser analisados!', //'Error trying to parse the release, only releases with requested status can be parsed.'
		'O lançamento não pode ter o valor modificado e um comentário de desaprovação!', //'The release posting cannot have the value changed and the disapproval comment!'
		'PreRelease dos parâmetros não encontrado!', //'PreRelease from parameters not found!'
		'GroupRelease dos parâmetros não encontrado!', //'GroupRelease from parameters not found!'
		'Somente lançamentos com status de reprovação podem ser relançados!', //'Error trying to parse the release, only release with disapproved status can be relaunched.'
		'Impossível processar essa entrada, cheque as informações passadas!', //'Unable to make this entry, check the data!'
		'O colaborador // do grupo ## está inativo, remova ele do grupo e tente novamente!', //`The collaborator // of the ## group is inactive, remove him from the group in the group options.`
		'Lançamento não encontrado!', //'Release not found!'
		'Sem colaboradores ativos no pagamento!', //'No active collaborators in the payment!'
		'Erro ao tentar cancelar o lançamento, apenas lançamentos que foram solicitados ou agendados podem ser cancelados.', // 'Error trying to cancel launch, only launches that have been requested or scheduled can be cancelled.'
		'Erro ao tentar atualizar a data de agendamento, apenas lançamentos com status solicitado ou agendado podem ser atualizadas.', // 'Error when trying to update the schedule date, only versions with requested or scheduled status can be updated.'
		'Erro ao tentar atualizar o lançamento, apenas os lançamento que estão aguardando revisão ou agendados podem ser atualizados.', // 'Error when trying to update the release, only the releases that are awaiting review or that are scheduled can be updated.'
		'O número total de pagamentos não pode ser maior que 1000(mil)!', //'Total payments cannot be greater than 1000 payments!'
		'Somente pagamentos aprovados ou com erro no estorno podem ser estornados!',
		'Pagamento com apenas colaboradores inativos ou sem acesso aos benefícios!',
	],

	//Shareholder(700) messages
	[
		'Sócio não encontrado!', // 'Shareholder not found!'
		'Email do sócio já existe na empresa!', // 'Shareholders email already exists in the company!'
		'CPF do sócio já existe na empresa!', // 'Shareholders CPF already exists in the company!'
		'Impossível deletar o sócio. É necessário ter pelo menos um sócio associado com a empresa.', //'Not possible to delete the shareholder. It is necessary to have at least one shareholder associated with the company.'
		'Erro ao recuperar informações do sócio!', //'Error retrieving shareholders!'
	],

	//Collaborator(800) messages
	[
		'Os termos para o usuário já foram aprovados!', // 'Terms for the user have already been approved!'
		'Colaborador não encontrado!', // 'User collaborator not found!'
		'Email ou senha incorretos!', // 'Email or password incorrect!'
		'RG e CRNM não podem ser registrados juntos, registre apenas um!', // 'RG and CRNM cannot be registered together, register only one!'
		'Campo de RG ou CRNM deve ser informado!', // 'The RG field or CRNM field must be informed!'
		'Colaborador já existe!', // 'User Collaborator already exists!'
		'CPF inválido!', // 'CPF invalid!'
		'Email do colaborador não existe!',
		'Somente usuário autenticados podem mudar a imagem de avatar!', // 'Only authenticated users can change avatar.'
		'É necessário informar a senha antiga para registrar uma nova!', // 'You need to inform the old password to set a new password.'
		'Senha antiga incorreta!', // 'Old password does not match.'
		'Email do colaborador já está em uso!', // 'Collaborator email already in use'
		'Todos ou alguns colaboradores não foram encontrados!', //'All or some collaborator users not found!'
		'Colaborador não aprovado na análise de compliance!', // 'User collaborator not approved in compliance analysis!'
		'Colaborador já possui um cartão físico!', // 'Collaborator already has a physical card'
		'Colaboradores não encontrados!', // 'UserS collaborator not found!'
		'O colaborador não possui permissão!', // 'User does not have permission!'
		'Colaborador desabilitado.', // Disabled user
		'CPF do colaborador já está em uso!', // 'Collaborator cpf already in use'
		'RG do colaborador já está em uso!', // 'Collaborator rg already in use'
		'CRNM do colaborador já está em uso!', // 'Collaborator crnm already in use'
		'O colaborador não pode ser deletado porque há pagamentos pendentes. Cancele os pagamentos e tente novamente.', // 'The contributor cannot be deleted as he has outstanding payments for him. Cancel the payment and try again later'
		'O colaborador não pode ser deletado porque há reembolsos pendentes. Cancele os reembolsos e tente novamente.', // 'The contributor cannot be deleted as he has outstanding refunds for him. Cancel the refund and try again later'
		'O colaborador não pode ser deletado porque há estornos pendentes. Cancele os estornos e tente novamente.', // 'The contributor cannot be deleted as he has outstanding chargebacks for him. Cancel the chargeback and try again later'
		'O colaborador não pode ser excluído, porque existe solicitação de cartão pendente ou em processamento.', //''The contributor cannot be deleted as he has pending or processing card_batch_items for him. Cancel the item and try again later.
		'O colaborador não é de menor, então não precisa de documento de comprovação!', // The collaborator is not a minor, so he, does not need the proof document!
		'O colaborador é de menor, então precisa do documento assinado pelos responsáveis!', // The collaborator is a minor, so he, need the proof document!
		'O colaborador de CPF // é de menor, portanto, não é possível registrá-lo por importação. Registre-o no cadastro manual.', // The collaborator with CPF /${cpfFormatted}/ is a minor, so it is not possible to register him in the spreadsheet. Register it through manual registration.
		'Documento não encontrado!', // Document not found!
		'O documento já foi analizado e aprovado!', // The document has already been analyzed and approved!
		'O documento assinado pelos responsáveis que foi submetido ainda não foi analizado ou foi reprovado. Por favor, tente novamente mais tarde.', // Underage contributor, the submitted document has not yet been analyzed or has been disapproved. Please try again later.
		'O colaborador já foi registrado como de menor e seu documento já foi associado. Tornando impossível mudar sua data de nascimento para maior de idade.', // The collaborator has already been registered as a minor and his/her document has been associated, making it impossible to change his/her date of birth to an older age.
		'O colaborador não pode ser excluido enquanto houver saldo em sua conta.', // The collaborator cannot be excluded, there is an active benefit balance.
		'O colaborador não possue um cartão linkado.', // 'Collaborator does not have a linked card'
		'O colaborador já fez login no aplicativo. A forma de recuperação da senha deve ser feita pelo aplicativo!', //The collaborator has already logged in to the application. The way to recover the password must be done by the application!
		'O colaborador não é de menor, portanto não precisa de responsável!', // The collaborator is not a minor, so he, does not need a responsible!
		'O colaborador ainda não fez o primeiro acesso.', // 'Collaborator has not completed the first login yet'
		'O colaborador já possui uma solicitação de exclusão pendente.', // User collaborator already has a deletion request!
	],

	//Master(900) messages
	[
		'Email ou senha incorretos!', // 'Email or password incorrect!'
		'Usuário master já existe!', // 'User master already exists!'
		'Fundos insuficientes!', // 'Insufficient funds'
		'O usuário não possui permissão!', // 'User does not have permission!'
		'Usuário master não encontrado!', // 'User master not found!'
	],

	//Operator(1000) messages
	[
		'Usuário operador já existe!', // 'User operator already exists!'
		'Email ou senha incorretos!', // 'Email or password incorrect!'
		'Usuário admin já existe!', // 'This permission is not allowed, admin already exist!'
		'CPF inválido!', // 'CPF invalid!'
		'Operador não encontrado!', // 'User operator not found!'
		'Email do operador não existe!', // 'Operator email does not exist!'
		'Usuário não possui permissão para performar essa operação!', // 'The user is not allowed to perform this operation!'
		'Somente usuários autenticados podem mudar a imagem de avatar!', // 'Only authenticated users can change avatar.'
		'Operação não permitida!', // 'This permission is not allowed!'
		'Email de operador já está em uso!', // 'Operator email already in use.'
		'É preciso informar a senha antiga para registrar uma nova!', // 'You need to inform the old password to set a new password.'
		'Senha antiga incorreta!', // 'Old password does not match.'
		'Usuários operadores não encontrados!', // 'Users operator not found!'
		'Usuário não possui permissão!', // 'User does not have permission!'
		'CPF do operador já está em uso!', // 'Operator cpf already in use.'
		'RG do operador já está em uso!', // 'Operator rg already in use.'
		'Usuário desativado!', // 'Disabled user'
		'Essa conta ainda não fez o primeiro acesso. Verifique a caixa de e-mail e utilize a senha disponibilizada.', // 'Operator has not completed the first login yet'
	],

	//Transaction(1100) messages
	[
		'O mês deve ser entre 1 e 12!', // 'The month in the query has to be defined between 1 and 12!'
	],

	//Token(1200) messages
	[
		'Refresh token inválido!', // 'Refresh Token invalid!'
		'Refresh token expirado!', // 'Refresh Token expired!'
		'Token ausente!', // 'Token missing'
		'Token inválido', // 'Token invalid'
		'O link de redefinição de senha expirou. Solicite um novo link para continuar.', // 'Reset password token is expired!'
	],

	//Card(1300) messages
	[
		'Cartão não encontrado!', // 'Card not found!'
	],

	//Question(1400) messages
	[
		'Questão não existe!', // 'Question does not exist!'
	],

	//Feedback(1500) messages
	[
		'Feedback não encontrado!', // 'Feedback not found!'
	],

	//Terms(1600) messages
	[
		'Os termos para este usuário já foram aprovados!', // 'Terms for the user have already been approved!'
	],

	//KYC(1700) messages
	[
		'Payload inválido!', // "Invalid payload"
		'Webhook payload inválido!', // 'Webhook payload not valid'
	],

	//Cost center(1800) messages
	[
		'Nome do centro de custo já existe!', // 'Cost center name is already in use!'
		'Centros de custo não foram encontrados!', // 'Cost centers not found!'
		'Centro de custo não encontrado!', // 'Cost center not found!'
		'Usuário não encontrado, cheque o centro de custo!', // 'User not found, check cost center!'
		'Centro de custo não pode ser deletado pois ainda há colaboradores associados!', // 'Cost center cannot be deleted as there are still collaborators associated with it!'
	],

	//Corporate group(1900) messages
	[
		'Grupo corporativo não encontrado!', // 'Corporate Group not found!'
		'Nome do grupo corporativo já existe!', // 'Corporate Group name already in use'
	],

	//Chargeback(2000) messages
	[
		'Estorno não encontrado!', // 'Chargeback not found!'
		'Somente estornos com status pending podem ser analisados', // 'Error trying to parse the chargeback, only chargeback with requested status can be parsed.'
		'Estornos não encontrados!', // 'Chargebacks not found!'
		'O colaborador não possui saldo para esse benefício!', // 'The collaborator has no balance for this benefit.'
	],

	//Notification(2100) messages
	[
		'Notificação não encontrada!', // 'Notification not found!'
	],

	//Date(2200) messages
	[
		'Data inválida!', // 'Date invalid!'
		'O novo horário de agendamento precisa ter ao menos 30 minutos de difereça para o horário atual.', // 'the new date of the schedule cannot be so close to the current date, it is necessary to have at least 30 minutes of interval.'
	],

	//Message(2300)
	[
		'Mensagens não encontradas!', // 'Messages not found!'
		'Mensagem não encontrada!', // 'Message not found!'
	],

	//CardBatch(2400)
	[
		'Remessa de cartões não encontrada!', // 'Card batch not found!'
		'Item de remssa de cartões não encontrado!', // 'Card batch item not found!'
		'Remessa de cartões deve ter o tipo de entrega para um único endereço.', //Card batch must have delivery type to single address
		'Erro na criação de remessa de cartão!', // 'Error on create card batch!'
		'Status da remessa de cartão já foi definido!', // 'Batch status already defined!'
		'Status da remessa não permite a exclusão.',
		'Não é possivel excluir o item quando a remessa possui apenas 1 item de entrega.',
		'A operação não é possível para esse item.',
	],
];

//UNAUTHENTICATED_TYPE: 2
const errorMessagesUnauthenticated = ['Ocorreu um problema de autenticação!'];

//ACCESS_DENIED_TYPE: 3
const errorMessagesAccess = ['Acesso negado!'];

//EXTERNAL_SERVICE_TYPE: 4
const errorMessagesExternalService = [
	'Um serviço externo não performou como o esperado!', // 'An external service did not behave as expected.'
	'Erro de cache!', // 'Cache error'
	'Erro no Swap: autenticação falhou!', // `SWAP error: authenticate failed!`
	'Erro genérico do Swap', // 'Generic Swap Error.'
	'Erro no Swap: Erro não identificado, cheque os dados!', // 'SWAP error: unidentified error, check the data!'
	'Erro no Swap: ', // 'SWAP error: ${message}'
	'Erro no Swap: Fundos insuficientes!', // `SWAP error: insufficient funds.`
];

//NOT_FOUND_TYPE: 5
const errorMessagesNotFound = ['Problema ao processar busca de dados...'];

//CELEBRATE_ERROR_TYPE: 6
const errorMessagesCelebrate = [
	'label é obrigatório!', //'"\"label\" is required!(1)'
	'label não é no formato de texto!', //'"\"label\" is not a string!(2)'
	'label não pode ser vazio!', //'"\"label\" cannot be empty!(3)'
	'label está com dados inválidos!', //'"\"label\" is not valid!(4)'
	'label não está no formato correto!', //'"\"label\" does not match the correct format!(5)'
];

//errorName: error.name,
//errorTypeId: error.errorTypeID,

//errorEntityName: error.errorEntityName,
//errorEntityId: error.errorEntityID,

//message: error.message,
//messageId: error.messageID
export function getMessageError(data: any) {
	if (data.errorTypeId === 1) {
		try {
			let messageTranslated =
				errorMessagesInvalidRequest[data.errorEntityId! / 100 - 1][
					data.messageId! - 1
				];

			if (messageTranslated.includes('/')) {
				// eslint-disable-next-line
				let nameDynamic = data.message.match('/.+/');
				messageTranslated = messageTranslated.replace('//', nameDynamic);
			}

			if (messageTranslated.includes('#')) {
				// eslint-disable-next-line
				let nameDynamic = data.message.match('#.+#');
				messageTranslated = messageTranslated.replace('##', nameDynamic);
			}

			return messageTranslated;
		} catch (err) {
			return data.message;
		}
	} else if (data.errorTypeId === 2) {
		const message = errorMessagesUnauthenticated[0];

		return message;
	} else if (data.errorTypeId === 3) {
		const message = errorMessagesAccess[0];

		return message;
	} else if (data.errorTypeId === 4) {
		const message = errorMessagesExternalService[data.messageId! - 1];

		return message;
	} else if (data.errorTypeId === 5) {
		const message = errorMessagesNotFound[0];

		return message;
	} else if (data.errorTypeId === 6) {
		try {
			const blankSpace = data.message.search(' ');
			if (blankSpace > -1) {
				const label = data.message.substring(0, blankSpace);
				const messageId = data.message.substring(
					data.message.length - 2,
					data.message.length - 1
				);
				const message = errorMessagesCelebrate[Number(messageId) - 1];

				return message.replace('label', label);
			}
		} catch (err) {
			return data.message;
		}
	} else if (data.errorTypeId === -1) {
		return 'Não foi possível conectar ao servidor.';
	} else {
		return 'Tipo de erro desconhecido!';
	}
}
