import { useState } from 'react';
import { BsFileEarmarkText } from 'react-icons/bs';
import { MdNotes } from 'react-icons/md';
import { Chargeback } from '../../../@types';
import { EmptyContent } from '../../../components/EmptyContent';
import PageTitle from '../../../components/PageTitle';
import * as TableStyle from '../../../components/Table/TableStyles';
import UserCard from '../../../components/UserCard';
import { useAuth } from '../../../hooks/useAuth';
import { parseDate } from '../../../utils/parseDate';
import { getStatusIcon, parseStatusString } from '../../../utils/parseStatus';
import Loader from '../../../components/Loader';
import * as S from './styles';
import { Pagination } from '../../../components/Pagination';
import { Filter, FilterParams } from '../../../components/Filter';
import { useQuery, useQueryClient } from 'react-query';
import {
	ChargebacksFilterReturn,
	ChargebacksListReturn,
	getFilteredChargebacks,
	getNonPendingChargebacks,
	getPendingChargebacks,
} from '../../../services/queries/Chargebacks';
import { convertCentsToReais } from '../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { useChargebacksListStore } from '../../../stores/useChargebacksListStore';
import { useHistoryNonMatchCallback } from '../../../hooks/useHistoryNonMatchCallback';
import { useSortColumnHook } from '../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../components/SortColumnButton';

export function Chargebacks() {
	const { user, currentCompany } = useAuth();
	const queryClient = useQueryClient();
	const [pendingChargebacksCurrentPage, setPendingChargebacksCurrentPage] =
		useState(1);
	const [
		nonPendingChargebacksCurrentPage,
		setNonPendingChargebacksCurrentPage,
	] = useState(1);
	const {
		currentSortColumn: currentSortColumnPending,
		toggleSort: toggleSortPending,
	} = useSortColumnHook();
	const {
		currentSortColumn: currentSortColumnNonPending,
		toggleSort: toggleSortNonPending,
	} = useSortColumnHook();
	const [showingTable, setShowingTable] = useState<string>();
	const [
		pendingChargebacksFiltersParams,
		setPendingChargebacksFiltersParams,
		nonPendingChargebacksFiltersParams,
		setNonPendingChargebacksFiltersParams,
		resetFilters,
	] = useChargebacksListStore((state) => [
		state.pendingChargebacksFiltersParams,
		state.setPendingChargebacksFiltersParams,
		state.nonPendingChargebacksFiltersParams,
		state.setNonPendingChargebacksFiltersParams,
		state.resetFilters,
	]);
	useHistoryNonMatchCallback('chargebacks', resetFilters);

	const pendingChargebacksQuery = useQuery<ChargebacksListReturn, Error>(
		[
			'pendingChargebacksList',
			currentCompany?.id,
			pendingChargebacksCurrentPage,
			currentSortColumnPending,
		],
		() => {
			return getPendingChargebacks(
				pendingChargebacksCurrentPage,
				currentCompany?.id,
				currentSortColumnPending
			);
		},
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar os estornos. ');
			},
			keepPreviousData: true,
		}
	);

	const nonPendingChargebacksQuery = useQuery<ChargebacksListReturn, Error>(
		[
			'nonPendingChargebacksList',
			currentCompany?.id,
			nonPendingChargebacksCurrentPage,
			currentSortColumnNonPending,
		],
		() => {
			return getNonPendingChargebacks(
				nonPendingChargebacksCurrentPage,
				currentCompany?.id,
				currentSortColumnNonPending
			);
		},
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar os estornos. ');
			},
			keepPreviousData: true,
		}
	);

	const filteredPendingChargebacksQuery = useQuery<
		ChargebacksFilterReturn,
		Error
	>(
		[
			'filteredChargebacks',
			pendingChargebacksFiltersParams,
			true,
			currentCompany?.id,
			pendingChargebacksCurrentPage,
			currentSortColumnPending,
		],
		() => {
			return getFilteredChargebacks(
				pendingChargebacksFiltersParams,
				true,
				currentCompany?.id,
				pendingChargebacksCurrentPage,
				currentSortColumnPending
			);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os estornos. '
				);
			},
			enabled:
				!!pendingChargebacksFiltersParams.find((f) => !!f.value) &&
				!!pendingChargebacksCurrentPage,
		}
	);

	const filteredNonPendingChargebacksQuery = useQuery<
		ChargebacksFilterReturn,
		Error
	>(
		[
			'filteredChargebacks',
			nonPendingChargebacksFiltersParams,
			false,
			currentCompany?.id,
			nonPendingChargebacksCurrentPage,
			currentSortColumnNonPending,
		],
		() => {
			return getFilteredChargebacks(
				nonPendingChargebacksFiltersParams,
				false,
				currentCompany?.id,
				nonPendingChargebacksCurrentPage,
				currentSortColumnNonPending
			);
		},
		{
			onSuccess: (data) => {
				console.log(data);
			},
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os estornos. '
				);
			},
			enabled:
				!!nonPendingChargebacksFiltersParams.find((f) => !!f.value) &&
				!!nonPendingChargebacksCurrentPage,
		}
	);

	function refreshPage() {
		setNonPendingChargebacksCurrentPage(1);
		setPendingChargebacksCurrentPage(1);
		queryClient.resetQueries('pendingChargebacksList');
		queryClient.resetQueries('nonPendingChargebacksList');
		queryClient.resetQueries('filteredChargebacks');
	}

	function isIndividualTableLoading(tableName: string) {
		if (tableName === 'Pendentes')
			return (
				filteredPendingChargebacksQuery.isLoading ||
				pendingChargebacksQuery.isPreviousData
			);
		else if (tableName === 'Concluídos')
			return (
				filteredNonPendingChargebacksQuery.isLoading ||
				nonPendingChargebacksQuery.isPreviousData
			);

		return false;
	}
	function getTableTotalRecords(tableName: string) {
		if (tableName === 'Pendentes') {
			if (pendingChargebacksFiltersParams.find((f) => !!f.value)) {
				return filteredPendingChargebacksQuery.data?.totalChargebacks;
			}
			return pendingChargebacksQuery.data?.totalPendingChargebacks;
		}

		if (nonPendingChargebacksFiltersParams.find((f) => !!f.value)) {
			return filteredNonPendingChargebacksQuery.data?.totalChargebacks;
		}
		return nonPendingChargebacksQuery.data?.totalNonPendingChargebacks;
	}

	function updateFilters(filters: FilterParams[], pending: boolean) {
		if (pending) {
			setPendingChargebacksCurrentPage(1);
			setPendingChargebacksFiltersParams(filters);
		} else {
			setNonPendingChargebacksCurrentPage(1);
			setNonPendingChargebacksFiltersParams(filters);
		}
	}

	function getSortColumnOrder(columnName: string, tableName: string) {
		if (tableName === 'Pendentes') {
			return currentSortColumnPending?.name === columnName
				? currentSortColumnPending.order
				: null;
		}
		return currentSortColumnNonPending?.name === columnName
			? currentSortColumnNonPending.order
			: null;
	}
	function handleToggleSortColumn(columnName: string, tableName: string) {
		if (tableName === 'Pendentes') toggleSortPending(columnName);
		else toggleSortNonPending(columnName);
	}

	function generateChargebackRow(chargeback: Chargeback) {
		return (
			<TableStyle.TableRow key={chargeback.id}>
				<TableStyle.TableData>
					<TableStyle.DocumentContainer>
						<TableStyle.IconContainer>
							<BsFileEarmarkText />
						</TableStyle.IconContainer>
						{parseDate(chargeback.created_at, 1)}
						{chargeback.operator_editor !== null ? (
							chargeback.operator_editor.id === user.id ? (
								<TableStyle.LabelEdited color={'#0AD1A8'}>
									<p>Você editou</p>
								</TableStyle.LabelEdited>
							) : (
								<TableStyle.LabelEdited>
									<p>Editado por {chargeback.operator_editor.name}</p>
								</TableStyle.LabelEdited>
							)
						) : (
							<></>
						)}
					</TableStyle.DocumentContainer>
				</TableStyle.TableData>
				<TableStyle.TableData>
					<UserCard
						name={`${chargeback.collaborator.first_name} ${chargeback.collaborator.last_name}`}
						bottomInfo={chargeback.collaborator.office}
						avatar_url={chargeback.collaborator.avatar_url}
					/>
				</TableStyle.TableData>
				<TableStyle.TableData>
					{convertCentsToReais(
						Number(
							chargeback.reversed_value
								? chargeback.reversed_value
								: chargeback.value
						)
					)}
				</TableStyle.TableData>
				<TableStyle.TableData>
					<TableStyle.StatusContainer>
						{getStatusIcon(chargeback.status)}
						<span>{parseStatusString(chargeback.status)}</span>
					</TableStyle.StatusContainer>
				</TableStyle.TableData>
				<TableStyle.TableData>
					{chargeback.status === 'pending' ? (
						<TableStyle.OptionLink
							to={`/home/chargebacks/${chargeback.id}`}
							data-rh={`${
								user.access_level === 'admin'
									? 'Revisar estorno'
									: 'Detalhamento'
							} `}
						>
							<MdNotes />
						</TableStyle.OptionLink>
					) : (
						<TableStyle.OptionLink
							data-rh='Detalhamento'
							to={`/home/chargebacks/${chargeback.id}`}
						>
							<MdNotes />
						</TableStyle.OptionLink>
					)}
				</TableStyle.TableData>
			</TableStyle.TableRow>
		);
	}

	function getTable(tableName: string, chargebacks?: Chargeback[]) {
		if (isIndividualTableLoading(tableName) || !chargebacks?.length) {
			return (
				<>
					<TableStyle.TitleWrapper>
						<PageTitle title={tableName} />
						{tableName === 'Pendentes' ? (
							<>
								<Filter
									filterParams={pendingChargebacksFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, true);
									}}
								/>
							</>
						) : (
							<>
								<Filter
									filterParams={nonPendingChargebacksFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, false);
									}}
								/>
							</>
						)}
						{tableName === 'Pendentes' && (
							<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
						)}
					</TableStyle.TitleWrapper>
					{isIndividualTableLoading(tableName) ? (
						<Loader />
					) : (
						<EmptyContent text='Não há ítens a serem exibidos.' />
					)}
				</>
			);
		}

		return (
			<>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle
							title={tableName}
							totalRecords={getTableTotalRecords(tableName) ?? 0}
						/>
						{tableName === 'Pendentes' ? (
							<>
								<Filter
									filterParams={pendingChargebacksFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, true);
									}}
								/>
							</>
						) : (
							<>
								<Filter
									filterParams={nonPendingChargebacksFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, false);
									}}
								/>
							</>
						)}
						{showingTable === tableName ? (
							<TableStyle.SeeLess
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver menos
							</TableStyle.SeeLess>
						) : (
							<TableStyle.SeeMore
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver mais
							</TableStyle.SeeMore>
						)}
					</TableStyle.TitleWrapper>

					{showingTable === tableName ? (
						tableName === 'Pendentes' ? (
							pendingChargebacksFiltersParams.find((f) => !!f.value) ? (
								<Pagination
									onPageChange={(page) =>
										setPendingChargebacksCurrentPage(page)
									}
									currentPage={pendingChargebacksCurrentPage}
									totalCount={
										filteredPendingChargebacksQuery.data
											? filteredPendingChargebacksQuery.data.totalChargebacks!
											: 1
									}
								/>
							) : (
								<Pagination
									onPageChange={(page) =>
										setPendingChargebacksCurrentPage(page)
									}
									currentPage={pendingChargebacksCurrentPage}
									totalCount={
										pendingChargebacksQuery.data
											? pendingChargebacksQuery.data.totalPendingChargebacks!
											: 1
									}
								/>
							)
						) : nonPendingChargebacksFiltersParams.find((f) => !!f.value) ? (
							<Pagination
								onPageChange={(page) =>
									setNonPendingChargebacksCurrentPage(page)
								}
								currentPage={nonPendingChargebacksCurrentPage}
								totalCount={
									filteredNonPendingChargebacksQuery.data
										? filteredNonPendingChargebacksQuery.data.totalChargebacks!
										: 1
								}
							/>
						) : (
							<Pagination
								onPageChange={(page) =>
									setNonPendingChargebacksCurrentPage(page)
								}
								currentPage={nonPendingChargebacksCurrentPage}
								totalCount={
									nonPendingChargebacksQuery.data
										? nonPendingChargebacksQuery.data
												.totalNonPendingChargebacks!
										: 1
								}
							/>
						)
					) : (
						<></>
					)}
					{tableName === 'Pendentes' && (
						<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
					)}
				</TableStyle.TableHeaderContainer>

				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder('created_at', tableName)}
									onToggleSort={() =>
										handleToggleSortColumn('created_at', tableName)
									}
								/>
								DATA
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder('name', tableName)}
									onToggleSort={() => handleToggleSortColumn('name', tableName)}
								/>
								COLABORADOR
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder(
										tableName === 'Pendentes' ? 'value' : 'reversed_value',
										tableName
									)}
									onToggleSort={() =>
										handleToggleSortColumn(
											tableName === 'Pendentes' ? 'value' : 'reversed_value',
											tableName
										)
									}
								/>
								VALOR
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>STATUS</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
						</TableStyle.TableRow>
					</TableStyle.TableHeader>

					<TableStyle.TableBody>
						{showingTable === tableName
							? chargebacks.map((chargeback) =>
									generateChargebackRow(chargeback)
							  )
							: generateChargebackRow(chargebacks[0])}
					</TableStyle.TableBody>
				</TableStyle.Table>
			</>
		);
	}

	if (
		pendingChargebacksQuery.isLoading ||
		nonPendingChargebacksQuery.isLoading
	) {
		return (
			<S.Container>
				<PageTitle title='Concluídos' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			{getTable(
				'Pendentes',
				pendingChargebacksFiltersParams.find((f) => !!f.value)
					? filteredPendingChargebacksQuery.data?.chargebacks
					: pendingChargebacksQuery.data?.pendingChargebacks
			)}
			{getTable(
				'Concluídos',
				nonPendingChargebacksFiltersParams.find((f) => !!f.value)
					? filteredNonPendingChargebacksQuery.data?.chargebacks
					: nonPendingChargebacksQuery.data?.nonPendingChargebacks
			)}

			<S.CreateButton to='/home/chargebacks/create'>
				Criar estorno
			</S.CreateButton>
		</S.Container>
	);
}
