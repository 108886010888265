import { useQuery } from 'react-query';
import {
	CORPWAY_COST_CENTER_KEY,
	getCostCenters,
} from '../../../../../services/queries/Corpway/CostCenters';
import { useAuth } from '../../../../../hooks/useAuth';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import styled from 'styled-components';
import { Label } from '../../../../../componentsV2/ui/Form/Label';
import { MdErrorOutline, MdInfo } from 'react-icons/md';
import { Typography } from '../../../../../componentsV2/ui/Typography';
import { Button } from '../../../../../componentsV2/ui/Button';
import { useState } from 'react';

interface Props {
	selectedCostCenterIds: string[];
	onChangeSelection: (costCenterIds: string[]) => void;
	errorMessage?: string;
	allSelected?: boolean;
}

export function CostCenterSelect({
	selectedCostCenterIds,
	onChangeSelection,
	errorMessage,
	allSelected = false,
}: Props) {
	const { currentCompany } = useAuth();

	const getCostCentersQuery = useQuery(
		[CORPWAY_COST_CENTER_KEY, currentCompany?.id],
		() => getCostCenters(currentCompany!.id),
		{
			onSuccess: (data) => {
				setChecked(
					data.costCenters.reduce((acc, { id }) => {
						return { ...acc, [id]: allSelected };
					}, {})
				);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os centros de custo. '
				);
			},
		}
	);

	const initialState: { [id: string]: boolean } | undefined =
		getCostCentersQuery.data?.costCenters.reduce((acc, { id }) => {
			return { ...acc, [id]: allSelected };
		}, {});

	const [checked, setChecked] = useState(initialState);

	function checkAll() {
		setChecked(
			getCostCentersQuery.data?.costCenters.reduce((acc, { id }) => {
				return { ...acc, [id]: true };
			}, {})
		);
		onChangeSelection(
			getCostCentersQuery.data?.costCenters.map(({ id }) => id) || []
		);
	}

	function uncheckAll() {
		setChecked(
			getCostCentersQuery.data?.costCenters.reduce((acc, { id }) => {
				return { ...acc, [id]: false };
			}, {})
		);
		onChangeSelection([]);
	}

	function handleSelectCostCenter(costCenterId: string) {
		if (selectedCostCenterIds.includes(costCenterId)) {
			onChangeSelection(
				selectedCostCenterIds.filter((id) => id !== costCenterId)
			);
		} else {
			onChangeSelection([...selectedCostCenterIds, costCenterId]);
		}

		setChecked({ ...checked, [costCenterId]: !checked![costCenterId] });
	}

	const hasAllCostCentersSelected =
		selectedCostCenterIds.length ===
		getCostCentersQuery.data?.total_costCenters;

	return (
		<Container>
			<CostCenterSelectorContainer>
				<Header>
					<Label>
						Centros de custo
						{(selectedCostCenterIds.length ===
							getCostCentersQuery.data?.costCenters.length ||
							allSelected) &&
							' - Todos'}
					</Label>
					{!allSelected && getCostCentersQuery.data?.costCenters.length !== 0 && (
						<Button
							type={'button'}
							style={{ fontSize: '1rem', padding: '0.5rem 0.5rem' }}
							intent={'terciary'}
							$outline
							width={'contain'}
							onClick={hasAllCostCentersSelected ? uncheckAll : checkAll}
						>
							{hasAllCostCentersSelected ? 'Desmarcar tudo' : 'Selecionar tudo'}
						</Button>
					)}
				</Header>
				{getCostCentersQuery.data?.costCenters.length === 0 && (
					<EmptyContainer>
						<MdInfo size={30} color={'#929292'} />
						<Typography>Não há itens a serem exibidos</Typography>
					</EmptyContainer>
				)}
				<List>
					{getCostCentersQuery.data?.costCenters.map((costCenter) => (
						<li key={costCenter.id}>
							<input
								type='checkbox'
								disabled={allSelected}
								checked={
									(checked ? checked[costCenter.id] : false) || allSelected
								}
								onChange={() => handleSelectCostCenter(costCenter.id)}
							/>
							{costCenter.title}
						</li>
					))}
				</List>
			</CostCenterSelectorContainer>
			{errorMessage && (
				<ErrorMessage>
					<MdErrorOutline />
					{errorMessage}
				</ErrorMessage>
			)}
		</Container>
	);
}

const CostCenterSelectorContainer = styled.div`
	display: flex;
	flex-direction: column;
	grid-row-gap: 1rem;
	width: 30rem;
	padding: 2rem;
	border-radius: 1.6rem;
	border: 1px solid #f0f3f8;
	@media (max-width: 1024px) {
		width: 100%;
	}
`;

const Container = styled.div`
	display: grid;
	gap: 1rem;
	height: 100%;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const EmptyContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	gap: 1rem;
	align-items: center;
`;

const List = styled.ul`
	display: grid;
	gap: 1rem;
	list-style: none;
	font-size: 1.4rem;
	font-weight: 600;
	height: 19rem;
	max-height: 19rem;
	color: var(--dark-gray);
	& li {
		display: flex;
		align-items: center;
		gap: 1rem;
	}
	& input {
		width: 1.6rem;
		height: 1.6rem;
	}
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 0.6rem;
		height: 0.6rem;
	}

	&::-webkit-scrollbar-track {
		background-color: #f5f5f5;
		border-radius: 0.6rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #c5c5c5;
		border-radius: 0.6rem;
	}

	@media (max-width: 1024px) {
		display: flex;
		flex-wrap: wrap;
	}
`;
const ErrorMessage = styled.span`
	display: flex;
	align-items: center;
	color: var(--toastify-color-error);
	font-size: 1.2rem;
	gap: 0.7rem;
`;
