import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Refund } from '../../../@types';
import ImagesSlider from '../../../components/ImagesSlider';
import Loader from '../../../components/Loader';
import PageTitle from '../../../components/PageTitle';
import UserCard from '../../../components/UserCard';
import { useDialogModal } from '../../../hooks/useDialogModal';
import { parseDate } from '../../../utils/parseDate';
import { getStatusIcon, parseStatusString } from '../../../utils/parseStatus';
import FeedbackModal from '../../../components/FeedbackModal';
import * as S from './styles';
import { useAuth } from '../../../hooks/useAuth';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	approveRefund,
	editBenefitValue,
	getRefund,
	reproveRefund,
} from '../../../services/queries/Refunds';
import {
	convertCentsToReais,
	convertReaisToCents,
} from '../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { FormCurrencyInput } from '../../../components/Form/FormStyles';
import { RefundValueHistoryLogs } from './RefundValueHistory';
import { FaFilePdf } from 'react-icons/fa';

interface RefundDetailsParams {
	id: string;
}

export default function RefundDetails() {
	const { id } = useParams<RefundDetailsParams>();
	const { user, currentCompany, companyBalance, updateCompanyBalance } =
		useAuth();
	const { openConfirmDialog, openOptionsDialog } = useDialogModal();
	const history = useHistory();
	const [value, setValue] = useState(0);
	const queryClient = useQueryClient();

	const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
	// When this image is set, open the slider showing this img initially
	const [imageToOpenSlider, setImageToOpenSlider] = useState<string | null>(
		null
	);

	const fetchRefundQuery = useQuery<Refund, Error>(
		['fetchRefund', currentCompany?.id, id],
		() => {
			return getRefund(id, currentCompany?.id);
		},
		{
			onSuccess: (data) => {
				const value = Number(data.value) / 100;
				setValue(value);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar o reembolso. '
				);
			},
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	const reproveRefundMutation = useMutation(
		(feedback: string) => {
			return reproveRefund(feedback, id, currentCompany?.id);
		},
		{
			onSuccess: () => {
				openConfirmDialog(`A solicitação foi reprovada!`, 'Entendi', () => {});
				history.push('/home/refunds');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao reprovar reembolso. '
				);
			},
		}
	);

	const approveRefundMutation = useMutation(
		() => {
			return approveRefund(id, currentCompany?.id);
		},
		{
			onSuccess: () => {
				openConfirmDialog(
					`A solicitação foi aprovada! O colaborador receberá o valor do reembolso`,
					'Entendi',
					() => {}
				);
				updateCompanyBalance.refetch();
				history.push('/home/refunds');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao aprovar reembolso. '
				);
			},
		}
	);

	const editValueMutation = useMutation(
		({ value }: { value: number }) =>
			editBenefitValue(id, value, currentCompany!.id),
		{
			onSuccess: () => {
				toast.info(`Valor do reembolso editado com sucesso.`);
				queryClient.invalidateQueries(['fetchRefund', currentCompany?.id, id]);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao editar o valor do reembolso. '
				);
			},
		}
	);

	const imagesAttachments = fetchRefundQuery.data?.attachment_url.filter(
		(a) => !a.endsWith('pdf')
	);
	const pdfAttachment = fetchRefundQuery.data?.attachment_url.find((a) =>
		a.endsWith('pdf')
	);

	async function handleApproveSubmition() {
		if (
			companyBalance.multiflex_balance <
			Number(fetchRefundQuery.data!.value) / 100
		) {
			toast.error('Saldo da companhia insuficiente');
			return;
		}

		let message = `Tem certeza que deseja aprovar o reembolso no valor de ${convertCentsToReais(
			Number(fetchRefundQuery.data!.value)
		)}?`;

		openOptionsDialog(
			message,
			'Confirmar',
			() => approveRefundMutation.mutate(),
			'Cancelar',
			() => {},
			true
		);
	}

	function handleOpenSlider(imgId: string) {
		setImageToOpenSlider(imgId);
	}
	function handleCloseSlider() {
		setImageToOpenSlider(null);
	}

	function handleEditBenefitValue() {
		const valueInCents = convertReaisToCents(value);
		if (valueInCents === 0) {
			toast.error('Valor abaixo do mínimo permitido!');
			return;
		}

		editValueMutation.mutate({ value: valueInCents });
	}

	if (
		!fetchRefundQuery.data ||
		approveRefundMutation.isLoading ||
		reproveRefundMutation.isLoading
	) {
		return (
			<S.Container>
				<PageTitle title='Detalhamento' />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<S.Header>
				<PageTitle title='Detalhamento' />
				{getStatusIcon(fetchRefundQuery.data.status)}
				{parseStatusString(fetchRefundQuery.data.status)}
			</S.Header>

			<S.InfoTop>
				<S.InfoContainer>
					<S.InfoTitle>Colaborador</S.InfoTitle>
					<UserCard
						name={`${fetchRefundQuery.data.user!.first_name} ${
							fetchRefundQuery.data.user!.last_name
						}`}
						bottomInfo={fetchRefundQuery.data.user!.office}
						avatar_url={fetchRefundQuery.data.user!.avatar_url}
					/>
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>Valor</S.InfoTitle>
					{fetchRefundQuery.data.status === 'pending' &&
					user.access_level === 'admin' ? (
						<S.BenefitValueFormContainer>
							<FormCurrencyInput
								value={value}
								onValueChange={({ floatValue }) => setValue(floatValue)}
							/>
							<S.EditValueBtn
								onClick={handleEditBenefitValue}
								disabled={editValueMutation.isLoading}
							>
								{editValueMutation.isLoading ? (
									<Loader color='white' size={20} />
								) : (
									'Editar'
								)}
							</S.EditValueBtn>
						</S.BenefitValueFormContainer>
					) : (
						convertCentsToReais(Number(fetchRefundQuery.data.value))
					)}
				</S.InfoContainer>
			</S.InfoTop>

			<S.InfoTop>
				<S.InfoContainer>
					<S.InfoTitle>SOLICITAÇÃO</S.InfoTitle>
					{fetchRefundQuery.data.title}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Data da solicitação</S.InfoTitle>
					{parseDate(fetchRefundQuery.data.created_at, 2)}
				</S.InfoContainer>
			</S.InfoTop>

			<S.InfoTop>
				<S.InfoContainer>
					<S.InfoTitle>Descrição</S.InfoTitle>
					{fetchRefundQuery.data.description}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Data de compra</S.InfoTitle>
					{parseDate(fetchRefundQuery.data.purchase_date, 2)}
				</S.InfoContainer>
			</S.InfoTop>

			<S.InfoTop>
				{fetchRefundQuery.data.status === 'canceled' && (
					<S.InfoContainer>
						<S.InfoTitle>Motivo da reprovação</S.InfoTitle>
						{fetchRefundQuery.data.reason_disapproval}
					</S.InfoContainer>
				)}

				{fetchRefundQuery.data.status !== 'pending' && (
					<S.InfoContainer>
						<S.InfoTitle>Data de análise</S.InfoTitle>
						{parseDate(
							fetchRefundQuery.data.review_date ??
								fetchRefundQuery.data.updated_at,
							2
						)}
					</S.InfoContainer>
				)}
			</S.InfoTop>

			<S.InfoContainer>
				<S.InfoTitle>Anexos</S.InfoTitle>
				<S.AttachmentsContainer>
					{imagesAttachments?.map((attachment) => (
						<S.ImagesList
							key={attachment}
							onClick={() => handleOpenSlider(attachment)}
						>
							<S.AttachmentImage src={attachment} alt='Anexo' />
						</S.ImagesList>
					))}
				</S.AttachmentsContainer>
				{pdfAttachment && (
					<S.AttachmentsContainer>
						<S.AttachmentLink
							href={pdfAttachment}
							target='_blank'
							rel='noopener noreferrer'
							data-rh='Ver PDF'
						>
							<FaFilePdf />
						</S.AttachmentLink>
					</S.AttachmentsContainer>
				)}
			</S.InfoContainer>

			<RefundValueHistoryLogs
				valueHistory={fetchRefundQuery.data.refund_history}
			/>

			{fetchRefundQuery.data.status === 'pending' &&
				user.access_level !== 'operator' && (
					<S.OptionsContainer>
						<S.ReproveOption onClick={() => setFeedbackModalOpen(true)}>
							Reprovar solicitação
						</S.ReproveOption>
						<S.ApproveOption onClick={handleApproveSubmition}>
							Aprovar solicitação
						</S.ApproveOption>
					</S.OptionsContainer>
				)}

			{/* ImageSlider, it opens when initiaImg is set */}
			<ImagesSlider
				initialImg={imageToOpenSlider}
				closeSlide={handleCloseSlider}
				images={imagesAttachments!}
			/>

			<FeedbackModal
				description='Motivo da reprovação'
				isFeedbackModalOpen={feedbackModalOpen}
				handleCloseFeedbackModal={() => setFeedbackModalOpen(false)}
				submit={(feedback) => reproveRefundMutation.mutate(feedback)}
			/>
		</S.Container>
	);
}
