import { useMutation, useQuery } from 'react-query';
import {
	ApproveCorpwayTermsAndPrivacyPolicy,
	getCorpwayPrivacyPolicy,
	getCorpwayTerms,
} from '../../services/queries/Terms';
import * as S from './styles';
import LogoControl from '../../assets/BountyControl.svg';
import LogoControlWhite from '../../assets/BountyControlWhite.svg';
import Loader from '../../components/Loader';
import { showErrorMessage } from '../../utils/ErrorHandler';
import { CorporateExpensesTheme } from '../../styles/CorporateExpensesTheme';
import { CheckboxField } from '../../componentsV2/ui/Form/CheckboxField';
import { Button } from '../../componentsV2/ui/Button';
import { useRef, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useHistory } from 'react-router-dom';

export function TermsCorpway() {
	const [termsAccept, setTermsAccept] = useState(false);
	const { user, updateUserInfo } = useAuth();
	const { push } = useHistory();

	const termsQuery = useQuery<string, Error>(
		['corpwayTerm'],
		() => {
			return getCorpwayTerms();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os termos de uso. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const privacyQuery = useQuery(
		['privacyPolicyCorpway'],
		() => {
			return getCorpwayPrivacyPolicy();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os avisos de privacidade. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const approvedMutation = useMutation(ApproveCorpwayTermsAndPrivacyPolicy, {
		onSuccess() {
			const updateUser = {
				privacy_policy_accepted_corpway: true,
				term_of_use_accepted_corpway: true,
			};
			updateUserInfo({ ...user, ...updateUser });
			push('/corporate-expenses/');
		},
	});

	if (termsQuery.isLoading || privacyQuery.isLoading) {
		return (
			<S.Container>
				<CorporateExpensesTheme />
				<S.LogoImage
					className='responsiveImage'
					src={LogoControlWhite}
					alt='Logo do Bounty'
				/>
				<S.TermsContainer>
					<S.TextTitle>
						{termsQuery.isLoading ? 'Termos de uso' : 'Avisos de privacidade'}
					</S.TextTitle>
				</S.TermsContainer>
				<div style={{ marginTop: '3rem' }} />
				<Loader color='white' />
			</S.Container>
		);
	}

	const Terms = () => {
		const [termsRead, setTermsRead] = useState(false);
		const [termsChecked, setTermsChecked] = useState(false);
		const termsContainerRef = useRef<HTMLDivElement>(null);

		const handleScroll = () => {
			const termsContainer = termsContainerRef.current;
			if (
				termsContainer &&
				1 + termsContainer.scrollTop + termsContainer.clientHeight >=
					termsContainer.scrollHeight
			) {
				setTermsRead(true);
			}
		};

		return (
			<S.TextsContainer>
				<S.InformationContainer>
					<S.TextTitle>Termos de uso</S.TextTitle>
					<S.LogoImage
						className='desktopImage'
						src={LogoControl}
						alt='Logo do Bounty'
					/>
				</S.InformationContainer>
				<S.TermsContainer
					ref={termsContainerRef}
					onScroll={handleScroll}
					dangerouslySetInnerHTML={{
						__html: termsQuery.data ? termsQuery.data : '',
					}}
				/>
				<S.AcceptContainer>
					<S.TextAccept
						{...(!termsRead && {
							'data-rh':
								'Por favor, leia nossos termos de uso antes de continuar',
						})}
					>
						<CheckboxField
							labelWidth='100%'
							label={
								<S.LabelText>
									<span> Eu li e estou ciente </span>do teor destes Termos de
									Uso
								</S.LabelText>
							}
							name='termsPolicy'
							onChange={() => setTermsChecked(!termsChecked)}
							disabled={!termsRead}
						/>
						<p>
							Ao clicar na caixa{' '}
							<span>“Eu li e estou ciente do teor destes Termos de Uso”</span>,
							você concorda e aceita expressa e integralmente as condições aqui
							descritas.
						</p>
					</S.TextAccept>

					<Button
						roundness='lg'
						width='adjusted'
						type='button'
						disabled={!termsChecked}
						intent='primary'
						style={{ width: '20rem', height: '7rem' }}
						onClick={() => setTermsAccept(true)}
					>
						Avançar
					</Button>
				</S.AcceptContainer>
			</S.TextsContainer>
		);
	};

	const PrivacyPolicy = () => {
		const [privacyRead, setPrivacyRead] = useState(false);
		const [privacyChecked, setPrivacyChecked] = useState(false);
		const privacyContainerRef = useRef<HTMLDivElement>(null);

		const handleScroll = () => {
			const termsContainer = privacyContainerRef.current;
			if (
				termsContainer &&
				1 + termsContainer.scrollTop + termsContainer.clientHeight >=
					termsContainer.scrollHeight
			) {
				setPrivacyRead(true);
			}
		};

		const handleAccept = () => {
			if (privacyChecked) {
				approvedMutation.mutate();
			}
		};

		return (
			<S.TextsContainer>
				<S.InformationContainer>
					<S.TextTitle>Avisos de privacidade</S.TextTitle>
					<S.LogoImage
						className='desktopImage'
						src={LogoControl}
						alt='Logo do Bounty'
					/>
				</S.InformationContainer>
				<S.TermsContainer
					ref={privacyContainerRef}
					onScroll={handleScroll}
					dangerouslySetInnerHTML={{
						__html: privacyQuery.data ? privacyQuery.data : '',
					}}
				/>
				<S.AcceptContainer>
					<S.TextAccept
						{...(!privacyRead && {
							'data-rh':
								'Por favor, leia nossos avisos de privacidade antes de continuar',
						})}
					>
						<CheckboxField
							labelWidth='100%'
							label={
								<S.LabelText>
									<span>Eu li e estou ciente</span> do teor deste aviso de
									privacidade
								</S.LabelText>
							}
							name='privacyPolicy'
							onChange={() => setPrivacyChecked(!privacyChecked)}
							disabled={!privacyRead}
						/>
						<p>
							Ao clicar na caixa{' '}
							<span>
								“Eu li e estou ciente do teor deste Aviso de privacidade”
							</span>
							, você concorda e aceita expressa e integralmente as condições
							aqui descritas.
						</p>
					</S.TextAccept>

					<Button
						roundness='lg'
						width='adjusted'
						type='button'
						intent='primary'
						disabled={!privacyChecked}
						style={{ width: '20rem', height: '7rem' }}
						onClick={handleAccept}
						loading={approvedMutation.isLoading}
					>
						Avançar
					</Button>
				</S.AcceptContainer>
			</S.TextsContainer>
		);
	};

	return (
		<>
			<S.Container>
				<CorporateExpensesTheme />
				<S.LogoImage
					className='responsiveImage'
					src={LogoControlWhite}
					alt='Logo do Bounty'
				/>
				{!termsAccept ? <Terms /> : <PrivacyPolicy />}
			</S.Container>
		</>
	);
}
