import { useState } from 'react';
import { MdNotes } from 'react-icons/md';
import { Refund } from '../../../@types';
import Loader from '../../../components/Loader';
import PageTitle from '../../../components/PageTitle';
import UserCard from '../../../components/UserCard';
import { parseDate } from '../../../utils/parseDate';
import { getStatusIcon, parseStatusString } from '../../../utils/parseStatus';
import * as S from './styles';
import * as TableStyle from '../../../components/Table/TableStyles';
import { EmptyContent } from '../../../components/EmptyContent';
import { useAuth } from '../../../hooks/useAuth';
import { Pagination } from '../../../components/Pagination';
import { Filter, FilterParams } from '../../../components/Filter';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	FilterRefundsReturn,
	getFilteredRefunds,
	getNonPendingRefunds,
	getPendingRefunds,
	increaseTotalBenefitsReports,
	RefundsListReturn,
} from '../../../services/queries/Refunds';
import { BsFileEarmarkText } from 'react-icons/bs';
import { convertCentsToReais } from '../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { useRefundsListStore } from '../../../stores/useRefundsListStore';
import { useHistoryNonMatchCallback } from '../../../hooks/useHistoryNonMatchCallback';
import { Document, Page, Text, View, Image, pdf } from '@react-pdf/renderer';
import { toast } from 'react-toastify';
import { cnpjMask, cpfMask } from '../../../utils/masks';
import * as Excel from 'exceljs';
import { FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { ReportGenerator } from './ReportGenerator';
import { useSortColumnHook } from '../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../components/SortColumnButton';
import { refundsReportStyle } from './ReportGenerator/styles';
import reportIcon from '../../../assets/flexibleBenefitsReports/ReportIcon.png';
import FlexibleBenefitsLogo from '../../../assets/flexibleBenefitsReports/FlexibleBenefitsLogo.png';
import { parseRefundStatusToReport } from './ReportGenerator/utils/parseRefundStatusToReport';

export default function RefundRequests() {
	const { currentCompany, user } = useAuth();
	const queryClient = useQueryClient();
	const [pendingRefundsCurrentPage, setPendingRefundsCurrentPage] = useState(1);
	const [nonPendingRefundsCurrentPage, setNonPendingRefundsCurrentPage] =
		useState(1);
	const {
		currentSortColumn: currentSortColumnPending,
		toggleSort: toggleSortPending,
	} = useSortColumnHook();
	const {
		currentSortColumn: currentSortColumnNonPending,
		toggleSort: toggleSortNonPending,
	} = useSortColumnHook();
	const [showingTable, setShowingTable] = useState<string>();

	const [
		pendingRefundsFiltersParams,
		setPendingRefundsFiltersParams,
		nonPendingRefundsFiltersParams,
		setNonPendingRefundsFiltersParams,
		resetFilters,
	] = useRefundsListStore((state) => [
		state.pendingRefundsFiltersParams,
		state.setPendingRefundsFiltersParams,
		state.nonPendingRefundsFiltersParams,
		state.setNonPendingRefundsFiltersParams,
		state.resetFilters,
	]);
	useHistoryNonMatchCallback('refunds', resetFilters);

	const increaseTotalBenefitsReportsQuery = useMutation(() =>
		increaseTotalBenefitsReports(currentCompany?.id!)
	);

	const pendingRefundsQuery = useQuery<RefundsListReturn, Error>(
		[
			'pendingRefundsList',
			currentCompany?.id,
			pendingRefundsCurrentPage,
			currentSortColumnPending,
		],
		() => {
			return getPendingRefunds(
				pendingRefundsCurrentPage,
				currentCompany?.id,
				currentSortColumnPending
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os reembolsos pendentes. '
				);
			},
			keepPreviousData: true,
		}
	);

	const nonPendingRefundsQuery = useQuery<RefundsListReturn, Error>(
		[
			'nonPendingRefundsList',
			currentCompany?.id,
			nonPendingRefundsCurrentPage,
			currentSortColumnNonPending,
		],
		() => {
			return getNonPendingRefunds(
				nonPendingRefundsCurrentPage,
				currentCompany?.id,
				currentSortColumnNonPending
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os reembolsos. '
				);
			},
			keepPreviousData: true,
		}
	);

	const filteredPendingRefunds = useQuery<FilterRefundsReturn, Error>(
		[
			'filteredRefunds',
			pendingRefundsFiltersParams,
			true,
			currentCompany?.id,
			pendingRefundsCurrentPage,
			currentSortColumnPending,
		],
		() => {
			return getFilteredRefunds(
				pendingRefundsFiltersParams,
				true,
				currentCompany?.id,
				pendingRefundsCurrentPage,
				currentSortColumnPending
			);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os reembolsos. '
				);
			},
			enabled:
				!!pendingRefundsFiltersParams.find((f) => !!f.value) &&
				!!pendingRefundsCurrentPage,
		}
	);

	const filteredNonPendingRefunds = useQuery<FilterRefundsReturn, Error>(
		[
			'filteredRefunds',
			nonPendingRefundsFiltersParams,
			false,
			currentCompany?.id,
			nonPendingRefundsCurrentPage,
			currentSortColumnNonPending,
		],
		() => {
			return getFilteredRefunds(
				nonPendingRefundsFiltersParams,
				false,
				currentCompany?.id,
				nonPendingRefundsCurrentPage,
				currentSortColumnNonPending
			);
		},
		{
			onError: (error) => {
				showErrorMessage(
					error as Error,
					'Não foi possível buscar os reembolsos. '
				);
			},
			enabled:
				!!nonPendingRefundsFiltersParams.find((f) => !!f.value) &&
				!!nonPendingRefundsCurrentPage,
		}
	);

	function refreshPage() {
		setPendingRefundsCurrentPage(1);
		setNonPendingRefundsCurrentPage(1);
		queryClient.resetQueries('pendingRefundsList');
		queryClient.resetQueries('nonPendingRefundsList');
	}

	function isIndividualTableLoading(tableName: string) {
		if (tableName === 'Pendentes')
			return (
				filteredPendingRefunds.isLoading || pendingRefundsQuery.isPreviousData
			);
		else if (tableName === 'Concluídos')
			return (
				filteredNonPendingRefunds.isLoading ||
				nonPendingRefundsQuery.isPreviousData
			);

		return false;
	}

	function getTableTotalRecords(tableName: string) {
		if (tableName === 'Pendentes') {
			if (pendingRefundsFiltersParams.find((f) => !!f.value)) {
				return filteredPendingRefunds.data?.totalRefunds;
			}
			return pendingRefundsQuery.data?.totalPendingRefunds;
		}

		if (nonPendingRefundsFiltersParams.find((f) => !!f.value)) {
			return filteredNonPendingRefunds.data?.totalRefunds;
		}
		return nonPendingRefundsQuery.data?.totalNonPendingRefunds;
	}

	function updateFilters(filters: FilterParams[], activeFilter: boolean) {
		if (activeFilter) {
			setPendingRefundsCurrentPage(1);
			setPendingRefundsFiltersParams(filters);
		} else {
			setNonPendingRefundsCurrentPage(1);
			setNonPendingRefundsFiltersParams(filters);
		}
	}

	async function generatePDFContent(refund: Refund, total_benefits: number) {
		return (
			<Document>
				<Page size='A4' style={refundsReportStyle.page} orientation='landscape'>
					<View style={refundsReportStyle.header} fixed>
						<Image src={FlexibleBenefitsLogo} style={refundsReportStyle.logo} />
						<View style={{ ...refundsReportStyle.headerInfo, width: '120%' }}>
							<Text style={refundsReportStyle.headerInfoLabel}>Nome:</Text>
							<Text style={refundsReportStyle.headerInfoData}>
								{currentCompany?.corporateName}
							</Text>
						</View>
						<View style={refundsReportStyle.headerInfo}>
							<Text style={refundsReportStyle.headerInfoLabel}>CNPJ:</Text>
							<Text style={refundsReportStyle.headerInfoData}>
								{cnpjMask(currentCompany?.cnpj!)}
							</Text>
						</View>
						<View style={refundsReportStyle.headerInfo}>
							<Text style={refundsReportStyle.headerInfoLabel}>N° Página:</Text>
							<Text
								style={refundsReportStyle.headerInfoData}
								render={({ pageNumber, totalPages }) =>
									`${String(pageNumber).padStart(2, '0')}/${String(
										totalPages
									).padStart(2, '0')}`
								}
							/>
						</View>
						<View style={refundsReportStyle.headerInfo}>
							<Text style={refundsReportStyle.headerInfoLabel}>
								Data do pedido:
							</Text>
							<Text style={refundsReportStyle.headerInfoData}>
								{Intl.DateTimeFormat('pt-BR', {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
									hour12: false, // 24-hour format
								}).format(new Date())}
							</Text>
						</View>
						<View style={{ ...refundsReportStyle.headerInfo, width: '60%' }}>
							<Text style={refundsReportStyle.headerInfoLabel}>
								N° do pedido:
							</Text>
							<Text style={refundsReportStyle.headerInfoData}>
								{String(total_benefits).padStart(7, '0')}
							</Text>
						</View>
					</View>

					<View style={refundsReportStyle.subHeader}>
						<View
							style={{ ...refundsReportStyle.titleContainer, width: '50%' }}
						>
							<Image src={reportIcon} style={refundsReportStyle.reportLogo} />

							<View style={refundsReportStyle.reportTitleContainer}>
								<Text
									style={{
										...refundsReportStyle.reportTitle,
										color: '#FF0037',
									}}
								>
									Relatório de
								</Text>

								<Text> </Text>

								<Text style={refundsReportStyle.reportTitle}>
									Reembolso Individual
								</Text>
							</View>
						</View>
					</View>

					<View style={refundsReportStyle.refundContainer}>
						<View style={{ ...refundsReportStyle.headerInfo, width: '50%' }}>
							<Text
								style={{ ...refundsReportStyle.headerInfoLabel, fontSize: 13 }}
							>
								Colaborador:
							</Text>
							<Text
								style={{ ...refundsReportStyle.headerInfoData, fontSize: 13 }}
							>
								{`${refund.collaborator.first_name} ${refund.collaborator.last_name}`}
							</Text>
						</View>

						<View style={{ ...refundsReportStyle.headerInfo, width: '25%' }}>
							<Text
								style={{ ...refundsReportStyle.headerInfoLabel, fontSize: 13 }}
							>
								CPF:
							</Text>
							<Text
								style={{ ...refundsReportStyle.headerInfoData, fontSize: 13 }}
							>
								{cpfMask(refund.collaborator.cpf)}
							</Text>
						</View>

						<View style={{ ...refundsReportStyle.headerInfo, width: '30%' }}>
							<Text
								style={{ ...refundsReportStyle.headerInfoLabel, fontSize: 13 }}
							>
								Título:
							</Text>
							<Text
								style={{ ...refundsReportStyle.headerInfoData, fontSize: 13 }}
							>
								{refund.title}
							</Text>
						</View>

						<View style={{ ...refundsReportStyle.headerInfo, width: '20%' }}>
							<Text
								style={{ ...refundsReportStyle.headerInfoLabel, fontSize: 13 }}
							>
								Data da Compra:
							</Text>
							<Text
								style={{ ...refundsReportStyle.headerInfoData, fontSize: 13 }}
							>
								{new Date(refund.purchase_date).toLocaleDateString()}
							</Text>
						</View>

						<View style={{ ...refundsReportStyle.headerInfo, width: '20%' }}>
							<Text
								style={{ ...refundsReportStyle.headerInfoLabel, fontSize: 13 }}
							>
								Data da Análise:
							</Text>
							<Text
								style={{ ...refundsReportStyle.headerInfoData, fontSize: 13 }}
							>
								{new Date(refund.review_date!).toLocaleDateString()}
							</Text>
						</View>

						<View style={{ ...refundsReportStyle.headerInfo, width: '14%' }}>
							<Text
								style={{ ...refundsReportStyle.headerInfoLabel, fontSize: 13 }}
							>
								Status:
							</Text>

							{parseRefundStatusToReport(refund.status, 12)}
						</View>

						<View
							style={{
								...refundsReportStyle.headerInfo,
								width: '20%',
								marginLeft: 90,
							}}
						>
							<Text
								style={{ ...refundsReportStyle.headerInfoLabel, fontSize: 13 }}
							>
								Valor:
							</Text>
							<Text
								style={{
									...refundsReportStyle.headerInfoData,
									fontSize: 13,
									fontWeight: 600,
								}}
							>
								{convertCentsToReais(Number(refund.value))}
							</Text>
						</View>
					</View>
				</Page>
			</Document>
		);
	}

	async function handleDownloadRefund(refund: Refund, pdfReport: boolean) {
		try {
			let blob: Blob;

			const { data } = await increaseTotalBenefitsReportsQuery.mutateAsync();

			if (pdfReport) {
				const PDFGenerated = await generatePDFContent(
					refund,
					data.total_benefits
				);
				blob = await pdf(PDFGenerated).toBlob();
			} else {
				blob = await generateSheetContent(refund, data.total_benefits);
			}

			// Create a temporary URL for the blob
			const url = URL.createObjectURL(blob);

			// Create a link element
			const link = document.createElement('a');
			link.href = url;
			link.download = `relatorio_de_reembolso.${pdfReport ? 'pdf' : 'xlsx'}`;

			// Simulate a click event to trigger the download
			link.dispatchEvent(new MouseEvent('click'));

			// Clean up the temporary URL
			URL.revokeObjectURL(url);
		} catch (error) {
			console.log(error);
			toast.error(
				'Ocorreu um erro ao tentar gerar o arquivo de relátorio. Tente novamente'
			);
		}
	}

	async function generateSheetContent(refund: Refund, total_reports: number) {
		const workbook = new Excel.Workbook();

		const worksheet = workbook.addWorksheet('Reembolsos');

		const currentTime = new Date();
		worksheet.addRow([
			`Gerado em ${currentTime.toLocaleDateString()} às ${currentTime.toLocaleTimeString(
				'pt-BR',
				{
					timeZone: 'America/Sao_Paulo',
					hour: '2-digit',
					minute: '2-digit',
				}
			)} no Benefícios Flexíveis. Usuário: ${user.email}.`,
		]);
		worksheet.addRow([`N° de pedido: ${total_reports}`]);

		worksheet.addRow([]);
		worksheet.addRow([`Empresa: ${currentCompany!.corporateName}`]);
		worksheet.addRow([`CNPJ: ${cnpjMask(currentCompany!.cnpj)}`]);
		worksheet.addRow([]);

		let defaultColumns = [
			'NOME',
			'CPF',
			'TÍTULO',
			'DESCRIÇÃO',
			'VALOR',
			'DATA CRIAÇÃO',
			'DATA CROMPRA',
			'DATA ANÁLISE',
			'STATUS',
		];

		const columnsIndexes = [
			{ index: 1, width: 20 },
			{ index: 2, width: 15 },
			{ index: 3, width: 30 },
			{ index: 4, width: 30 },
			{ index: 5, width: 15 },
			{ index: 6, width: 15 },
			{ index: 7, width: 15 },
			{ index: 8, width: 15 },
			{ index: 9, width: 15 },
		];

		if (refund.status === 'canceled') {
			defaultColumns.push('MOTIVO REPROVAÇÃO');
			columnsIndexes.push({ index: 10, width: 30 });
		}

		worksheet.addRow(defaultColumns);

		columnsIndexes.forEach((colIndex) => {
			const col = worksheet.getColumn(colIndex.index);
			col.width = colIndex.width;
		});

		let convertValueRefund = convertCentsToReais(Number(refund.value));
		let status = parseStatusString(refund.status);
		let date = parseDate(refund.created_at, 2);
		let purchase_date = parseDate(refund.purchase_date, 2);
		let review_date = parseDate(refund.review_date!, 2);

		let refundValues = [
			refund.collaborator.first_name + ' ' + refund.collaborator.last_name,
			refund.collaborator.cpf,
			refund.title,
			refund.description,
			convertValueRefund,
			date,
			purchase_date,
			review_date,
			status,
		];

		if (refund.status === 'canceled') {
			refundValues.push(refund.reason_disapproval);
		}

		worksheet.addRow(refundValues);

		const buffer = await workbook.xlsx.writeBuffer();
		const fileType =
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

		const blob = new Blob([buffer], { type: fileType });

		return blob;
	}

	function checkPendingRefundsExists() {
		if (pendingRefundsFiltersParams.find((f) => !!f.value)) {
			if (!filteredPendingRefunds.data) return false;
			return filteredPendingRefunds.data.totalRefunds > 0;
		}

		if (!pendingRefundsQuery.data) return false;
		return pendingRefundsQuery.data.totalPendingRefunds! > 0;
	}

	function getTotalValues(table_name: string) {
		if (table_name === 'Pendentes') {
			return (
				<S.TotalValuesContainer>
					<S.TotalValue>
						<S.TotalValueLabel>
							Valor total de reembolsos pendentes:
						</S.TotalValueLabel>
						<S.TotalValueNumber>
							{pendingRefundsFiltersParams.find((f) => f.value)
								? convertCentsToReais(
										Number(filteredPendingRefunds.data?.total_pending)
								  )
								: convertCentsToReais(
										Number(pendingRefundsQuery.data?.total_pending)
								  )}
						</S.TotalValueNumber>
					</S.TotalValue>
				</S.TotalValuesContainer>
			);
		} else if (table_name === 'Concluídos') {
			if (nonPendingRefundsFiltersParams.find((f) => f.value)) {
				const filterType = nonPendingRefundsFiltersParams.find(
					(f) => f.filter === 'status'
				);

				if (filterType?.selected) {
					const total_value =
						filterType.value === 'approved'
							? filteredNonPendingRefunds.data?.total_approved
							: filteredNonPendingRefunds.data?.total_canceled;

					return (
						<S.TotalValuesContainer>
							<S.TotalValue>
								<S.TotalValueLabel>
									Valor total de reembolsos:
								</S.TotalValueLabel>
								<S.TotalValueNumber>
									{convertCentsToReais(Number(total_value))}
								</S.TotalValueNumber>
							</S.TotalValue>
						</S.TotalValuesContainer>
					);
				} else {
					return (
						<S.TotalValuesContainer>
							<S.TotalValue>
								<S.TotalValueLabel>
									Valor total de reembolsos concluídos:
								</S.TotalValueLabel>
								<S.TotalValueNumber>
									{convertCentsToReais(
										Number(filteredNonPendingRefunds.data?.total_approved)
									)}
								</S.TotalValueNumber>
							</S.TotalValue>
							<S.TotalValue>
								<S.TotalValueLabel>
									Valor total de reembolsos reprovados:
								</S.TotalValueLabel>
								<S.TotalValueNumber>
									{convertCentsToReais(
										Number(filteredNonPendingRefunds.data?.total_canceled)
									)}
								</S.TotalValueNumber>
							</S.TotalValue>
						</S.TotalValuesContainer>
					);
				}
			}
		}
	}

	function getSortColumnOrder(columnName: string, tableName: string) {
		if (tableName === 'Pendentes') {
			return currentSortColumnPending?.name === columnName
				? currentSortColumnPending.order
				: null;
		}
		return currentSortColumnNonPending?.name === columnName
			? currentSortColumnNonPending.order
			: null;
	}

	function handleToggleSortColumn(columnName: string, tableName: string) {
		if (tableName === 'Pendentes') toggleSortPending(columnName);
		else toggleSortNonPending(columnName);
	}

	function generateRow(refund: Refund) {
		return (
			<TableStyle.TableRow key={refund.id}>
				<TableStyle.TableData>
					<TableStyle.DocumentContainer>
						<TableStyle.IconContainer>
							<BsFileEarmarkText />
						</TableStyle.IconContainer>
						{refund.status === 'pending'
							? parseDate(refund.created_at, 1)
							: parseDate(refund.review_date ?? refund.updated_at, 1)}
					</TableStyle.DocumentContainer>
				</TableStyle.TableData>
				<TableStyle.TableData>
					<UserCard
						name={`${refund.collaborator.first_name} ${refund.collaborator.last_name}`}
						bottomInfo={refund.collaborator.office}
						avatar_url={refund.collaborator.avatar_url}
					/>
				</TableStyle.TableData>
				<TableStyle.TableData>{refund.title}</TableStyle.TableData>
				<TableStyle.TableData>
					{convertCentsToReais(Number(refund.value))}
				</TableStyle.TableData>
				<TableStyle.TableData>
					<TableStyle.StatusContainer>
						{getStatusIcon(refund.status)}
						<span>{parseStatusString(refund.status)}</span>
					</TableStyle.StatusContainer>
				</TableStyle.TableData>
				<TableStyle.TableData>
					<S.OptionsContainer>
						{refund.status === 'pending' ? (
							<>
								<TableStyle.OptionLink
									to={`/home/refunds/${refund.id}`}
									data-rh='Detalhamento'
								>
									<MdNotes />
								</TableStyle.OptionLink>
							</>
						) : (
							<>
								<TableStyle.OptionLink
									to={`/home/refunds/${refund.id}`}
									data-rh='Detalhamento'
								>
									<MdNotes />
								</TableStyle.OptionLink>
								<TableStyle.OptionButton
									data-rh='Baixar relatório PDF'
									onClick={async () => await handleDownloadRefund(refund, true)}
								>
									<FaFilePdf />
								</TableStyle.OptionButton>
								<TableStyle.OptionButton
									data-rh='Baixar relatório XLSX'
									onClick={async () =>
										await handleDownloadRefund(refund, false)
									}
								>
									<FaFileExcel />
								</TableStyle.OptionButton>
							</>
						)}
					</S.OptionsContainer>
				</TableStyle.TableData>
			</TableStyle.TableRow>
		);
	}

	function getTable(tableName: string, refunds?: Refund[]) {
		if (isIndividualTableLoading(tableName) || !refunds?.length) {
			return (
				<>
					<TableStyle.TitleWrapper>
						<PageTitle title={tableName} />
						{tableName === 'Concluídos' ? (
							<>
								<Filter
									filterParams={nonPendingRefundsFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, false);
									}}
								/>
							</>
						) : (
							<>
								<Filter
									filterParams={pendingRefundsFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, true);
									}}
								/>
							</>
						)}
						{tableName === 'Pendentes' && (
							<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
						)}
					</TableStyle.TitleWrapper>
					{isIndividualTableLoading(tableName) ? (
						<Loader />
					) : (
						<EmptyContent text='Não há ítens a serem exibidos.' />
					)}
				</>
			);
		}

		return (
			<>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle
							title={tableName}
							totalRecords={getTableTotalRecords(tableName) ?? 0}
						/>
						{tableName === 'Concluídos' ? (
							<>
								<Filter
									filterParams={nonPendingRefundsFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, false);
									}}
								/>
							</>
						) : (
							<>
								<Filter
									filterParams={pendingRefundsFiltersParams}
									onFiltersChanged={(updatedFilters) => {
										updateFilters(updatedFilters, true);
									}}
								/>
							</>
						)}
						{showingTable === tableName ? (
							<TableStyle.SeeLess
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver menos
							</TableStyle.SeeLess>
						) : (
							<TableStyle.SeeMore
								onClick={() =>
									showingTable === tableName
										? setShowingTable('')
										: setShowingTable(tableName)
								}
							>
								Ver mais
							</TableStyle.SeeMore>
						)}
					</TableStyle.TitleWrapper>
					{showingTable === tableName ? (
						tableName === 'Pendentes' ? (
							pendingRefundsFiltersParams.find((f) => !!f.value) ? (
								<Pagination
									onPageChange={(page) => setPendingRefundsCurrentPage(page)}
									currentPage={pendingRefundsCurrentPage}
									totalCount={
										filteredPendingRefunds.data
											? filteredPendingRefunds.data.totalRefunds!
											: 0
									}
								/>
							) : (
								<Pagination
									onPageChange={(page) => setPendingRefundsCurrentPage(page)}
									currentPage={pendingRefundsCurrentPage}
									totalCount={
										pendingRefundsQuery.data
											? pendingRefundsQuery.data.totalPendingRefunds!
											: 0
									}
								/>
							)
						) : nonPendingRefundsFiltersParams.find((f) => !!f.value) ? (
							<Pagination
								onPageChange={(page) => setNonPendingRefundsCurrentPage(page)}
								currentPage={nonPendingRefundsCurrentPage}
								totalCount={
									filteredNonPendingRefunds.data
										? filteredNonPendingRefunds.data.totalRefunds!
										: 0
								}
							/>
						) : (
							<Pagination
								onPageChange={(page) => setNonPendingRefundsCurrentPage(page)}
								currentPage={nonPendingRefundsCurrentPage}
								totalCount={
									nonPendingRefundsQuery.data
										? nonPendingRefundsQuery.data.totalNonPendingRefunds!
										: 0
								}
							/>
						)
					) : (
						<></>
					)}
					{tableName === 'Pendentes' && (
						<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
					)}
				</TableStyle.TableHeaderContainer>

				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							{tableName === 'Concluídos' ? (
								<TableStyle.TableHeaderCell>
									<SortColumnButton
										order={getSortColumnOrder('review_date', tableName)}
										onToggleSort={() =>
											handleToggleSortColumn('review_date', tableName)
										}
									/>
									DATA DE ANÁLISE
								</TableStyle.TableHeaderCell>
							) : (
								<TableStyle.TableHeaderCell>
									<SortColumnButton
										order={getSortColumnOrder('created_at', tableName)}
										onToggleSort={() =>
											handleToggleSortColumn('created_at', tableName)
										}
									/>
									DATA
								</TableStyle.TableHeaderCell>
							)}
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder('name', tableName)}
									onToggleSort={() => handleToggleSortColumn('name', tableName)}
								/>
								COLABORADOR
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder('title', tableName)}
									onToggleSort={() =>
										handleToggleSortColumn('title', tableName)
									}
								/>
								SOLICITAÇÃO
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={getSortColumnOrder('value', tableName)}
									onToggleSort={() =>
										handleToggleSortColumn('value', tableName)
									}
								/>
								VALOR
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>STATUS</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
						</TableStyle.TableRow>
					</TableStyle.TableHeader>

					<TableStyle.TableBody>
						{showingTable === tableName
							? refunds?.map((refund) => generateRow(refund))
							: generateRow(refunds[0])}
					</TableStyle.TableBody>
				</TableStyle.Table>
			</>
		);
	}

	if (pendingRefundsQuery.isLoading || nonPendingRefundsQuery.isLoading) {
		return (
			<S.Container>
				<PageTitle title='Concluídos' />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			{getTable(
				'Pendentes',
				pendingRefundsFiltersParams.find((f) => !!f.value)
					? filteredPendingRefunds.data?.refunds
					: pendingRefundsQuery.data?.pendingRefunds
			)}

			{!filteredPendingRefunds.isLoading &&
				!pendingRefundsQuery.isLoading &&
				getTotalValues('Pendentes')}

			<S.ReportContainer>
				<ReportGenerator
					company_id={currentCompany?.id!}
					pending={true}
					filterParams={pendingRefundsFiltersParams}
					sortColumn={{
						name: 'name',
						order: 'ASC',
					}}
					disabled={!checkPendingRefundsExists()}
				/>
			</S.ReportContainer>

			{getTable(
				'Concluídos',
				nonPendingRefundsFiltersParams.find((f) => !!f.value)
					? filteredNonPendingRefunds.data?.refunds
					: nonPendingRefundsQuery.data?.nonPendingRefunds
			)}

			{!filteredNonPendingRefunds.isLoading &&
				!nonPendingRefundsQuery.isLoading &&
				getTotalValues('Concluídos')}

			<S.ReportContainer>
				{nonPendingRefundsFiltersParams.find((f) => f.value) && (
					<ReportGenerator
						company_id={currentCompany?.id!}
						pending={false}
						filterParams={nonPendingRefundsFiltersParams}
						sortColumn={currentSortColumnNonPending}
					/>
				)}
			</S.ReportContainer>
		</S.Container>
	);
}
