import { useQuery } from 'react-query';
import { useAuth } from '../../hooks/useAuth';
import { fetchLogs } from '../../services/queries/PaymentsV2';
import { getBenefitParsedTitle } from '../../utils/benefits/getBenefitParsedTitle';
import { parseDate } from '../../utils/parseDate';
import { EmptyContent } from '../EmptyContent';
import Loader from '../Loader';
import PageTitle from '../PageTitle';
import * as S from './styles';
import { convertCentsToReais } from '../../utils/CurrencyConvert';
import { showErrorMessage } from '../../utils/ErrorHandler';

interface Props {
	paymentId: string;
}
export function PaymentLogs({ paymentId }: Props) {
	const { currentCompany } = useAuth();

	const fetchLogsQuery = useQuery(
		['fetchLogs', currentCompany?.id, paymentId],
		() => {
			return fetchLogs(paymentId, currentCompany!.id);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar o histórico de edições. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	if (!fetchLogsQuery.data) {
		return (
			<>
				<PageTitle title='Histórico de edições' />
				<S.LoadingContainer>
					<Loader />
				</S.LoadingContainer>
			</>
		);
	}

	const logs = [...fetchLogsQuery.data.logs].reverse();

	if (logs.length === 0) {
		return (
			<>
				<PageTitle title='Histórico de edições' />
				<EmptyContent text='Histórico de edições vazio' />
			</>
		);
	}

	return (
		<>
			<PageTitle title='Histórico de edições' />
			<S.ChangesList data-testid='logs-container'>
				{logs.map((log) => {
					if (log.context === 'update-value') {
						return (
							<S.Change key={log.id}>
								<span>{log.operator_name}</span> alterou o valor de{' '}
								<span>{getBenefitParsedTitle(log.benefit!)}</span>{' '}
								{log.group_name ? (
									<>
										do grupo <span>{log.group_name}</span> de{' '}
									</>
								) : (
									<>
										do colaborador <span>{log.collaborator_name}</span> de{' '}
									</>
								)}
								<span>{convertCentsToReais(Number(log.previous_value))}</span>{' '}
								para <span>{convertCentsToReais(Number(log.new_value))}</span>{' '}
								em <span>{parseDate(log.created_at, 2)}</span>
							</S.Change>
						);
					}

					if (log.context === 'update-date') {
						return (
							<S.Change key={log.id}>
								<span>{log.operator_name}</span> alterou a data de agendamento
								de <span>{new Date(log.previous_date!).toLocaleString()}</span>{' '}
								para <span>{new Date(log.new_date!).toLocaleString()}</span> em{' '}
								<span>{parseDate(log.created_at, 2)}</span>
							</S.Change>
						);
					}

					if (log.context === 'disapproval') {
						return (
							<S.Change key={log.id}>
								<span>{log.operator_name}</span> reprovou{' '}
								<span>{getBenefitParsedTitle(log.benefit!)}</span>{' '}
								{log.group_name ? (
									<>
										do grupo <span>{log.group_name}</span> no valor de{' '}
									</>
								) : (
									<>
										do colaborador <span>{log.collaborator_name}</span> no valor
										de{' '}
									</>
								)}
								<span>{convertCentsToReais(Number(log.previous_value))}</span>{' '}
								pelo motivo de "<span>{log.reason_disapproval}</span>" em{' '}
								<span>{parseDate(log.created_at, 2)}</span>
							</S.Change>
						);
					}
					if (log.context === 'delete') {
						return (
							<S.Change key={log.id}>
								<span>{log.operator_name}</span> deletou{' '}
								<span>{getBenefitParsedTitle(log.benefit!)}</span>{' '}
								{log.group_name ? (
									<>
										do grupo <span>{log.group_name}</span> no valor de{' '}
									</>
								) : (
									<>
										do colaborador <span>{log.collaborator_name}</span> no valor
										de{' '}
									</>
								)}
								<span>{convertCentsToReais(Number(log.previous_value))}</span>{' '}
								em <span>{parseDate(log.created_at, 2)}</span>
							</S.Change>
						);
					}

					if (log.context === 'add') {
						return (
							<S.Change key={log.id}>
								<span>{log.operator_name}</span> adicionou{' '}
								<span>{getBenefitParsedTitle(log.benefit!)}</span>{' '}
								{log.group_name ? (
									<>
										para o grupo <span>{log.group_name}</span> com o valor de{' '}
									</>
								) : (
									<>
										para o colaborador <span>{log.collaborator_name}</span> com
										o valor de{' '}
									</>
								)}
								<span>{convertCentsToReais(Number(log.new_value))}</span> em{' '}
								<span>{parseDate(log.created_at, 2)}</span>
							</S.Change>
						);
					}

					if (log.context === 'chargeback') {
						return (
							<S.Change key={log.id}>
								<span>{log.operator_name}</span> iniciou o processo de estorno
								do pagamento em <span>{parseDate(log.created_at, 2)}</span>
							</S.Change>
						);
					}

					return null;
				})}
			</S.ChangesList>
		</>
	);
}
