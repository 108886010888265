import { createColumnHelper } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Card } from '../../../@types/CorporateExpenses/Card';
import { Transaction } from '../../../@types/CorporateExpenses/Transaction';
// import cardBlack from '../../../assets/card-black.jpg';
import { InfoContent } from '../../../componentsV2/InfoContent';
import { Table } from '../../../componentsV2/Table';
import { Button } from '../../../componentsV2/ui/Button';
import { useAuth } from '../../../hooks/useAuth';
import {
	CORPWAY_CARDS_KEY,
	getCardLastTransactions,
} from '../../../services/queries/Corpway/Cards';
import { convertCentsToReais } from '../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import {
	checkDeclineAndStrikeText,
	parseTransactionType,
} from '../Extract/utils/transactionUtils';
import { ActivateCardConfirmationModal } from './components/ActivateCardConfirmationModal';
import { BlockCardConfirmationModal } from './components/BlockCardConfirmationModal';
import { parseCardBaID } from './utils/parseCardBaID';

const columnHelper = createColumnHelper<Transaction>();
const columns = [
	columnHelper.accessor('added_time', {
		cell: (info) => new Date(info.getValue()).toLocaleDateString(),
		header: 'Data',
	}),
	columnHelper.accessor((row) => row, {
		cell: (info) => {
			const { txn_type, msg_type, currency, merchant_country } = info.getValue();

			return parseTransactionType(txn_type, msg_type, currency, merchant_country);
		},
		header: 'Lançamento',
	}),
	columnHelper.accessor((row) => row, {
		cell: (info) => {
			const { response_code, billing_amount } = info.getValue();

			const parsedValue = convertCentsToReais(billing_amount);
			return checkDeclineAndStrikeText(response_code, parsedValue);
		},
		header: 'Valor',
	}),
];
export function CardDetails() {
	const { id } = useParams<{ id: string }>();
	const { currentCompany, user } = useAuth();

	const getCardLastTransactionsQuery = useQuery(
		[CORPWAY_CARDS_KEY, id, currentCompany?.id],
		() => getCardLastTransactions(id, currentCompany!.id),
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível buscar o cartão. ');
			},
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	const card = getCardLastTransactionsQuery.data
		? {
				...getCardLastTransactionsQuery.data.card_data,
				balance: getCardLastTransactionsQuery.data.balance,
		  }
		: undefined;

	const LastTransactions = () => (
		<div>
			<Table
				columns={columns}
				data={getCardLastTransactionsQuery.data?.last_transactions ?? []}
				idPath='internal_transaction_id'
			/>
			<Button
				as='link'
				to={`/corporate-expenses/cards/${id}/extract`}
				intent='link'
				$outline
				style={{ marginTop: '2rem', width: 'fit-content', marginLeft: 'auto' }}
			>
				Ver extrato completo
			</Button>
		</div>
	);

	const InformationContent = () => (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				rowGap: '2rem',
			}}
		>
			<InfoContent label='Responsável:'>{card?.user?.name || '-'}</InfoContent>
			<InfoContent label='Regra:'>
				{card ? parseCardBaID(card.ba_id!) : '-'}
			</InfoContent>
			<hr />
			{card?.status === 'blocked' && user.roles.includes('manage-cards') && (
				<ActivateCardConfirmationModal card={card as unknown as Card} />
			)}
			{card?.status === 'active' && user.roles.includes('manage-cards') && (
				<BlockCardConfirmationModal card={card as unknown as Card} />
			)}
		</div>
	);

	return (
		<div>
			{/* <SectionContainer>
				<Breadcrumb
					routes={[
						{
							title: 'Cartões',
							route: '/corporate-expenses/cards',
						},
						{
							title: 'Detalhes do Cartão',
							route: `/corporate-expenses/cards/${id}`,
						},
					]}
				/>
				<TitleTypography image>Detalhes do Cartão</TitleTypography>
			</SectionContainer>

			<AsynchronousContent status={getCardLastTransactionsQuery.status}>
				{card !== undefined && (
					<ContentContainer>
						<CardContainer>
							<CardHeaderContainer>
								<Typography color='#FFF'>Final:</Typography>
								<Typography color='#FFF' size='1.8rem' weight='600'>
									{card.pan.slice(-8)}
								</Typography>

								{card.status !== 'active' && (
									<CardStatusContainer>
										<MdBlock />
										<span>{parseCardStatus(card.status)}</span>
									</CardStatusContainer>
								)}
							</CardHeaderContainer>

							<CardContentContainer>
								<InfoContent label='Apelido:'>
									<CardInfoContainer>{card.alias}</CardInfoContainer>
								</InfoContent>
								<InfoContent label='Centro de Custo:'>
									<CardInfoContainer>
										{card.cost_center?.title || '-'}
									</CardInfoContainer>
								</InfoContent>
								<InfoContent label='Saldo do Cartão:' orientation='horizontal'>
									<Typography weight='600' size='2.4rem'>
										{convertCentsToReais(card.balance)}
									</Typography>
								</InfoContent>
							</CardContentContainer>
						</CardContainer>

						<SectionContainer style={{ width: '100%' }}>
							<Tabs
								tabs={[
									{
										component: <InformationContent />,
										name: 'Informações',
									},
									{
										component: <LastTransactions />,
										name: 'Últimas Transações',
									},
								]}
							/>
						</SectionContainer>
					</ContentContainer>
				)}
			</AsynchronousContent> */}
		</div>
	);
}

const ContentContainer = styled.div`
	display: flex;
	column-gap: 2rem;
`;
const CardContainer = styled.div`
	border-radius: 1rem;
	width: 40rem;
	background-color: var(--white);
	& > div {
		padding: 2rem;
	}
`;
// const CardHeaderContainer = styled.div`
// 	position: relative;
// 	display: flex;
// 	flex-direction: column;
// 	background-color: #f2f2f2;
// 	height: 22rem;
// 	border-radius: 1rem 1rem 0 0;
// 	background: url(${cardBlack}) no-repeat center/cover;
// 	justify-content: flex-end;
// 	row-gap: 1rem;
// `;
const CardStatusContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	& svg {
		color: #ff0037;
		width: 8rem;
		height: 8rem;
	}
	& span {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 3rem;
		color: var(--white);
	}
`;
const CardContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
	align-items: center;
	& > div {
		width: 100%;
	}
`;
const CardInfoContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	border: 0.1rem solid var(--dark-gray);
	border-radius: 1rem;
	font-size: 1.6rem;
	font-weight: 600;
`;
