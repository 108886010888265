import { useEffect, useState } from 'react';
import Modal from '../../../../../../componentsV2/ui/Modal';
import styled from 'styled-components';
import { Button } from '../../../../../../componentsV2/ui/Button';
import { DIALOG_MODAL_CONTAINER_ID } from '../../../../../../contexts/DialogModalContext';
import { IoMdClose } from 'react-icons/io';

const ErrorMessages = {
	'file-invalid-type': {
		title: 'Formato de arquivo',
		message: 'Por favor escolha um dos formatos aceitos: JPEG, PNG ou PDF.',
	},
	'file-too-large': {
		title: 'Tamanho do arquivo',
		message: 'Por favor escolha um arquivo com no máximo 3MB.',
	},
};

interface Props {
	setErrors: (file: string | undefined) => void;
	error?: string;
}

export function UploadRejectionModal({ error, setErrors }: Props) {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		setIsOpen(!!error);
	}, [error]);

	function closeModal() {
		setErrors(undefined);
		setIsOpen(false);
	}

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={closeModal}
			position='center'
			parentId={DIALOG_MODAL_CONTAINER_ID}
		>
			<Container>
				<ErrorMessageContainer>
					<IconContainer>
						<IoMdClose color='#fff' size={30} />
					</IconContainer>

					{!!error && (
						<>
							<ErrorTitle>
								<p style={{ fontWeight: 600 }}>
									{
										ErrorMessages[
											error as 'file-invalid-type' | 'file-too-large'
										].title
									}
								</p>
								<p>&nbsp;não suportado</p>
							</ErrorTitle>
							<ErrorMessage>
								{
									ErrorMessages[error as 'file-invalid-type' | 'file-too-large']
										.message
								}
							</ErrorMessage>
						</>
					)}
				</ErrorMessageContainer>

				<CloseBttnContainer>
					<Button
						onClick={() => closeModal()}
						intent='terciary'
						roundness='lg'
						$outline
					>
						Fechar
					</Button>
				</CloseBttnContainer>
			</Container>
		</Modal>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 40rem;
	min-height: 30rem;
	padding: 3rem;
`;

const ErrorMessageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	row-gap: 0.8rem;
	font-size: 2rem;
`;

const ErrorTitle = styled.div`
	display: flex;

	& > p {
		font-size: 1.7rem;
	}
`;

const ErrorMessage = styled.p`
	text-align: center;
	width: 80%;
	font-size: 1.5rem;
	color: var(--gray);
	margin-top: 4rem;
`;

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--primary-red);
	width: 4rem;
	height: 4rem;
	border-radius: 50px;
`;

const CloseBttnContainer = styled.div`
	margin-top: auto;
`;
