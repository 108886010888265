import { useState } from 'react';
import Modal from '../../../../../../../componentsV2/ui/Modal';
import { Button } from '../../../../../../../componentsV2/ui/Button';
import * as S from './styles';
import Logo from '../../../../../../../assets/corpwayExtractReport/ControlLogo.png';
import Destiny from '../../../../../../../assets/corpwayExtractReport/destiny-arrow.svg';
import Origin from '../../../../../../../assets/corpwayExtractReport/origin-arrow.svg';
import { BalanceMovement } from '../../../../../../../services/queries/Corpway/Funds';
import { convertCentsToReais } from '../../../../../../../utils/CurrencyConvert';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { cnpjMask } from '../../../../../../../utils/masks';
import { maskCardNumber } from '../../../../../../../utils/formatCardNumber';

export interface TransferReceiptsModalProps {
	movement: BalanceMovement;
	movementType: string;
}

export function TransferReceiptsModal({
	movement,
	movementType,
}: TransferReceiptsModalProps) {
	const [isOpen, setOpen] = useState(false);

	function handleCloseModal() {
		setOpen(false);
	}

	function cloneModalContentForPDF() {
		const originalContent = document.getElementById('pdf-content');
		if (originalContent) {
			const clonedContent = originalContent.cloneNode(true) as HTMLElement;

			const buttons = clonedContent.querySelectorAll('.pdf-hide');
			buttons.forEach((button) => button.remove());

			return clonedContent;
		}
		return null;
	}

	function handleDownloadPDF() {
		const clonedContent = cloneModalContentForPDF();

		if (clonedContent) {
			document.body.appendChild(clonedContent);

			html2canvas(clonedContent, { scale: 4 }).then((canvas) => {
				const imgData = canvas.toDataURL('image/JPEG', 2);
				const pdf = new jsPDF();
				const imgWidth = 210;
				const pageHeight = 297;

				const imgHeight = (canvas.height * imgWidth) / canvas.width;

				if (imgHeight > pageHeight) {
					const scaleFactor = pageHeight / imgHeight;
					const scaledWidth = imgWidth * scaleFactor;
					const scaledHeight = imgHeight * scaleFactor;
					pdf.addImage(
						imgData,
						'JPEG',
						(imgWidth - scaledWidth) / 2,
						0,
						scaledWidth,
						scaledHeight
					);
				} else {
					pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
				}

				const suffix = getFileSuffix();
				const fileName = getPDFFileName(suffix);

				pdf.save(fileName);

				document.body.removeChild(clonedContent);
			});
		}
	}

	function getFileSuffix() {
		let suffix = '';

		switch (movementType) {
			case 'Transferência externa':
				suffix = 'externa';
				break;
			case 'Recarga de carteira':
				suffix = `${movement.company?.name
					.replace(/ /g, '_')
					.toLowerCase()}_recarga_carteira`
					.replace(/ /g, '_')
					.toLowerCase();
				break;
			case 'Repasse para cartão':
				suffix = `carteira_${movement.card?.pan?.slice(-3)}_cartao_`;
				break;
			case 'Estorno para carteira':
				suffix = `cartao_${movement.card?.pan?.slice(-3)}_carteira_`;
				break;
		}

		return suffix;
	}

	function getPDFFileName(suffix: string) {
		const date = new Date(movement.created_at!)
			.toLocaleDateString('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			})
			.replace(/\//g, '_');

		const fileName = `Comprovante_transferencia_${suffix}_${date}.pdf`;

		return fileName;
	}

	const RenderContent = () => {
		switch (movementType) {
			case 'Transferência externa':
				return (
					<>
						<S.InfoContainer>
							<S.InfoHeader>
								<img src={Destiny} alt='' />
								<h2>Destino:</h2>
							</S.InfoHeader>
							<S.InfoContent>
								<S.Label>
									<p>Razão social:</p>
									<p>CNPJ:</p>
									<p>Banco:</p>
									<p>Tipo de conta:</p>
									<p>Agência:</p>
									<p>Conta:</p>
								</S.Label>
								<S.Value>
									<p>Razão social:</p>
									<p>CNPJ:</p>
									<p>Banco:</p>
									<p>Tipo de conta:</p>
									<p>Agência:</p>
									<p>Conta:</p>
								</S.Value>
							</S.InfoContent>
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoHeader>
								<img src={Origin} alt='' />
								<h2>Origem:</h2>
							</S.InfoHeader>
							<S.InfoContent>
								<S.Label>
									<p>Razão social:</p>
									<p>CNPJ:</p>
									<p>Banco:</p>
									<p>Tipo de conta:</p>
								</S.Label>
								<S.Value>
									<p>Razão social:</p>
									<p>{cnpjMask(movement.company?.cnpj!)}</p>
									<p>Banco:</p>
									<p>Tipo de conta:</p>
								</S.Value>
							</S.InfoContent>
						</S.InfoContainer>
					</>
				);

			case 'Recarga de carteira':
				return (
					<>
						<S.InfoContainer>
							<S.InfoHeader>
								<img src={Destiny} alt='' />
								<h2>Destino:</h2>
							</S.InfoHeader>
							<S.InfoContent>
								<S.Label>
									<p>Razão social:</p>
									<p>CNPJ:</p>
								</S.Label>
								<S.Value>
									<p>{movement.company?.name}</p>
									<p>{cnpjMask(movement.company?.cnpj!)}</p>
								</S.Value>
							</S.InfoContent>
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoHeader>
								<img src={Origin} alt='' />
								<h2>Origem:</h2>
							</S.InfoHeader>
							<S.InfoContent>
								<S.Label>
									<p>Razão social:</p>
									<p>CNPJ:</p>
								</S.Label>
								<S.Value>
									<p>{movement.company?.name}</p>
									<p>{cnpjMask(movement.company?.cnpj!)}</p>
								</S.Value>
							</S.InfoContent>
						</S.InfoContainer>
					</>
				);
			case 'Repasse para cartão':
				return (
					<>
						<S.InfoContainer>
							<S.InfoHeader>
								<img src={Destiny} alt='' />
								<h2>Destino:</h2>
							</S.InfoHeader>
							<S.InfoContent>
								<S.Label>
									<p>Número do cartão:</p>
								</S.Label>
								<S.Value>
									<p>{maskCardNumber(movement.card?.pan!)}</p>
								</S.Value>
							</S.InfoContent>
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoHeader>
								<img src={Origin} alt='' />
								<h2>Origem:</h2>
							</S.InfoHeader>
							<S.InfoContent>
								<S.Label>
									<p>Razão social:</p>
									<p>CNPJ:</p>
								</S.Label>
								<S.Value>
									<p>{movement.company?.name}</p>
									<p>{cnpjMask(movement.company?.cnpj!)}</p>
								</S.Value>
							</S.InfoContent>
						</S.InfoContainer>
					</>
				);
			case 'Estorno para carteira':
				return (
					<>
						<S.InfoContainer>
							<S.InfoHeader>
								<img src={Destiny} alt='' />
								<h2>Destino:</h2>
							</S.InfoHeader>
							<S.InfoContent>
								<S.Label>
									<p>Razão social:</p>
									<p>CNPJ:</p>
								</S.Label>
								<S.Value>
									<p>{movement.company?.name}</p>
									<p>{cnpjMask(movement.company?.cnpj!)}</p>
								</S.Value>
							</S.InfoContent>
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoHeader>
								<img src={Origin} alt='' />
								<h2>Origem:</h2>
							</S.InfoHeader>
							<S.InfoContent>
								<S.Label>
									<p>Número do cartão:</p>
								</S.Label>
								<S.Value>
									<p>{movement.card?.pan}</p>
								</S.Value>
							</S.InfoContent>
						</S.InfoContainer>
					</>
				);
		}
	};

	return (
		<>
			<Button
				style={{
					borderRadius: '4rem',
					justifyContent: 'center',
					display: 'flex',
				}}
				onClick={() => setOpen(true)}
			>
				Ver comprovante
			</Button>

			<Modal
				isOpen={isOpen}
				onRequestClose={handleCloseModal}
				position='center'
			>
				<S.Container id='pdf-content'>
					<S.Header>
						<img src={Logo} alt='Logo bounty' />
						<h2>Comprovante de transferência</h2>
						<p>
							{new Date(movement.created_at)
								.toLocaleDateString('pt-BR', {
									day: '2-digit',
									month: 'short',
									year: 'numeric',
								})
								.replace('.', '')
								.replace(' de ', ' ')
								.toUpperCase()}{' '}
							-
							{new Date(movement.created_at).toLocaleTimeString('pt-BR', {
								hour12: false,
								hour: '2-digit',
								minute: '2-digit',
								second: '2-digit',
							})}
						</p>
					</S.Header>

					<S.SubHeader>
						<S.Label style={{ fontSize: '1.4rem' }}>
							<p>Valor:</p>
							<p>Tipo de transferência:</p>
						</S.Label>
						<S.Value style={{ fontSize: '1.4rem' }}>
							<S.Currency>{convertCentsToReais(movement.amount)}</S.Currency>
							<p style={{ fontWeight: 600 }}>{movementType}</p>
						</S.Value>
					</S.SubHeader>

					{RenderContent()}

					<S.ButtonContainer>
						<Button
							className='pdf-hide'
							intent='terciary'
							onClick={() => {
								setOpen(false);
							}}
							$outline
						>
							Voltar
						</Button>
						<Button
							className='pdf-hide'
							intent='primary'
							onClick={handleDownloadPDF}
						>
							Baixar anexo
						</Button>
					</S.ButtonContainer>
				</S.Container>
			</Modal>
		</>
	);
}
