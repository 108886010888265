import { RefundValueHistory } from '../../../../@types';
import { EmptyContent } from '../../../../components/EmptyContent';
import PageTitle from '../../../../components/PageTitle';
import { convertCentsToReais } from '../../../../utils/CurrencyConvert';
import { parseDate } from '../../../../utils/parseDate';
import * as S from './styles';

interface Props {
	valueHistory: RefundValueHistory[];
}
export function RefundValueHistoryLogs({ valueHistory }: Props) {
	return (
		<div>
			<PageTitle title='Histórico de edições de valor' />

			<S.ChangesList>
				{valueHistory.map((log) => (
					<S.Change key={log.id}>
						<span>{log.operator_editor.name}</span> alterou o valor de{' '}
						<span>{convertCentsToReais(Number(log.original_value))}</span> para{' '}
						<span>{convertCentsToReais(Number(log.new_value))}</span> em{' '}
						<span>{parseDate(log.created_at, 2)}</span>
					</S.Change>
				))}
				{valueHistory.length === 0 && (
					<EmptyContent text='Histórico de edições vazio' />
				)}
			</S.ChangesList>
		</div>
	);
}
