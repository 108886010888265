import * as S from './styles';
import * as TableStyle from '../../../components/Table/TableStyles';
import { useAuth } from '../../../hooks/useAuth';
import { Transfer } from '../../../@types';
import PageTitle from '../../../components/PageTitle';
import { Filter } from '../../../components/Filter';
import { useEffect, useState } from 'react';
import { Pagination } from '../../../components/Pagination';
import { useQuery, useQueryClient } from 'react-query';
import {
	getTransfersV2,
	TransfersListReturn,
} from '../../../services/queries/Tranfers';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import Loader from '../../../components/Loader';
import { EmptyContent } from '../../../components/EmptyContent';
import UserCard from '../../../components/UserCard';
import { convertCentsToReais } from '../../../utils/CurrencyConvert';
import { MdNotes } from 'react-icons/md';
import { parseDate } from '../../../utils/parseDate';
import { getBenefitParsedTitle } from '../../../utils/benefits/getBenefitParsedTitle';
import {
	getTransferDetailsLink,
	parseTransferCategory,
	parseTransferType,
} from './utils/parseTransferData';
import { ReportGenerator } from './ReportGenerator';
import { useTransfersListStore } from '../../../stores/useTransfersListStore';
import { useHistory, useLocation } from 'react-router-dom';
import { useSortColumnHook } from '../../../hooks/useSortColumnHook';
import { SortColumnButton } from '../../../components/SortColumnButton';

export function TransfersV2() {
	const { currentCompany } = useAuth();
	const queryClient = useQueryClient();
	const history = useHistory();
	const location = useLocation();
	const { currentSortColumn, toggleSort } = useSortColumnHook();
	const [transfersCurrentPage, setTransfersCurrentPage] = useState(1);
	const [showingTable, setShowingTable] = useState(false);

	const [filtersParams, setFiltersParams, resetFilters] = useTransfersListStore(
		(state) => [state.filtersParams, state.setFiltersParams, state.resetFilters]
	);

	useEffect(() => {
		let lastChangedRoute: string | null = null;
		const transfersPathname = location.pathname;

		const unlisten = history.listen((l, _) => {
			const state = l.state as { fromTransfersPage: boolean } | undefined;

			// the user has changed route from one of Transfers pages links
			if (lastChangedRoute === null && state?.fromTransfersPage) {
				// save this route
				lastChangedRoute = l.pathname;
				return;
			}

			// the user went from lastChangedRoute to a different page
			if (l.pathname !== transfersPathname) {
				unlisten();
				resetFilters();
			}
			// the user went to lastChangedRoute and returned, reset lastChangedRoute
			else if (l.pathname === transfersPathname) {
				lastChangedRoute = null;
			}
		});
	}, [history, resetFilters, location.pathname]);

	function refreshPage() {
		setTransfersCurrentPage(1);
		queryClient.resetQueries('transfersList');
	}

	const fetchTransfers = useQuery<TransfersListReturn, Error>(
		[
			'transfersList',
			currentCompany?.id,
			filtersParams,
			transfersCurrentPage,
			currentSortColumn,
		],
		() => {
			return getTransfersV2(
				filtersParams,
				currentCompany?.id!,
				transfersCurrentPage,
				currentSortColumn
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar o histórico de transferências. '
				);
			},
		}
	);

	function getTotalValues() {
		if (filtersParams.find((f) => f.value)) {
			const filterType = filtersParams.find((f) => f.filter === 'type');

			if (filterType?.selected) {
				if (filterType.value === 'entrance') {
					return (
						<S.TotalValuesContainer>
							<S.TotalValue>
								<S.TotalValueLabel>Valor total de entradas:</S.TotalValueLabel>
								<S.TotalValueNumber>
									{convertCentsToReais(
										Number(fetchTransfers.data!.entrances_totalValue)
									)}
								</S.TotalValueNumber>
							</S.TotalValue>
						</S.TotalValuesContainer>
					);
				} else {
					return (
						<S.TotalValuesContainer>
							<S.TotalValue>
								<S.TotalValueLabel>Valor total de saídas:</S.TotalValueLabel>
								<S.TotalValueNumber>
									{convertCentsToReais(
										Number(fetchTransfers.data!.exits_totalValue)
									)}
								</S.TotalValueNumber>
							</S.TotalValue>
						</S.TotalValuesContainer>
					);
				}
			} else {
				return (
					<S.TotalValuesContainer>
						<S.TotalValue>
							<S.TotalValueLabel>Valor total de entradas:</S.TotalValueLabel>
							<S.TotalValueNumber>
								{convertCentsToReais(
									Number(fetchTransfers.data!.entrances_totalValue)
								)}
							</S.TotalValueNumber>
						</S.TotalValue>
						<S.TotalValue>
							<S.TotalValueLabel>Valor total de saídas:</S.TotalValueLabel>
							<S.TotalValueNumber>
								{convertCentsToReais(
									Number(fetchTransfers.data!.exits_totalValue)
								)}
							</S.TotalValueNumber>
						</S.TotalValue>
					</S.TotalValuesContainer>
				);
			}
		}
	}

	function generateRow(transfer: Transfer) {
		return (
			<TableStyle.TableRow key={transfer.id}>
				<TableStyle.TableData style={{ minWidth: '12rem' }}>
					{parseTransferType(transfer.type)}
				</TableStyle.TableData>
				<TableStyle.TableData style={{ minWidth: '12rem' }}>
					{parseDate(transfer.created_at, 1)}
				</TableStyle.TableData>
				<TableStyle.TableData style={{ minWidth: '20rem' }}>
					{parseTransferCategory(transfer.category)}
				</TableStyle.TableData>
				<TableStyle.TableData style={{ minWidth: '12rem' }}>
					{convertCentsToReais(Number(transfer.amount))}
				</TableStyle.TableData>
				<TableStyle.TableData style={{ minWidth: '15rem' }}>
					{transfer.balance ? getBenefitParsedTitle(transfer.balance) : 'Saldo'}
				</TableStyle.TableData>
				<TableStyle.TableData style={{ minWidth: '28rem', overflow: 'auto' }}>
					{transfer.collaborator ? (
						<UserCard
							name={`${transfer.collaborator.first_name} ${transfer.collaborator.last_name}`}
							bottomInfo={transfer.collaborator.office}
							avatar_url={transfer.collaborator.avatar_url}
						/>
					) : (
						'N/A'
					)}
				</TableStyle.TableData>
				<TableStyle.TableData>
					{transfer.release_id ? (
						<TableStyle.OptionLink
							data-rh='Detalhamento'
							to={{
								pathname:
									getTransferDetailsLink(
										transfer.category,
										transfer.release_id
									) ?? '#',
								state: { fromTransfersPage: true },
							}}
						>
							<MdNotes />
						</TableStyle.OptionLink>
					) : (
						<TableStyle.OptionButton disabled>
							<MdNotes />
						</TableStyle.OptionButton>
					)}
				</TableStyle.TableData>
			</TableStyle.TableRow>
		);
	}

	function getTable(transfers?: Transfer[]) {
		if (!transfers?.length) {
			return (
				<>
					<TableStyle.TableHeaderContainer>
						<TableStyle.TitleWrapper>
							<PageTitle
								title='Histórico'
								totalRecords={fetchTransfers.data?.totalTransfers}
							/>
							<Filter
								filterParams={filtersParams}
								onFiltersChanged={(updatedFilters) => {
									setTransfersCurrentPage(1);
									setFiltersParams(updatedFilters);
								}}
							/>
						</TableStyle.TitleWrapper>
						<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
					</TableStyle.TableHeaderContainer>

					<EmptyContent text='Histórico de transferências vazio.' />
				</>
			);
		}

		return (
			<>
				<TableStyle.TableHeaderContainer>
					<TableStyle.TitleWrapper>
						<PageTitle
							title='Histórico'
							totalRecords={fetchTransfers.data?.totalTransfers}
						/>
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setTransfersCurrentPage(1);
								setFiltersParams(updatedFilters);
							}}
						/>
						{showingTable ? (
							<TableStyle.SeeLess onClick={() => setShowingTable(false)}>
								Ver menos
							</TableStyle.SeeLess>
						) : (
							<TableStyle.SeeMore onClick={() => setShowingTable(true)}>
								Ver mais
							</TableStyle.SeeMore>
						)}
					</TableStyle.TitleWrapper>
					<Pagination
						onPageChange={(page) => setTransfersCurrentPage(page)}
						currentPage={transfersCurrentPage}
						totalCount={
							fetchTransfers.data ? fetchTransfers.data.totalTransfers : 1
						}
					/>
					<TableStyle.ReloadIcon type='reload' onClick={refreshPage} />
				</TableStyle.TableHeaderContainer>

				<TableStyle.Table>
					<TableStyle.TableHeader>
						<TableStyle.TableRow>
							<TableStyle.TableHeaderCell>TIPO</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'created_at'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('created_at')}
								/>
								DATA
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>CATEGORIA</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'amount'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('amount')}
								/>
								VALOR
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>CARTEIRA</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'name'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('name')}
								/>
								COLABORADOR
							</TableStyle.TableHeaderCell>
							<TableStyle.TableHeaderCell>OPÇÕES</TableStyle.TableHeaderCell>
						</TableStyle.TableRow>
					</TableStyle.TableHeader>

					<TableStyle.TableBody>
						{showingTable
							? transfers.map((transfer) => generateRow(transfer))
							: generateRow(transfers[0])}
					</TableStyle.TableBody>
				</TableStyle.Table>
			</>
		);
	}

	if (
		!fetchTransfers.data ||
		fetchTransfers.isLoading ||
		fetchTransfers.isPreviousData
	) {
		return (
			<S.Container>
				<PageTitle title='Histórico' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			{getTable(fetchTransfers.data.transfers)}

			{getTotalValues()}

			<S.ButtonsContainer>
				{filtersParams.find((f) => f.value) && (
					<ReportGenerator
						company_id={currentCompany?.id!}
						filterParams={filtersParams}
						currentSortColumn={currentSortColumn}
					/>
				)}
			</S.ButtonsContainer>
		</S.Container>
	);
}
