import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	useReactTable,
} from '@tanstack/react-table';
import { Props } from '..';
import { Typography } from '../../../../../../componentsV2/ui/Typography';
import * as S from '../styles';
import { BalanceMovement } from '../../../../../../services/queries/Corpway/Funds';
import {
	parseMovementStatus,
	parseMovementType,
} from '../../../../Management/utils/parseBalanceMovements';
import { convertCentsToFormattedReais } from '../../../../../../utils/CurrencyConvert';
import { formatCardNumber } from '../../../../../../utils/formatCardNumber';

const columnHelper = createColumnHelper<BalanceMovement>();
const columns = [
	columnHelper.accessor('created_at', {
		cell: (info) => {
			const date = new Date(info.getValue());
			const day = String(date.getDate()).padStart(2, '0');
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const year = String(date.getFullYear()).slice(-2);
			return `${day}/${month}/${year}`;
		},
		header: 'Data',
	}),
	columnHelper.accessor((row) => row, {
		cell: (info) => convertCentsToFormattedReais(info.getValue().amount),
		header: 'Valor',
	}),
	columnHelper.accessor('status', {
		cell: (info) => parseMovementStatus(info.getValue()!),
		header: 'Status',
	}),
];

export function SecondaryContent({ movement }: Props) {
	const movementType = parseMovementType(movement.type, movement.action);

	function getMovementDetails() {
		if (movementType === 'Transferência externa') {
			return <></>;
		}

		if (movementType === 'Recarga de carteira') {
			return (
				<>
					<S.SubTitleRow>
						<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
							Destalhes da transação:
						</Typography>
					</S.SubTitleRow>

					<S.FieldsContainer style={{ marginTop: '8px' }}>
						<S.Field>
							<Typography color='var(--dark-gray)'>Operação via:</Typography>
							<Typography color='var(--dark-gray)'>
								{movement.type === 'pix' ? 'Pix' : 'Boleto'}
							</Typography>
						</S.Field>

						<S.Field>
							<Typography color='var(--dark-gray)'>Destino:</Typography>
							<Typography color='var(--dark-gray)'>Carteira</Typography>
						</S.Field>
					</S.FieldsContainer>
				</>
			);
		}

		if (
			movementType === 'Estorno para carteira' ||
			movementType === 'Repasse para cartão'
		) {
			return (
				<>
					<S.SubTitleRow>
						<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
							Destalhes do cartão de{' '}
							{movementType === 'Estorno para carteira' ? 'origem' : 'destino'}:
						</Typography>
					</S.SubTitleRow>

					<S.FieldsContainer style={{ marginTop: '8px' }}>
						<S.Field>
							<Typography color='var(--dark-gray)'>Nome do cartão:</Typography>
							<Typography color='var(--dark-gray)'>
								{movement.card?.alias}
							</Typography>
						</S.Field>

						<S.Field>
							<Typography color='var(--dark-gray)'>
								Número do cartão:
							</Typography>
							<Typography color='var(--dark-gray)'>
								{formatCardNumber(movement.card?.pan!)}
							</Typography>
						</S.Field>

						<S.Field>
							<Typography color='var(--dark-gray)'>Centro de custo:</Typography>
							<Typography color='var(--dark-gray)'>
								{movement.card?.cost_center}
							</Typography>
						</S.Field>
					</S.FieldsContainer>
				</>
			);
		}
	}

	function getMovementHistory(): BalanceMovement[] {
		if (movementType === 'Transferência externa') {
			return [];
		}

		if (movement.status === 'created') {
			return [
				{
					created_at: movement.created_at,
					amount: movement.amount,
					status: movement.status,
				} as BalanceMovement,
			];
		}

		return [
			{
				created_at: movement.created_at,
				amount: movement.amount,
				status: 'created',
			} as BalanceMovement,
			{
				created_at:
					movement.type === 'billing' && movement.status === 'paid'
						? movement.payment_date
						: movement.updated_at,
				amount:
					movement.type === 'billing' && movement.status === 'paid'
						? movement.amount_paid
						: movement.amount,
				status: movement.status,
			} as BalanceMovement,
		];
	}

	const table = useReactTable({
		data: getMovementHistory() ?? [],
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	const hasItems = table.getRowModel().rows.length > 0;

	return (
		<>
			<S.SubTitleRow>
				<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
					Histórico da transação:
				</Typography>
			</S.SubTitleRow>

			<S.SectionContainerStyle>
				<table>
					<thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<th key={header.id}>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext()
											  )}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody>
						{hasItems ? (
							table.getRowModel().rows.map((row) => (
								<tr key={row.id}>
									{row.getVisibleCells().map((cell) => (
										<td key={cell.id}>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</td>
									))}
								</tr>
							))
						) : (
							<>
								<tr>
									<td
										colSpan={table.getAllColumns().length}
										style={{
											textAlign: 'center',
											padding: '7rem',
											paddingBottom: '9rem',
										}}
									>
										<p
											style={{
												margin: 0,
												fontFamily: 'Poppins, sans-serif',
												fontSize: '1.4rem',
												color: '#575757;',
											}}
										>
											Não há itens a serem exibidos.
										</p>
									</td>
								</tr>
								<div className='table-footer'></div>
							</>
						)}
					</tbody>
				</table>
			</S.SectionContainerStyle>

			{getMovementDetails()}
		</>
	);
}
