import * as Excel from 'exceljs';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../../../hooks/useAuth';
import { getCardExtractByPeriodToReport } from '../../../../../../services/queries/Corpway/Extract';
import {
	convertCentsToCurrency,
	convertCentsToFormattedReais,
	convertCentsToPlainReais,
} from '../../../../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../../../../utils/ErrorHandler';
import {
	parseExtractTransactionStatus,
	parseTransactionType,
} from '../../../utils/transactionUtils';
import { Card } from '../../../../../../@types/CorporateExpenses/Card';
import { Props } from './PDFGenerator';
import { ReportProps } from '..';
import { cnpjMask } from '../../../../../../utils/masks';
import { maskCardNumber } from '../../../../../../utils/formatCardNumber';
import {
	calculateCreditValue,
	calculateDebitValue,
} from './utils/transactionUtilsToReport';
import { parseCurrencyStrToNumber } from '../../../../../../utils/parseCurrency';

export function SpreadsheetReportGenerator() {
	const { user, currentCompany } = useAuth();

	const getExtractQuery = useMutation(
		({ card, start_date, end_date, filters }: Props) =>
			getCardExtractByPeriodToReport(
				card!.id,
				currentCompany!.id,
				start_date,
				end_date,
				filters
			),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar as transações para o relatório.'
				);
			},
		}
	);

	async function handleGenerateXLSX({
		card,
		start_date,
		end_date,
		filters,
	}: Props) {
		try {
			let blob: Blob;
			blob = await generateSheetContent(card!, start_date, end_date, filters);

			const startDate = new Date(start_date);
			const endDate = new Date(end_date);

			// Create a temporary URL for the blob
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = `Bounty_Control_${card?.alias}_${card?.pan.slice(
				-4
			)}_${startDate.toLocaleDateString('pt-BR')}_${endDate.toLocaleDateString(
				'pt-BR'
			)}.xlsx`;

			// Simulate a click event to trigger the download
			link.dispatchEvent(new MouseEvent('click'));

			// Clean up the temporary URL
			URL.revokeObjectURL(url);
		} catch (error: any) {
			console.log(error);
			if (error.message === 'empty_transactions') {
				throw error; // let parent handle
			}
			toast.error(
				'Ocorreu um erro ao tentar gerar o arquivo de relatório. Tente novamente'
			);
		}
	}

	async function generateSheetContent(
		card: Card,
		start_date: number,
		end_date: number,
		filters: ReportProps['filters']
	) {
		const workbook = new Excel.Workbook();

		const worksheet = workbook.addWorksheet('Relatório de boletos');

		const transactions = await getExtractQuery.mutateAsync({
			card,
			start_date,
			end_date,
			filters,
		});

		if (transactions.transactions.length === 0) {
			throw new Error('empty_transactions');
		}

		const currentTime = new Date();
		worksheet.addRow([
			`Gerado em ${currentTime.toLocaleDateString()} às ${currentTime.toLocaleTimeString(
				'pt-BR',
				{
					timeZone: 'America/Sao_Paulo',
					hour: '2-digit',
					minute: '2-digit',
				}
			)} no Bounty Control.`,
		]);
		worksheet.addRow([`Usuário: ${user.email}.`]);
		worksheet.addRow([
			`Intervalo de busca: ${Intl.DateTimeFormat('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			}).format(new Date(start_date))} - ${Intl.DateTimeFormat('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			}).format(new Date(end_date))}`,
		]);
		worksheet.addRow([`N° de pedido: ${transactions.totalExtracts}`]);

		worksheet.addRow([]);
		worksheet.addRow([`Empresa: ${currentCompany!.corporateName}`]);
		worksheet.addRow([`CNPJ: ${cnpjMask(currentCompany!.cnpj)}`]);
		worksheet.addRow([`Cartão: ${card.alias}`]);
		worksheet.addRow([`Pan: ${maskCardNumber(card.pan)}`]);
		worksheet.addRow([`Responsável: ${card.user_data?.name}`]);
		worksheet.addRows([[], [], [], []]); //Space for total value that is added afterwards

		let columns = [
			'DATA',
			'STATUS',
			'LANÇAMENTO',
			'ESTABELECIMENTO',
			'VALOR',
			'VALOR ORIGINAL',
			'ANEXO 1',
			'ANEXO 2',
			'OBSERVAÇÃO',
		];

		// list headers
		worksheet.addRow(columns);

		const columnsIndexes = [1, 2, 3, 4, 5, 6, 7, 8, 9];

		columnsIndexes.forEach((colIndex) => {
			const col = worksheet.getColumn(colIndex);
			col.width = 25;
		});

		worksheet.getColumn(5).numFmt = '[$R$ ]#,##0.00'; // Valor column formatting

		transactions.transactions.forEach((transaction) => {
			let transactionInvoice1: string | null = null;
			let transactionInvoice2: string | null = null;

			if (
				transaction.transactionInvoices &&
				transaction.transactionInvoices.invoices_urls.length > 0
			) {
				transaction.transactionInvoices.invoices_urls.forEach(
					(invoice: any, index: number) => {
						const invoiceUrl =
							typeof invoice === 'string' ? invoice : invoice.url;

						if (index === 0) {
							transactionInvoice1 = invoiceUrl;
						} else if (index === 1) {
							transactionInvoice2 = invoiceUrl;
						}
					}
				);
			}

			const row = [
				new Date(transaction.added_time).toLocaleString(),
				parseExtractTransactionStatus(
					transaction.msg_type,
					transaction.txn_type,
					transaction.response_code,
					transaction.cleared,
					transaction.clearing_state,
					transaction.financial_impact_type
				),
				parseTransactionType(
					transaction.txn_type,
					transaction.msg_type,
					transaction.currency,
					transaction.merchant_country
				),
				transaction.merchant_name || 'N/A',
				parseCurrencyStrToNumber(
					convertCentsToPlainReais(transaction.billing_amount)
				),
				transaction.currency !== 986
					? convertCentsToCurrency(
							String(transaction.currency),
							transaction.txn_amount
					  )
					: '-',
				transactionInvoice1,
				transactionInvoice2,
				transaction.transactionInvoices?.observation,
			];
			const addedRow = worksheet.addRow(row);

			if (
				transaction.transactionInvoices?.invoices_urls &&
				transaction.transactionInvoices?.invoices_urls.length > 0
			) {
				const link1Cell = addedRow.getCell(7);
				const link2Cell = addedRow.getCell(8);

				if (transaction.transactionInvoices?.invoices[0]) {
					const [_, ...invoiceName] =
						transaction.transactionInvoices?.invoices[0].split('-');
					link1Cell.value = {
						text: invoiceName.join(),
						hyperlink: transactionInvoice1!,
					};
					link1Cell.font = {
						underline: 'single',
						color: { argb: 'ff0000ff' },
					};
				}

				if (transaction.transactionInvoices?.invoices[1]) {
					const [_, ...invoiceName] =
						transaction.transactionInvoices?.invoices[1].split('-');
					link2Cell.value = {
						text: invoiceName.join(),
						hyperlink: transactionInvoice2!,
					};
					link2Cell.font = {
						underline: 'single',
						color: { argb: 'ff0000ff' },
					};
				}
			}
		});

		const entrancesValue = calculateCreditValue(transactions.transactions);
		let entrancesValueRow = worksheet.getRow(12);
		entrancesValueRow.values = [
			`Crédito: ${convertCentsToFormattedReais(entrancesValue)}`,
		];

		const debitValue = calculateDebitValue(transactions.transactions);
		let debitValueRow = worksheet.getRow(13);
		debitValueRow.values = [
			`Débito: ${convertCentsToFormattedReais(debitValue)}`,
		];

		const buffer = await workbook.xlsx.writeBuffer();
		const fileType =
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

		const blob = new Blob([buffer], { type: fileType });

		return blob;
	}

	return {
		generateSpreadsheet: handleGenerateXLSX,
		loading: getExtractQuery.isLoading,
	};
}
