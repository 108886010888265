import { Release } from '../../@types';
import PageTitle from '../PageTitle';
import { parseDate } from '../../utils/parseDate';
import {
	getStatusIcon,
	parseStatusString,
} from '../../utils/parsePaymentStatus';
import * as S from './styles';
import { convertCentsToReais } from '../../utils/CurrencyConvert';

interface Props {
	release: Release;
	title: string;
}
export function PaymentHeader(props: Props) {
	return (
		<S.Header>
			<S.HeaderTitle>
				<PageTitle title={props.title} />
				{getStatusIcon(props.release.release.status)}

				{parseStatusString(props.release.release.status)}
			</S.HeaderTitle>
			<S.HeaderInfo>
				<S.InfoContainer>
					<S.InfoTitle>ID</S.InfoTitle>
					{props.release.release.order_id ?? 'N/A'}
				</S.InfoContainer>
				<S.InfoContainer>
					{parseDate(
						props.release.release.createdAt,
						1,
						Number(props.release.release.referenceMonth)
					)}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Valor Total</S.InfoTitle>
					{convertCentsToReais(Number(props.release.release.totalValue!))}
				</S.InfoContainer>
				{props.release.release.scheduledDate && (
					<S.InfoContainer>
						<S.InfoTitle>Agendado</S.InfoTitle>
						{new Date(props.release.release.scheduledDate)
							.toLocaleString()
							.replace(' ', ' às ')}
					</S.InfoContainer>
				)}
				{props.release.release.preReleaseCount && (
					<S.InfoContainer>
						<S.InfoTitle>qtde Pagamentos</S.InfoTitle>
						{props.release.release.preReleaseCountWithStatus} /{' '}
						{props.release.release.preReleaseCount}
					</S.InfoContainer>
				)}
				{props.release.release.collaboratorCount && (
					<S.InfoContainer>
						<S.InfoTitle>qtde Colaboradores</S.InfoTitle>
						{props.release.release.collaboratorCount}
					</S.InfoContainer>
				)}
				{props.release.release.userOperatorCanceling && (
					<S.InfoContainer>
						<S.InfoTitle>Cancelado por</S.InfoTitle>
						{props.release.release.userOperatorCanceling.name}
					</S.InfoContainer>
				)}
				{props.release.release.reasonDisapproval && (
					<S.InfoContainer>
						<S.InfoTitle>Motivo do cancelamento</S.InfoTitle>
						{props.release.release.reasonDisapproval}
					</S.InfoContainer>
				)}
				{props.release.release.total_chargeback && (
					<S.InfoContainer>
						<S.InfoTitle>Valor total estornado</S.InfoTitle>
						{convertCentsToReais(
							Number(props.release.release.total_chargeback)
						)}
					</S.InfoContainer>
				)}
			</S.HeaderInfo>
		</S.Header>
	);
}
