import { useQuery } from 'react-query';
import { getAntiFraud } from '../../../../services/queries/Terms';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { SectionContainer } from '../../../../componentsV2/ui/SectionContainer';
import { Breadcrumb } from '../../../../componentsV2/BreadCrumb';
import { TitleTypography } from '../../../../componentsV2/ui/Typography';
import styled from 'styled-components';
import { EmptyContent } from '../../../../componentsV2/EmptyContent';

const AntiFraudPolicy = () => {
	const AntiFraudPolicyQuery = useQuery(
		['antiFraudPolicy'],
		() => {
			return getAntiFraud();
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar as políticas de antifraude. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	if (!AntiFraudPolicyQuery.data || AntiFraudPolicyQuery.isLoading) {
		return (
			<div>
				<SectionContainer>
					<Breadcrumb
						routes={[
							{ title: 'Suporte', route: '/corporate-expenses/support' },
							{
								title: 'Política Antifraude',
								route: '/corporate-expenses/support/anti-fraude-policy',
							},
						]}
					/>
					<TitleTypography image>Política Antifraude</TitleTypography>
				</SectionContainer>

				<EmptyContent />
			</div>
		);
	}

	return (
		<div>
			<SectionContainer>
				<Breadcrumb
					routes={[
						{ title: 'Suporte', route: '/corporate-expenses/support' },
						{
							title: 'Política Antifraude',
							route: '/corporate-expenses/support/anti-fraude-policy',
						},
					]}
				/>
				<TitleTypography image>Política Antifraude</TitleTypography>
			</SectionContainer>

			<TermsContainer
				dangerouslySetInnerHTML={{
					__html: AntiFraudPolicyQuery.data ? AntiFraudPolicyQuery.data : '',
				}}
			/>
		</div>
	);
};

export default AntiFraudPolicy;

export const TermsContainer = styled.div`
	text-align: justify;
	padding: 2rem 4rem;
	margin-top: 1.5rem;
	margin-bottom: 3.5rem;
	width: 100%;
	background-color: white;
	border-radius: 10px;
	font-size: 1.4rem;

	& > table,
	td {
		text-align: start;
		padding: 3rem 2rem;
		margin-top: 3rem;
		margin-bottom: 3rem;
		border-collapse: collapse;
	}

	tr:not(:first-of-type) {
		td {
			position: relative;
			width: 40%;
			padding: 2rem 3rem;
			border: 0.2px solid #b5bac3;

			strong {
				display: flex;
				position: absolute;
				background: #dbe1e9;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				align-items: center;
				justify-content: center;
			}
		}
	}

	tr:not(:first-of-type):nth-child(3n + 1) td strong {
		background-color: #d6dbe3;
	}

	table:first-of-type {
		tr:first-child {
			background-color: var(--dark-gray);
			height: 7.5rem;

			td:nth-child(1) {
				border-radius: 1rem 0 0 0;
				text-align: center;
			}

			td:nth-last-child(1) {
				border-radius: 0 1rem 0 0;
				text-align: center;
			}

			p {
				color: white;
			}
		}
		tr:nth-child(2n + 2) td {
			background-color: #e5eaf2;
		}
		tr:nth-child(2n + 3) td {
			background-color: ##f5f9ff;
		}
	}

	table:not(:first-of-type) {
		tr:nth-child(3n + 1) th {
			background-color: #e9eef6;
		}

		tr:nth-child(3n + 2) th {
			background-color: #e5eaf2;
		}

		tr:nth-child(3n + 3) th {
			background-color: #d6dbe3;
		}
	}

	tr {
		p {
			font-weight: normal;
		}
		h3 {
			font-weight: normal;
		}

		th {
			padding: 3rem 2rem;
			text-align: left;
		}
	}

	a {
		color: var(--dark-gray);
		text-decoration: underline;
		transition: all 0.2s;
	}

	a:hover {
		color: var(--light-gray);
	}

	& > ol,
	ul {
		margin-left: 2rem;
		li {
			margin-left: 2rem;
		}
	}

	& > h1,
	h2 {
		font-size: 2rem;
		margin-top: 2rem;
	}

	&::-webkit-scrollbar {
		width: 1.2rem;
	}

	&::-webkit-scrollbar-track {
		border-radius: 1rem;
		background-color: #e3e8eb;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--light-gray);
		border-radius: 1rem;
	}
`;
