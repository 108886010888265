import { BalanceMovement } from '../../../../../services/queries/Corpway/Funds';
import { useState } from 'react';
import { Button } from '../../../../../componentsV2/ui/Button';
import { MdNotes, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import Modal from '../../../../../componentsV2/ui/Modal';
import { Typography } from '../../../../../componentsV2/ui/Typography';
import * as S from './styles';
import { parseMovementType } from '../../../Management/utils/parseBalanceMovements';
import { convertCentsToReais } from '../../../../../utils/CurrencyConvert';
import { MainContent } from './components/MainContent';
import { SecondaryContent } from './components/SecondaryContent';
import { TransferReceiptsModal } from './components/TransferReceiptsModal';

export interface Props {
	movement: BalanceMovement;
}

export function MovementDetailsModal({ movement }: Props) {
	const [isOpen, setIsOpen] = useState(false);
	const [modalContent, setModalContent] = useState<0 | 1>(0);

	const [animationModal, setAnimationModal] = useState(false);

	function handleCloseModal() {
		setIsOpen(false);
	}

	const movementType = parseMovementType(movement.type, movement.action);

	function getOperationType() {
		if (movementType === 'Transferência externa') return 'Ted';
		if (
			movementType === 'Repasse para cartão' ||
			movementType === 'Estorno para carteira'
		)
			return 'Repasse interno';

		if (movement.type === 'pix') return 'Pix';
		if (movement.type === 'billing') return 'Boleto';
	}

	return (
		<>
			<Button
				style={{
					color: 'var(--primary-blue)',
					border: 'none',
					padding: '0',
				}}
				intent='ghost'
				onClick={() => {
					setIsOpen(true);
					setAnimationModal(true);
				}}
				data-rh='Detalhes da movimentação'
			>
				<MdNotes />
			</Button>

			<Modal
				isOpen={isOpen}
				onRequestClose={handleCloseModal}
				position='right'
				titleTheme='dark'
				closeBtnStyles={{ zIndex: 3 }}
				animateOnClose={animationModal}
			>
				<S.Container>
					<S.HeaderContent movementType={movementType}>
						<S.HeaderMainContent>
							<div>
								<S.TransactionCategory>
									<Typography color='white' weight='500' size='2.4rem'>
										{movementType}
									</Typography>
								</S.TransactionCategory>
								<S.TransactionAmount>
									<Typography color='white' weight='500' size='2rem'>
										{convertCentsToReais(movement.amount)}
									</Typography>
								</S.TransactionAmount>
							</div>
						</S.HeaderMainContent>

						<S.HeaderDetailsContainer>
							<S.HeaderDetail>
								<Typography color='white' size='1.4rem' weight='600'>
									Operação:
								</Typography>
								<Typography color='white' weight='300' size='1.4rem'>
									{getOperationType()}
								</Typography>
							</S.HeaderDetail>
							<S.ModalContentButton
								style={{ alignItems: 'flex-end' }}
								onClick={() => setModalContent(modalContent === 0 ? 1 : 0)}
							>
								<Typography color='white' size='1.4rem' weight='600'>
									{modalContent === 0 ? 'Histórico' : 'Detalhes'}
								</Typography>
								<MdOutlineKeyboardArrowRight color={'#fff'} size={20} />
							</S.ModalContentButton>
						</S.HeaderDetailsContainer>
					</S.HeaderContent>

					<S.MainContent style={{ zIndex: 999 }}>
						{modalContent === 0 ? (
							<MainContent movement={movement} />
						) : (
							<SecondaryContent movement={movement} />
						)}
					</S.MainContent>

					{movement.status === 'paid' && (
						<div
							style={{
								justifyContent: 'center',
								display: 'flex',
							}}
						>
							<TransferReceiptsModal
								movement={movement}
								movementType={movementType}
							/>
						</div>
					)}
				</S.Container>
			</Modal>
		</>
	);
}
